import { ChangeDetectionStrategy, Component, Input } from "@angular/core"
import { Person, TimelineElement, TimelineType, WorkflowStates } from "@models/common"
import { DateFormatPipe, FromUnixPipe } from "ngx-moment"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { AvatarModule } from "ngx-avatars"
import { TimelineImageElementComponent } from "../timeline-image-element/timeline-image-element.component"
import { TimelineCommentElementComponent } from "../timeline-comment-element/timeline-comment-element.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { NgIf, NgSwitch, NgSwitchCase, TitleCasePipe } from "@angular/common"

@Component({
  selector: "timeline-element",
  templateUrl: "./timeline-element.component.html",
  styleUrls: ["./timeline-element.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    NgSwitch,
    NgSwitchCase,
    TimelineCommentElementComponent,
    TimelineImageElementComponent,
    AvatarModule,
    MatLegacyTooltipModule,
    TitleCasePipe,
  ],
})
export class TimelineElementComponent {
  @Input() timelineElement: TimelineElement
  @Input() currentUser: Person

  isCreatorCurrentUser() {
    return this.timelineElement.creator && this.currentUser && this.timelineElement.creator.uid === this.currentUser.uid
  }

  get alignment() {
    return this.isCreatorCurrentUser() ? "end" : "start"
  }

  get layoutClass() {
    return this.isCreatorCurrentUser() ? "row-reverse" : "row"
  }

  get creator() {
    // @ts-ignore
    return this.timelineElement.creator || { email: "", uid: "", ref: null }
  }
  get creatorImage() {
    return this.timelineElement.creator && this.timelineElement.creator.image ? this.timelineElement.creator.image : ""
  }
  get disablerName() {
    return this.timelineElement.disabler ? this.timelineElement.disabler.name : ""
  }

  get inviteeName() {
    const invitation = this.timelineElement?.currentData?.invitation

    if (!invitation) {
      return ""
    }

    return (invitation.targetName || invitation.targetEmail || invitation.targetPhoneNumber || "").trim()
  }

  get targetName() {
    if (
      this.timelineElement.currentData &&
      this.timelineElement.currentData.person &&
      (this.timelineElement.currentData.person.name || this.timelineElement.currentData.person.fullName)
    ) {
      const targetName = this.timelineElement.currentData.person.name || this.timelineElement.currentData.person.fullName

      return targetName!.trim()
    }

    return ""
  }

  get reportName() {
    if (this.timelineElement.currentData && this.timelineElement.currentData.report && this.timelineElement.currentData.report.name) {
      return this.timelineElement.currentData.report.name.trim()
    }

    return null
  }

  get reportUid() {
    if (this.timelineElement.currentData && this.timelineElement.currentData.report && this.timelineElement.currentData.report.uid) {
      return this.timelineElement.currentData.report.uid.trim()
    }

    return null
  }

  get legacyReportUrl() {
    return this.reportUid != null ? `legacyReports/${this.reportUid}` : ""
  }

  creatorName(youIfCreator = true): string {
    if (youIfCreator && this.isCreatorCurrentUser()) {
      return "you"
    }

    return this.timelineElement.creator && this.timelineElement.creator.name ? this.timelineElement.creator.name.trim() : ""
  }

  infoTooltipMessage(tooltipFor: "creator" | "target" | "invitee"): string {
    let mail
    let company

    switch (tooltipFor) {
      case "creator":
        const creator: Person = this.timelineElement.creator

        mail = creator && creator.email && creator.email.trim()
        company = creator && creator.companyName && creator.companyName.trim()
        break
      case "target":
        const targetPerson = this.timelineElement?.currentData?.person

        mail = targetPerson?.email ?? null
        company = targetPerson?.aggregateData?.["companyName"] ?? targetPerson?.companyName ?? null
        break
      case "invitee":
        const invitation = this.timelineElement?.currentData?.invitation

        // defaults
        company = null
        mail = null

        // Don't show anything in the tooltip message if invited by SMS
        if (!invitation || invitation?.deliveryMethod === "sms") {
          break
        }

        if (invitation?.targetName) {
          break
        }

        if (invitation?.targetEmail) {
          mail = invitation.targetEmail
        }

        break
      default:
        const assertUnreachable: never = tooltipFor
    }

    return `${mail ? "mail: " + mail : ""}${mail && company ? " - " : ""}${company ? "company: " + company : ""}`.trim()
  }

  get dueDateSetMessage(): string {
    try {
      // Convert to booleans to make tslint happy
      const previous = !!this.timelineElement?.previousData?.item?.dueDate
      const current = !!this.timelineElement?.currentData?.item?.dueDate

      switch (true) {
        case previous && current:
          return `changed the due date`
        case previous && !current:
          return `removed the due date`
        case !previous && current:
        default:
          return `set a due date`
      }
    } catch (err) {
      return `set a due date`
    }
  }

  get statusChangeMessage() {
    const statusCurr = this.timelineElement?.data?.currentData?.item?.status || this.timelineElement?.data?.currentData?.task?.status

    switch (statusCurr) {
      case WorkflowStates.DELEGATED:
        return "delegated the task"
      case WorkflowStates.ACCEPTED:
        return "accepted the task"
      case WorkflowStates.REJECTED:
        return "declined the task"
      case WorkflowStates.CANCELLED:
        return "cancelled the task"
      case WorkflowStates.FIXED:
        return "fixed the task"
      case WorkflowStates.CLOSED:
        return "closed the task"
      case WorkflowStates.FIXED_ACCEPTED:
        return "accepted the fix"
      case WorkflowStates.FIXED_REJECTED:
        return "rejected the fix"
      default:
        return `Status is now ${statusCurr}`
    }
  }

  get isTimeline() {
    if (this.timelineElement.disabled === true) {
      return true
    }
    switch (this.timelineElement.type) {
      case TimelineType.IMAGE_ADDED:
      case TimelineType.COMMENT_ADDED:
        return false
      default:
        return true
    }
  }

  formatTimestamp(timestamp: any) {
    if (isNaN(timestamp as any)) {
      return timestamp
    }
    const fromUnix = new FromUnixPipe().transform(timestamp / 1000)

    return new DateFormatPipe().transform(fromUnix, "D MMMM YYYY HH:mm")
  }
}
