import { Component, OnInit, Output, EventEmitter } from "@angular/core"
import { UntypedFormControl, Validators, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { SnackbarService, FirebaseAuthService } from "@services"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-account-activation-view",
  templateUrl: "./account-activation-view.component.html",
  styleUrls: ["./account-activation-view.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, MatLegacyFormFieldModule, MatLegacyInputModule, FormsModule, ReactiveFormsModule, CheckdButtonComponent],
})
export class AccountActivationViewComponent implements OnInit {
  @Output() submit = new EventEmitter()
  email = new UntypedFormControl("", [Validators.required, Validators.email])
  form: UntypedFormGroup
  wasEmailSubmitted: boolean = false

  constructor(
    private fb: UntypedFormBuilder,
    private authService: FirebaseAuthService,
    private snackbarService: SnackbarService,
  ) {
    this.form = fb.group({
      email: this.email,
    })
  }

  ngOnInit() {}

  onSubmit(email: string) {
    if (this.email.valid) {
      this.wasEmailSubmitted = true
      //this.submit.emit(email);
      this.authService
        .sendPasswordResetEmail(email)
        .then((_) => {
          this.snackbarService.showMessage(`Password reset email sent to ${email}!`)
        })
        .catch((err) => {
          this.snackbarService.showMessage(`ERROR: ${err}`)
        })
    }
  }
}
