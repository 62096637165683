import { Component, EventEmitter, Input, Output } from "@angular/core"
import { NgFor } from "@angular/common"
import { MatIconModule } from "@angular/material/icon"
import { MatDividerModule } from "@angular/material/divider"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

export interface IMultiselect {
  selected: string[]
  deselected: string[]
}

@Component({
  selector: "checkd-multiselect",
  templateUrl: "./checkd-multiselect.component.html",
  styleUrls: ["./checkd-multiselect.component.scss"],
  standalone: true,
  imports: [
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    FormsModule,
    MatLegacyMenuModule,
    MatLegacyCheckboxModule,
    MatDividerModule,
    MatIconModule,
    NgFor,
  ],
})
export class CheckdMultiselectComponent {
  _values: { name: string; checked: boolean | null }[] = []
  get _filteredValues() {
    return this._values.filter((value) => value.name.toLowerCase().includes(this.searchString.toLowerCase()))
  }

  @Input() set values(data: string[]) {
    this._values = (data || []).map((value) => {
      // checked here is used as a tri-bool. True/False/null
      return { name: value, checked: false }
    })
  }

  @Input() placeholder: string = "Select"
  // Toggles whether the checkboxes should act as tri-booleans. Plain true/false otherwise
  @Input() triCheck: boolean = false

  @Output() onSelection = new EventEmitter<IMultiselect>()

  searchString = ""

  get searchBoxString(): string {
    return this.someSelected() || this.allSelected() ? "One or more selected" : ""
  }

  emitChanges() {
    const selected = this._values.filter((value) => value.checked === true).map((value) => value.name)
    const deselected = this._values.filter((value) => value.checked === null).map((value) => value.name)

    this.onSelection.emit({ selected, deselected })
  }

  selectAllClicked() {
    if (this.allSelected()) {
      this._values.forEach((value) => (value.checked = false))
    } else {
      this._values.forEach((value) => (value.checked = true))
    }

    this.emitChanges()
  }

  checkBoxClicked(index: number) {
    if (!this.triCheck) {
      this._filteredValues[index].checked = !this._filteredValues[index].checked
      this.emitChanges()

      return
    }

    const current = this._filteredValues[index].checked

    if (current === true) {
      this._filteredValues[index].checked = null
    } else if (current === false) {
      this._filteredValues[index].checked = true
    } else {
      this._filteredValues[index].checked = false
    }

    this.emitChanges()
  }

  allSelected(): boolean {
    return this._values.every((value) => value.checked !== false)
  }

  someSelected(): boolean {
    return this._values.some((value) => value.checked !== false) && !this.allSelected()
  }

  clear() {
    this._values.forEach((value) => (value.checked = false))
    this.searchString = ""
  }
}
