import { Component, OnInit, Inject } from "@angular/core"
import { DisabledOverlayRef } from "./checkd-disabled-overlay-ref"
import { DISABLED_OVERLAY_DIALOG_DATA } from "./checkd-disabled-overlay.tokens"
import { Project, Company } from "@models/common"
import { UserService, ProjectService } from "@services"
import { Observable } from "rxjs"
import { RouterLink } from "@angular/router"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { CheckdButtonComponent } from "../checkd-button/checkd-button.component"
import { NgIf, AsyncPipe } from "@angular/common"
import { MatIconModule } from "@angular/material/icon"
import { FlexModule } from "@angular/flex-layout/flex"
import { MatLegacyCardModule } from "@angular/material/legacy-card"

@Component({
  selector: "checkd-disabled-overlay",
  templateUrl: "./checkd-disabled-overlay.component.html",
  styleUrls: ["./checkd-disabled-overlay.component.scss"],
  standalone: true,
  imports: [MatLegacyCardModule, FlexModule, MatIconModule, NgIf, CheckdButtonComponent, MatLegacyButtonModule, RouterLink, AsyncPipe],
})
export class CheckdDisabledOverlayComponent implements OnInit {
  userCompany$: Observable<Company>
  projectCompany$: Observable<Company>
  project: Project

  constructor(
    public dialogRef: DisabledOverlayRef,
    private userService: UserService,
    private projectService: ProjectService,
    @Inject(DISABLED_OVERLAY_DIALOG_DATA) public data: any
  ) {
    if (data.project) {
      this.project = data.project as Project
      this.userCompany$ = this.userService.currentCompany$
      this.projectCompany$ = this.projectService.listenToCompany(this.project)
    }
  }

  ngOnInit() {}
}
