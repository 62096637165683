import { Component, OnInit } from "@angular/core"
import { MatDialogRef, MatDialogModule } from "@angular/material/dialog"
import { MatLegacySelectionListChange, MatLegacyListModule } from "@angular/material/legacy-list"
import { LegacyReport } from "@models/common/legacy-report"
import { DateFormatPipe, FromUnixPipe } from "ngx-moment"
import { tap } from "rxjs/operators"
import { ProjectViewService } from "../../project/project-view/project-view.service"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { MatLineModule } from "@angular/material/core"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatIconModule } from "@angular/material/icon"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-report-selection-dialog",
  templateUrl: "./report-selection-dialog.component.html",
  styleUrls: ["./report-selection-dialog.component.scss"],
  standalone: true,
  imports: [
    FlexModule,
    MatDialogModule,
    MatIconModule,
    MatLegacyTooltipModule,
    NgIf,
    MatLegacyListModule,
    NgFor,
    MatLineModule,
    MatLegacyProgressSpinnerModule,
    AsyncPipe,
  ],
})
export class ReportSelectionDialogComponent implements OnInit {
  isLoading = true

  constructor(private dialogRef: MatDialogRef<ReportSelectionDialogComponent>, private projectViewService: ProjectViewService) {}

  readonly reports$ = this.projectViewService.filteredAndSortedProjectAndProjectUserReports$.pipe(tap((_) => (this.isLoading = false)))

  ngOnInit(): void {}

  reportSelected(event: MatLegacySelectionListChange) {
    const report: LegacyReport = event.source.selectedOptions.selected[0].value

    this.dialogRef.close(report)
  }

  formatTimestamp(timestamp: any) {
    if (isNaN(timestamp as any)) {
      return timestamp
    }
    const fromUnix = new FromUnixPipe().transform(timestamp / 1000)

    return new DateFormatPipe().transform(fromUnix, "D MMMM YYYY HH:mm")
  }
}
