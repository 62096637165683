import { ProjectData } from "@models/common/project.interface"
import { ItemData } from "./item.interface"
import { PersonData } from "./person.interface"
import { ITimelineData } from "./timeline.interface"
import { BaseModel } from "./base-model"
import { TaskData } from "./task"
import { parseDocumentReferencePath } from "./utilities"
import { NotificationType } from "./notification-type"
import { COLLECTIONS } from "./collections.interface"
import { BaseModelData } from "./base-model.interface"
import firebase from "firebase/compat"
import firestore = firebase.firestore

export interface NotificationData extends BaseModelData {
  senderUid: string
  receiverUid: string
  message: string
  sentPush: boolean
  data?: {
    projectData?: ProjectData
    assignerData?: PersonData
    assigneeData?: PersonData
    itemData?: ItemData
    taskData?: TaskData
    timelineData?: ITimelineData
    timelineCreatorData?: PersonData
    [key: string]: any
  }
  createdAt?: number
  notificationType?: string
  isOpened: boolean
  target: firestore.DocumentReference
  uid?: any
  bulkUid?: string
}

export class Notification extends BaseModel<NotificationData> {
  public static override COLLECTION: string = COLLECTIONS.NOTIFICATIONS

  get name() {
    return this.data.name || ""
  }
  get senderUid() {
    return this.data.senderUid || ""
  }
  get receiverUid() {
    return this.data.receiverUid || ""
  }
  get message() {
    return this.data.message || ""
  }
  get isOpened() {
    return this.data.isOpened || false
  }

  override get createdAt() {
    return this.data.createdAt || 0
  }
  get target() {
    return this.data.target || null
  }
  get notificationData() {
    return this.data.data || {}
  }
  get notificationType() {
    return this.data.notificationType || NotificationType.NONE
  }

  public async markAsRead() {
    return this.update({
      isOpened: true,
    })
  }

  public targetPathComponents() {
    return Notification.parseTargetPath(this)
  }

  public static async markAllAsReadForUser(userUid: string) {
    const unreadNotifications = await Notification.db
      .collection(COLLECTIONS.NOTIFICATIONS)
      .where("receiverUid", "==", userUid)
      .where("isOpened", "==", false)
      .get()

    let operationCounter = 0
    const batchesArray = [Notification.db.batch()]

    unreadNotifications.forEach((unreadNotification) => {
      batchesArray[batchesArray.length - 1].update(unreadNotification.ref, { isOpened: true })
      operationCounter += 1

      if (operationCounter === 499) {
        batchesArray.push(Notification.db.batch())
        operationCounter = 0
      }
    })

    return Promise.all(batchesArray.map((batch) => batch.commit()))
  }

  public static parseTargetPath(notification: Notification) {
    return parseDocumentReferencePath(notification.target)
  }

  public static get(uid: string) {
    return BaseModel.getModel(Notification, uid)
  }

  public static create(data: NotificationData) {
    return BaseModel.createModel(Notification, data)
  }
}
