import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag } from "@angular/cdk/drag-drop"
import { Component, EventEmitter, Input, Output } from "@angular/core"
import { IColumnConfig } from "../item-list/table-configs"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { NgFor } from "@angular/common"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"

@Component({
  selector: "checkd-item-table-column-selection-menu",
  templateUrl: "./item-table-column-selection-menu.component.html",
  styleUrls: ["./item-table-column-selection-menu.component.scss"],
  standalone: true,
  imports: [MatLegacyButtonModule, MatLegacyMenuModule, MatIconModule, CdkDropList, NgFor, CdkDrag, MatLegacyCheckboxModule],
})
export class ItemTableColumnSelectionMenuComponent {
  @Input() columns: IColumnConfig[]
  @Output() columnsChange = new EventEmitter<IColumnConfig[]>()

  checkBoxClicked(index: number) {
    this.columns[index].checked = !this.columns[index].checked
    this.columnsChange.emit(this.columns)
  }

  drop(event: CdkDragDrop<IColumnConfig[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex)
    this.columnsChange.emit(this.columns)
  }
}
