import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

export interface ITextfieldDialogData {
  title: string
  content: string
  value?: string
  okValue?: string
  cancelValue?: string
  placeholder?: boolean
}

@Component({
  selector: "checkd-textfield-dialog",
  templateUrl: "./textfield-dialog.component.html",
  styleUrls: ["./textfield-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, MatLegacyFormFieldModule, MatLegacyInputModule, FormsModule, FlexModule, CheckdButtonComponent],
})
export class TextfieldDialogComponent {
  get isValidValue() {
    return this.data != null && this.data.value != null && this.data.value.trim && this.data.value.trim() !== ""
  }

  constructor(private dialogRef: MatDialogRef<TextfieldDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ITextfieldDialogData) {}

  cancel() {
    this.dialogRef.close(null)
  }
  ok() {
    if (this.isValidValue) {
      this.dialogRef.close(this.data)
    }
  }
}
