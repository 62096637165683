import { Component, EventEmitter, forwardRef, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core"
import { drawingActionButtonsEventTypes } from "@items"
import { Drawing, DrawingData, Item, Project } from "@models/common"
import { IPoint } from "@models/common/point.interface"
import { FilestackService, ItemService, ProjectService } from "@services"

import { BehaviorSubject, from, Observable, of, ReplaySubject, Subject, Subscription } from "rxjs"
import { distinctUntilChanged, distinctUntilKeyChanged, filter, map, shareReplay, switchMap, take } from "rxjs/operators"
import { MatDividerModule } from "@angular/material/divider"
import { MatLegacyListModule } from "@angular/material/legacy-list"
import { CheckdDrawingComponent as AppCheckdDrawingComponent } from "../../../checkd-ui/checkd-drawing/checkd-drawing.component"
import { CheckdDrawingComponent } from "../../../reports/drawing-report/report-drawing/checkd-drawing/checkd-drawing.component"
import { PrimaryButtonDirective } from "../../../next-ui/button/primary-button.directive"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { MatIconModule } from "@angular/material/icon"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"

@Component({
  selector: "drawing-section",
  templateUrl: "./drawing-section.component.html",
  styleUrls: ["./drawing-section.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    MatIconModule,
    forwardRef(() => CheckdDrawingComponent),
    AppCheckdDrawingComponent,
    MatLegacyProgressSpinnerModule,
    PrimaryButtonDirective,
    MatLegacyListModule,
    NgFor,
    MatDividerModule,
    AsyncPipe,
  ],
})
export class DrawingSectionComponent implements OnInit, OnDestroy, OnChanges {
  @Input() item: Item
  @Input() project: Project
  @Input() currentCompanyHasDrawingFeature = true
  @Output() onClick = new EventEmitter()

  private subscriptions = new Subscription()
  @Input() actionButtonEvent: Observable<drawingActionButtonsEventTypes>

  userIsSelectingDrawing = false
  // @ts-ignore
  selectedDrawing: Drawing = null

  @Input() showCancelButton = false
  @Output() showCancelButtonChange = new EventEmitter<boolean>()
  @Input() showSavePositionButton = false
  @Output() showSavePositionButtonChange = new EventEmitter<boolean>()

  @Output() onDrawingSelected = new EventEmitter()

  drawing$: Observable<Drawing>
  drawingData$: Observable<DrawingData>
  items$: Observable<Item[]>

  latestChosenItemPosition = new ReplaySubject<IPoint>(1)

  project$: Subject<Project> = new Subject<Project>()
  projectDrawings$ = this.project$.pipe(
    distinctUntilKeyChanged("uid"),
    switchMap((project) => from(project.getDrawings()))
  )

  constructor(private filestackService: FilestackService, private itemService: ItemService, public projectService: ProjectService) {}

  ngOnInit() {
    this.drawing$ = this.itemService.listenToDrawing(this.item).pipe(shareReplay({ bufferSize: 1, refCount: true }))

    this.drawingData$ = this.drawing$.pipe(
      filter((drawing) => drawing != null),
      map((drawing) => drawing.data)
    )

    this.items$ = of([this.item])

    this.subscriptions.add(this.actionButtonEvent.subscribe((e) => this.handleEventFromActionButton(e)))
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.project) {
      this.project$.next(this.project)
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  handleEventFromActionButton(event: drawingActionButtonsEventTypes) {
    // tslint:disable-next-line:switch-default
    switch (event) {
      case "cancel":
        this.userIsSelectingDrawing = false
        // @ts-ignore
        this.selectedDrawing = null
        break
      case "save_position":
        this.latestChosenItemPosition.pipe(take(1)).subscribe((coords) => {
          this.onDrawingSelected.emit({
            drawing: this.selectedDrawing,
            coordinates: { positionX: coords.positionX, positionY: coords.positionY },
          })

          this.item.positionX = coords.positionX
          this.item.positionY = coords.positionY
          this.items$ = of([this.item])
        })
        break
    }

    this.setSavePositionButtonVisibility(false)
    this.setCancelButtonVisibility(false)
  }

  // TODO make text truncation work in CSS
  truncate(str: string | undefined) {
    if (!str) {
      return ""
    }

    const truncateLen = 24

    if (str.length <= truncateLen) {
      return str
    }

    return `${str.substring(0, truncateLen)}...`
  }

  attachToDrawingClicked() {
    this.userIsSelectingDrawing = true
    this.setCancelButtonVisibility(true)
  }

  userSelectedDrawing(drawing: Drawing) {
    this.selectedDrawing = drawing
    this.userIsSelectingDrawing = false
  }

  setCancelButtonVisibility(visible: boolean) {
    this.showCancelButton = visible
    this.showCancelButtonChange.emit(visible)
  }

  setSavePositionButtonVisibility(visible: boolean) {
    this.showSavePositionButton = visible
    this.showSavePositionButtonChange.emit(visible)
  }

  // Checks if item has drawing only. Does not check whether the item is actually placed on the drawing
  get itemIsAttachedToDrawing() {
    return this.item != null && this.item.aggregateData && this.item.aggregateData.drawingUid != null
  }

  get itemIsPlacedOnDrawing() {
    return this.itemIsAttachedToDrawing && this.item.positionX != null && this.item.positionY != null
  }

  // TODO: check if this does anything
  imgOnClick() {
    this.onClick.emit(this.item)
  }

  itemCreated(event: IPoint) {
    this.setSavePositionButtonVisibility(true)
    this.latestChosenItemPosition.next(event)
  }

  itemMoved(event: IPoint) {
    this.setSavePositionButtonVisibility(true)
    this.latestChosenItemPosition.next(event)
  }
}
