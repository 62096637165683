import { CompanyFeatures } from "@models/common/company.interface"

export interface IFeatureDescription {
  feature: CompanyFeatures
  name: string
  salesPitch?: string[]
  description?: string[]
}

export const FEATURE_DESCRIPTIONS: { [feature in CompanyFeatures]: IFeatureDescription } = {
  ASSOCIATES: { feature: CompanyFeatures.ASSOCIATES, name: "Associates" },
  BASIC: {
    feature: CompanyFeatures.BASIC,
    name: "Basic",
    description: ["NB: This feature is deprecated and should be removed once we get rid of it in the code"],
  },
  COMPANY_INSIGHTS: { feature: CompanyFeatures.COMPANY_INSIGHTS, name: "Company Insights" },
  ENTERPRISE: { feature: CompanyFeatures.ENTERPRISE, name: "Enterprise" },
  EXPORT_BCF: { feature: CompanyFeatures.EXPORT_BCF, name: "Export to BCF" },
  FORMS: { feature: CompanyFeatures.FORMS, name: "Forms" },
  FORMS_ITEMFLOW: { feature: CompanyFeatures.FORMS_ITEMFLOW, name: "Forms Item Flow" },
  FORMS_TEMPLATE_JSON_EXPORT: {
    feature: CompanyFeatures.FORMS_TEMPLATE_JSON_EXPORT,
    name: "Forms template JSON export",
    salesPitch: ["With this feature unlocked, you can export a JSON file representing one of your templates"],
    description: [],
  },
  FREE: { feature: CompanyFeatures.FREE, name: "Free" },
  ITEM_CREATION_ACTIONBUTTON: {
    feature: CompanyFeatures.ITEM_CREATION_ACTIONBUTTON,
    name: "Item Creation Actionbutton",
    description: ["NB: This feature is deprecated and should be removed once we get rid of it in the code"],
  },
  PROJECT_ARCHIVING: { feature: CompanyFeatures.PROJECT_ARCHIVING, name: "Project Archiving" },
  PROJECT_TEMPLATES: { feature: CompanyFeatures.PROJECT_TEMPLATES, name: "Project Templates" },
  REPORT_LOCKING: { feature: CompanyFeatures.REPORT_LOCKING, name: "Report Locking" },
  TEMPLATE_CREATION: { feature: CompanyFeatures.TEMPLATE_CREATION, name: "Template Creation" },

  DRAWINGS: {
    feature: CompanyFeatures.DRAWINGS,
    name: "Drawings",
    salesPitch: [
      "With Drawings unlocked, you can add items and tasks directly on a live drawing.",
      "Go on inspections, create items and share details digital drawing reports with everyone.",
    ],
    description: [""],
  },

  QR_OPEN_TEMPLATES: {
    feature: CompanyFeatures.QR_OPEN_TEMPLATES,
    name: "QR Open Templates",
    salesPitch: [
      "With this feature unlocked, you can print QR codes for a project template that your colleagues can scan with their phone.",
      "Once they scan it, they will be able to fill out a report anonymously, based on the template.",
    ],
    description: [""],
  },
  FORMS_LIBRARY: {
    feature: CompanyFeatures.FORMS_LIBRARY,
    name: "Forms Library",
  },
  FORMS_TABLE: {
    feature: CompanyFeatures.FORMS_TABLE,
    name: "Allows usage of table element in forms templates",
  },
  SSO_AD_LOGIN: {
    feature: CompanyFeatures.SSO_AD_LOGIN,
    name: "Allows company members with an AD account to sign in/up using SSO",
  },
  FORMS_PDF_BY_MAIL: {
    feature: CompanyFeatures.FORMS_PDF_BY_MAIL,
    name: "Send FORMS PDF's by mail",
    salesPitch: [
      "This feature lets users send a PDF directly by mail.",
      "It also lets users specify a set of template elements that should be included in the mail from a filled out report.",
    ],
  },
  MEMBER_TEMPLATES_VIEWER: {
    feature: CompanyFeatures.MEMBER_TEMPLATES_VIEWER,
    name: "Member Templates Viewer",
    salesPitch: ["This feature allow companies to view forms templates shared as member templates by forms library publisher companies"],
  },
}
