import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { NgSwitch, NgSwitchCase, NgStyle, NgIf } from "@angular/common"

export interface ISpinnerDialogConfig {
  disableClose: boolean
  subTitle: string
  diameter: number
  strokeWidth: number
}

export interface IFinishSpinnerDialogConfig {
  message: string
  subTitle?: string
  timeOut?: number
  disableClose?: boolean
}

@Component({
  selector: "app-spinner-dialog",
  templateUrl: "./spinner-dialog.component.html",
  styleUrls: ["./spinner-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, NgSwitch, NgSwitchCase, MatLegacyProgressSpinnerModule, MatIconModule, NgStyle, ExtendedModule, NgIf],
})
export class SpinnerDialogComponent implements OnInit {
  defaultConfig = {
    diameter: 100,
    strokeWidth: 10,
    message: "",
    subTitle: "",
  }

  dialogState: "spinning" | "finishedWithError" | "finishedWithSuccess" = "spinning"

  title: string
  subTitle: string

  constructor(private dialogRef: MatDialogRef<SpinnerDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.title = data.message
    this.subTitle = data.subTitle
    this.data = { ...this.defaultConfig, ...data }
  }

  ngOnInit() {}

  finishWithSuccess(config: IFinishSpinnerDialogConfig) {
    this.dialogState = "finishedWithSuccess"

    this.title = config.message
    // @ts-ignore
    this.subTitle = config.subTitle

    this.setDialogCloseConfig(config)
  }

  finishWithError(config: IFinishSpinnerDialogConfig) {
    this.dialogState = "finishedWithError"

    this.title = config.message
    // @ts-ignore
    this.subTitle = config.subTitle

    this.setDialogCloseConfig(config)
  }

  private setDialogCloseConfig(config: IFinishSpinnerDialogConfig) {
    if (config.timeOut) {
      setTimeout(() => this.dialogRef.close(), config.timeOut)
    }

    if (config.hasOwnProperty("disableClose")) {
      this.dialogRef.disableClose = config.disableClose
    }
  }

  get iconSize(): string {
    return `${this.data.diameter}px`
  }
}
