import { ChangeDetectorRef, Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from "@angular/core"

import { DrawingData, Item } from "@models/common"
import { IPoint } from "@models/common/point.interface"

import { LeafletService, XYBounds, XYCoord } from "@services"
import * as L from "leaflet"
import { LatLng, LatLngBounds, Layer, Map, Marker } from "leaflet"
import { BehaviorSubject, combineLatest, Subject, Subscription } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"

import { ItemService } from "../../services"
import { LeafletCheckdItemDirective } from "../directives/leaflet-checkd-item.directive"
import { NgFor } from "@angular/common"
import { LeafletCheckdDrawingDirective } from "../directives/leaflet-checkd-drawing.directive"
import { LeafletModule } from "@asymmetrik/ngx-leaflet"

export interface ItemMarkerOptions {
  item?: Item
  icon?: L.Icon
  draggable: boolean
}

@Component({
  selector: "app-checkd-drawing",
  templateUrl: "./checkd-drawing.component.html",
  styleUrls: ["./checkd-drawing.component.scss"],
  standalone: true,
  imports: [LeafletModule, LeafletCheckdDrawingDirective, NgFor, LeafletCheckdItemDirective],
})
export class CheckdDrawingComponent implements OnInit, OnDestroy {
  private readonly leafletService = inject(LeafletService)
  private readonly itemService = inject(ItemService)
  private readonly cdr = inject(ChangeDetectorRef)

  @Input()
  set drawingData(drawingData: DrawingData) {
    this.drawingData$.next(drawingData)
  }
  private drawingData$ = new BehaviorSubject<DrawingData | undefined>(undefined)

  @Input() set items(items: Item[]) {
    this._items = items
    this.updateItemLayers()
  }

  @Input() enableGestureToAddItemMarker: boolean = false
  @Input() maxAddableItemMarkersWithGesture: number = 1
  @Input() initializeMarkerInMiddle = false

  @Output() onItemLayerMoved = new EventEmitter<IPoint>()

  @Output() onItemCreate = new EventEmitter<IPoint>()
  @Output() onItemSelected = new EventEmitter<Item>()
  @Output() onItemContextMenu = new EventEmitter()

  width: number
  height: number

  itemLayers = new Array<Layer>()

  private _items: Item[] = []
  private drawingBounds: XYBounds

  drawingLatLngBounds: LatLngBounds

  drawingMapOptions = {
    attributionControl: false,
    minZoom: -4,
    maxZoom: 4,
    center: [0, 0],
    zoom: -2,
    crs: L.CRS.Simple,
  }

  icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: "assets/marker-icon.png",
    shadowUrl: "assets/marker-shadow.png",
  })

  map: Map

  onMapReady$ = new Subject<Map>()

  subscriptions: Subscription[]

  ngOnInit() {
    this.setupListeners()
    this.setupSubscriptions()
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  setupListeners() {}

  setupSubscriptions() {
    // This fixes an issue where if a drawing was zoomed in on, it would zoom out to the original zoom level when an item status changed
    // while still making the drawing auto-update when changing drawing quality
    const distinctDrawingData$ = this.drawingData$.pipe(
      distinctUntilChanged((previous, current) => previous!.storage.url === current!.storage.url)
    )

    this.subscriptions = [
      combineLatest([this.onMapReady$, distinctDrawingData$]).subscribe(([map, _drawingData]) => {
        if (_drawingData === undefined) {
          return
        }

        this.map = map
        map.eachLayer((layer) => map.removeLayer(layer))
        const southWest = map.unproject([0, _drawingData.height!], map.getMaxZoom() - 5)
        const northEast = map.unproject([_drawingData.width!, 0], map.getMaxZoom() - 5)
        const bounds = new L.LatLngBounds(southWest, northEast)
        this.drawingLatLngBounds = bounds
        // NB!!
        // Padding is used to allow the user to see the edges of the drawing when zoomed in
        // The original drawing bounds above are used to CALCULATE item coordinates
        map.setMaxBounds(bounds.pad(0.1))
        map.fitBounds(bounds)
        L.imageOverlay(_drawingData.storage.url, bounds).addTo(map)
        this.drawingBounds = { x0: 0, y0: 0, x1: _drawingData.width, y1: _drawingData.height } as XYBounds
        this.updateItemLayers()
      }),
    ]
  }

  onMapReady(map: Map) {
    this.onMapReady$.next(map)
  }

  addItemGesture(event: any) {
    this.onItemCreate.emit({
      positionX: event.x,
      positionY: event.y,
    })
  }

  itemClicked(event: any, item: Item) {
    this.onItemSelected.emit(item)
  }

  itemMoved(event: any, item: Item) {
    item.update({ positionX: event.x, positionY: event.y })
  }

  markerMoved(event: any) {
    this.onItemLayerMoved.emit({ positionX: event.x, positionY: event.y })
  }

  onItemContextMenuTriggered(event: any, item: Item) {
    this.onItemContextMenu.emit(item)
  }

  protected isItemWithCoords(item: any) {
    return item.data.positionX != null && item.data.positionY != null
  }

  updateItemLayers() {
    if (this.map && this.drawingBounds) {
      this.itemLayers = (this._items || [])
        .filter((item) => this.isItemWithCoords(item))
        .map((item) => {
          const coords = { x: item.positionX, y: item.positionY } as XYCoord
          const pixels = this.leafletService.denormalizeXYCoordinates(coords, this.drawingBounds)
          const location = this.map.unproject([pixels.x, pixels.y], this.map.getMaxZoom() - 5)

          return this.createItemMarker(location, item)
        })
      this.cdr.detectChanges()
    }
  }

  private createItemMarker(location: LatLng, item: Item): Marker {
    const options = {
      item,
      icon: this.createItemMarkerIcon(item.data.number!, item.data.status!),
      draggable: item.isDraggable,
    } as ItemMarkerOptions

    return new L.Marker(location, options)
  }

  private createItemMarkerIcon(itemNumber: number, itemStatus: string): L.DivIcon {
    const convertedStatus = this.itemService.workflowStateToStatus(itemStatus)

    return this.leafletService.statusToIconHtml(convertedStatus, itemNumber)
  }
}
