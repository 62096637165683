import { map } from "rxjs/operators"
import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"
import { UserService, FirebaseAuthService, SnackbarService } from "@services"

import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { FlexModule } from "@angular/flex-layout/flex"
import { NgIf } from "@angular/common"

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    FlexModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatIconModule,
    CheckdButtonComponent,
  ],
})
export class PasswordResetComponent implements OnInit {
  oobCodeValid = false
  passwordReset: UntypedFormGroup
  hide = true
  private oobCode: string

  constructor(
    private userService: UserService,
    private authService: FirebaseAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.passwordReset = this.formBuilder.group({
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
    })

    this.userService.logout()

    this.route.queryParams.pipe(map((params) => params["oobCode"])).subscribe((oobCode) => {
      this.oobCode = oobCode
      this.authService
        .verifyPasswordResetCode(oobCode)
        .then((_) => (this.oobCodeValid = true))
        .catch((error) => this.handleError(error))
    })
  }

  isValid() {
    return this.passwordReset.valid && this.passwordReset.value.password == this.passwordReset.value.confirmPassword
  }

  resetPassword() {
    this.authService
      .resetPassword(this.oobCode, this.passwordReset.value.password)
      .then((_) => {
        this.snackbar.showMessage("Password reset successful", 4000)
        this.router.navigate(["/login"])
      })
      .catch((error) => this.handleError(error))
  }

  handleError(error: any) {
    this.snackbar.showMessage(`${error.message}`, 5000)
  }
}
