import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Item } from "@models/common"
import { IColumnConfig } from "../item-list/table-configs"
import { FormsModule } from "@angular/forms"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { ItemTableColumnSelectionMenuComponent } from "../item-table-column-selection-menu/item-table-column-selection-menu.component"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { NgTemplateOutlet, NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatToolbarModule } from "@angular/material/toolbar"

export type ActionTypeSelected =
  | "create_report"
  | "new_item"
  | "export_to_CSV"
  | "export_to_BCF"
  | "attach_to_Report"
  | "assign"
  | "add_tags"
  | "accept"
  | "close"

@Component({
  selector: "checkd-items-table-action-bar",
  templateUrl: "./items-table-action-bar.component.html",
  styleUrls: ["./items-table-action-bar.component.scss"],
  standalone: true,
  imports: [
    MatToolbarModule,
    ExtendedModule,
    FlexModule,
    NgTemplateOutlet,
    NgIf,
    PrimaryButtonDirective,
    ItemTableColumnSelectionMenuComponent,
    MatLegacyButtonModule,
    MatIconModule,
    MatLegacyMenuModule,
    FormsModule,
  ],
})
export class ItemsTableActionBarComponent implements OnInit {
  @Input() selectedItems: Item[] = []
  @Input() tooManyItemsForBulkActions: boolean = true
  @Input() maxItemsForBulkActions: number
  @Input() projectIsArchivedOrLocked: boolean
  @Input() canUseItemFlow: boolean
  @Input() canExportToBcf: boolean
  @Input() hideNewItemButton: boolean
  @Input() hideItemsSelectedText: boolean = false
  @Input() hideItemActions: boolean = false

  @Input() columnsEditable: boolean

  @Input() editableColumns: IColumnConfig[]
  @Output() editableColumnsChange = new EventEmitter<IColumnConfig[]>()

  @Output() itemActionClicked = new EventEmitter<ActionTypeSelected>()
  @Output() newItemButtonClicked = new EventEmitter()

  @Output() searchStringChange = new EventEmitter<String>()
  @Input() searchString: String

  constructor() {
    return
  }

  ngOnInit() {
    return
  }
}
