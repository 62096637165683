import { Component, Inject, OnInit } from "@angular/core"
import { IMissingFeatureOverlayConfigData, MISSING_FEATURE_DIALOG_DATA, MissingFeatureOverlayRef } from "../../feature-checker.interface"
import { CheckdButtonComponent } from "../../../../checkd-ui/checkd-button/checkd-button.component"
import { NgFor } from "@angular/common"
import { MatLegacyCardModule } from "@angular/material/legacy-card"

@Component({
  selector: "checkd-missing-feature-dialog",
  templateUrl: "./missing-feature-dialog.component.html",
  styleUrls: ["./missing-feature-dialog.component.scss"],
  standalone: true,
  imports: [MatLegacyCardModule, NgFor, CheckdButtonComponent],
})
export class MissingFeatureDialogComponent {
  constructor(
    public dialogRef: MissingFeatureOverlayRef,
    @Inject(MISSING_FEATURE_DIALOG_DATA) public data: IMissingFeatureOverlayConfigData
  ) {}
}
