import { Injectable } from "@angular/core"
import * as Sentry from "@sentry/angular"
import { BehaviorSubject } from "rxjs"

export interface ILoggingConfig {
  data?: { [key: string]: string }
}

@Injectable({
  providedIn: "root",
})
export class LoggingService {
  public currentProjectUid$ = new BehaviorSubject<string | null>(null)

  private logEvent(error: Error, scope: Sentry.Scope) {
    scope.setTag("projectUid", this.currentProjectUid$.getValue())
    Sentry.captureException(error, scope)
  }

  warn(error: Error, config: ILoggingConfig = {}) {
    const scope = this.convertLoggingToSentryScope(config)
    scope.setLevel("warning")
    this.logEvent(error, scope)
  }

  error(error: Error, config: ILoggingConfig = {}) {
    const scope = this.convertLoggingToSentryScope(config)
    this.logEvent(error, scope)
  }

  private convertLoggingToSentryScope(config: ILoggingConfig): Sentry.Scope {
    const scope = new Sentry.Scope()
    scope.setTags(config.data || {})

    return scope
  }
}
