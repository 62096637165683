import { Pipe, PipeTransform } from "@angular/core"

@Pipe({
  name: "nameFilter",
  standalone: true,
})
export class NameFilterPipe implements PipeTransform {
  transform(models: { name: string }[], searchString: string, length?: number): any {
    if (!models) {
      return []
    }

    if (searchString && Array.isArray(models)) {
      return models.filter((model) => (model.name || "").toLocaleLowerCase().includes(searchString.toLocaleLowerCase())).slice(0, length)
    }

    return models.slice(0, length)
  }
}
