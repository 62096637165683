import { Component, EventEmitter, Input, Output } from "@angular/core"
import { CheckdStatusIconComponent } from "../checkd-status-icon/checkd-status-icon.component"

@Component({
  selector: "checkd-status-button",
  templateUrl: "./checkd-status-button.component.html",
  styleUrls: ["./checkd-status-button.component.scss"],
  standalone: true,
  imports: [CheckdStatusIconComponent],
})
export class CheckdStatusButtonComponent {
  @Input() type: string
  @Input() checked: boolean = false
  @Output() toggled = new EventEmitter<boolean>()

  get isChecked() {
    return this.checked !== null && `${this.checked}` !== "false"
  }

  toggleChecked() {
    this.checked = !this.isChecked
    this.toggled.emit(this.checked)
  }
}
