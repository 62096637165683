import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { allAvailableActions } from "@items"
import { Item } from "@models/common/item"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { FormsModule } from "@angular/forms"
import { NgIf } from "@angular/common"

@Component({
  selector: "checkd-item-comment-input",
  templateUrl: "./item-comment-input.component.html",
  styleUrls: ["./item-comment-input.component.scss"],
  standalone: true,
  imports: [NgIf, FormsModule, MatLegacyButtonModule, MatIconModule],
})
export class ItemCommentInputComponent {
  @Input() item: Item
  @Input() availableActions: string[] = allAvailableActions
  @Input() projectIsArchived: boolean = false
  @Output() onSendComment = new EventEmitter()

  _inputValue = ""

  isAvailableAction(action: string) {
    return this.availableActions.indexOf(action) > -1
  }

  inputIsBlank(str: string) {
    return str.trim().length < 1
  }

  sendComment(str: string) {
    if (!this.inputIsBlank(str)) {
      this._inputValue = ""
      this.onSendComment.emit(str)
    }
  }
}
