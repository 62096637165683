import { Component, OnDestroy, OnInit } from "@angular/core"
import { FirebaseAuthService, SnackbarService, UserService } from "@services"
import { map } from "rxjs/operators"
import { ActivatedRoute, Router, RouterLink } from "@angular/router"
import firebase from "firebase/compat"
import ActionCodeInfo = firebase.auth.ActionCodeInfo
import { Subscription } from "rxjs"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-email-verification-view",
  templateUrl: "./email-verification-view.component.html",
  styleUrls: ["./email-verification-view.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, MatLegacyButtonModule, RouterLink],
})
export class EmailVerificationViewComponent implements OnInit, OnDestroy {
  constructor(public userService: UserService, public authService: FirebaseAuthService, private route: ActivatedRoute) {}

  oobCodeValid = false
  emailVerified = false
  oobCode: string
  authCodeInfo: ActionCodeInfo
  oobErrorMessage: string

  subscriptions: Subscription[]

  ngOnInit(): void {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.route.queryParams.pipe(map((params) => params["oobCode"])).subscribe((oobCode) => {
        this.oobCode = oobCode

        // Check action code validity and extract data
        this.authService
          .checkActionCode(oobCode)
          .then((authCodeInfo) => {
            this.oobCodeValid = true
            this.authCodeInfo = authCodeInfo
            // Confirm email verification
            this.authService.applyActionCode(oobCode).then((_) => {
              this.emailVerified = true
            })
          })
          .catch((error) => {
            this.oobErrorMessage = error.message
          })
      }),
    ]
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}
