import { BaseModelData } from "@models/common/base-model.interface"

export enum UserFeatures {
  SEND_SMS_INVITES = "SEND_SMS_INVITES",
  INSIGHTS_EMBEDDED = "INSIGHTS_EMBEDDED",
}

export interface PersonData extends BaseModelData {
  email: string
  name?: string
  fullName?: string
  profilePicture?: string
  phone?: string
  companyName?: string // <-- Used for newly registered users without a proper company
  image?: string
  jobTitle?: string
  notifications?: {
    assignedTaskMail?: boolean
  }
  deviceTokens?: string[]
}

export interface IUserGeneral {
  features?: UserFeatures[]
}
