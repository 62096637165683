import { Component, Inject, OnDestroy, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA } from "@angular/material/dialog"
import { Company, Drawing, Image, Item, ItemMenuActions, Person, Project, Role, Task, Timeline } from "@models/common"
import { CompanyService, ItemService, ProjectService, RoleHandlerService, TimelineService, UserService } from "@services"
import { combineLatest, Observable, Subscription } from "rxjs"
import { shareReplay, switchMap } from "rxjs/operators"
import { allAvailableActions } from "../item-actions/item-actions.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { ItemDetailsComponent } from "../item-details/item-details.component"
import { NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "app-item-details-dialog",
  templateUrl: "./item-details-dialog.component.html",
  styleUrls: ["./item-details-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, ItemDetailsComponent, FlexModule, AsyncPipe],
})
export class ItemDetailsDialogComponent implements OnInit, OnDestroy {
  item: Item = this.data.item
  project: Project
  projectMembers: Person[]
  withCarousel: boolean
  userProjectRole: Role
  availableItemActions: string[] = allAvailableActions
  restrictedItemMenuActions: ItemMenuActions[] = []
  timeline: Timeline
  userItemRole: Role
  currentUserCompanyRole: Role
  currentCompanyMembers: Person[] = []
  currentCompany$: Observable<Company> = this.userService.currentCompany$
  currentCompanyAssociates: Person[] = []

  item$: Observable<Item> = this.itemService.listenToUid(this.item.uid).pipe(shareReplay({ bufferSize: 1, refCount: true }))
  task$: Observable<Task> = this.itemService.listenToTask(this.item)
  currentUser$: Observable<Person> = this.userService.currentUser$
  itemImages$: Observable<Image[]> = this.itemService.listenToImages(this.item)
  itemDrawing$: Observable<Drawing> = this.itemService.listenToDrawing(this.item)
  project$: Observable<Project> = this.itemService.listenToProject(this.item)

  userProjectRole$: Observable<Role> = combineLatest([this.currentUser$, this.project$]).pipe(
    switchMap(([user, project]) => this.roleHandlerService.getPersonProjectRole(user, project))
  )

  userItemRole$: Observable<Role> = combineLatest([this.currentUser$, this.item$]).pipe(
    // @ts-ignore
    switchMap(([user, item]) => this.roleHandlerService.listenToRole(user, item))
  )

  public currentCompanyFeatures: string[] = []
  public currentCompany: Company

  subscriptions: Subscription[] = []

  constructor(
    private itemService: ItemService,
    private userService: UserService,
    private companyService: CompanyService,
    private roleHandlerService: RoleHandlerService,
    private projectService: ProjectService,
    private timelineService: TimelineService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.project = data.project
    this.projectMembers = data.projectMembers
    this.availableItemActions = data.availableItemActions || allAvailableActions
    this.restrictedItemMenuActions = data.restrictedItemMenuActions || []
    this.userProjectRole = data.userProjectRole
    this.withCarousel = data.withCarousel
  }

  ngOnInit() {
    this.setupSubscriptions()
  }

  setupSubscriptions() {
    // @ts-ignore
    this.subscriptions = [
      this.project == null ? this.project$.subscribe((project) => (this.project = project)) : null,
      this.userProjectRole == null ? this.userProjectRole$.subscribe((role) => (this.userProjectRole = role)) : null,
      this.projectMembers == null
        ? this.project$
            .pipe(switchMap((project) => this.projectService.listenToPeople(project)))
            .subscribe((members) => (this.projectMembers = members))
        : null,
      this.userItemRole$.subscribe((role) => (this.userItemRole = role)),
      this.timelineService.listen(this.item).subscribe((timeline) => (this.timeline = timeline)),
      this.userService.currentCompanyFeatures$.subscribe((features) => (this.currentCompanyFeatures = features)),
      this.userService.currentCompany$.subscribe((company) => (this.currentCompany = company)),
      this.userService.currentCompanyMembers$.subscribe((members) => {
        this.currentCompanyMembers = members
      }),
      this.currentCompany$.pipe(switchMap((company) => this.companyService.listenToAssociatedPeople(company))).subscribe((associates) => {
        this.currentCompanyAssociates = associates
      }),
    ].filter((it) => it)
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }
}
