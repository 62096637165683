import { Component, EventEmitter, Input, Output } from "@angular/core"

import { Item, Person, Task, Workflow, WorkflowStates } from "@models/common"

import { SnackbarService } from "@services"
import { ILoggingConfig, LoggingService } from "@services/logging.service"
import firebase from "firebase/compat/app"
import { Subscription } from "rxjs"
import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { NgIf, UpperCasePipe } from "@angular/common"

@Component({
  selector: "task-actions",
  templateUrl: "./task-actions.component.html",
  styleUrls: ["./task-actions.component.scss"],
  standalone: true,
  imports: [NgIf, CheckdButtonComponent, UpperCasePipe],
})
export class TaskActionsComponent {
  @Input() currentUser: Person
  @Input() projectIsArchived: boolean = false
  @Input() item: Item
  @Input() task: Task

  workflowStates = WorkflowStates

  public get taskCollaboratorRefs(): firebase.firestore.DocumentReference[] {
    return this.task != null ? this.task.collaboratorRefs : []
  }

  public get assignerUid(): string {
    // @ts-ignore
    return this.task != null && this.task.data != null && this.task.data.assigner != null && this.task.data.assigner.id != null
      ? this.task.data.assigner.id
      : null
  }

  public get assigneeUid(): string {
    // @ts-ignore
    return this.task != null && this.task.data != null && this.task.data.assignee != null && this.task.data.assignee.id != null
      ? this.task.data.assignee.id
      : null
  }

  @Output() taskAction = new EventEmitter<{ type: string; item: Item; task: Task }>()
  workflow = Workflow.get("")

  subscriptions: Subscription[]

  constructor(private snackbarService: SnackbarService, private loggingService: LoggingService) {}

  get itemStatus() {
    return this.item != null ? this.item.status : WorkflowStates.OPEN
  }

  get taskStatus() {
    return this.task != null ? this.task.status : WorkflowStates.OPEN
  }

  get status() {
    if (this.taskStatus != null) return this.taskStatus
    if (this.itemStatus != null) return this.itemStatus

    return WorkflowStates.OPEN
  }

  doTaskAction(type: string) {
    this.taskAction.emit({ type, item: this.item, task: this.task })
  }

  isVisibleAction(actionType: string): boolean {
    try {
      this.workflow.setCurrentState(this.status)
    } catch (err) {
      this.loggingService.error(err, {
        data: {
          status: this.status,
          itemUid: this.item.uid,
          taskUid: this.task.uid,
        },
      } as ILoggingConfig)
      this.snackbarService.showMessage(err.message)
    }

    const nextPossibleStates = this.workflow.nextPossibleStates
    const filteredPossibleStates = this.workflow.filterStates(
      nextPossibleStates,
      this.currentUser.uid,
      this.assignerUid,
      this.assigneeUid,
      this.taskCollaboratorRefs
    )

    return filteredPossibleStates.includes(actionType) // && this.isAuthorizedAction(actionType);
  }
}
