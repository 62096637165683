import { Directive, Input, OnChanges, OnInit, SimpleChange, EventEmitter, HostListener } from "@angular/core"
import { DrawingInterface, Item, DrawingAnnotation } from "../models/common"
import { LeafletService, XYBounds, XYCoord } from "../services"

import * as L from "leaflet"
import { Map, LatLng, LatLngBounds, Point, LeafletEvent, Marker } from "leaflet"
import { LeafletDirective, LeafletDirectiveWrapper } from "@asymmetrik/ngx-leaflet"

interface LeafletClickEvent {
  containerPoint: Point
  latlng: LatLng
  layerPoint: Point
  originalEvent: any
  target: any
  type: string
  [propName: string]: any
}

@Directive({
  selector: "[leafletCheckdItem]",
  outputs: ["itemClicked", "itemMoved"],
  standalone: true,
})
export class LeafletCheckdItemDirective {
  leafletDirective: LeafletDirective

  itemClicked = new EventEmitter()
  itemMoved = new EventEmitter()

  @Input("leafletLayer") layer: Marker

  constructor(protected leafletService: LeafletService, leafletDirective: LeafletDirective) {
    this.leafletDirective = leafletDirective
  }

  ngOnInit() {
    if (null != this.leafletDirective.getMap()) {
      this.preventMarkerToGoOutOfBounds(this.layer, this.leafletDirective.getMap())
      this.emitClickEvents()
      this.emitPositionChangeEvents()
    }
  }

  private preventMarkerToGoOutOfBounds(marker: Marker, map: Map) {
    let lastValidPosition = marker.getLatLng()
    let bounds = map.options.maxBounds as LatLngBounds
    marker.addEventListener("drag", (event) => {
      let newPosition = marker.getLatLng()
      if (bounds.contains(newPosition)) lastValidPosition = newPosition
      else marker.setLatLng(lastValidPosition)
    })
  }

  private emitClickEvents() {
    this.layer.addEventListener("click", (event) => {
      this.itemClicked.emit(event)
    })
  }

  private emitPositionChangeEvents() {
    // @ts-ignore
    this.layer.addEventListener("dragend", (event: LeafletClickEvent) => {
      let bounds = this.leafletDirective.getMap().options.maxBounds as LatLngBounds
      let newCoordinates = this.leafletService.normalizeLatLngCoordinates(
        this.layer.getLatLng(),
        bounds.getNorthWest(),
        bounds.getSouthEast()
      )
      this.itemMoved.emit(Object.assign({ layer: this.layer }, this.normalizeCoords(event, this.leafletDirective.getMap())))
    })
  }

  // Returns an object with x and y attributes between 0.0 and 1.0, e.g.:
  //
  // {x: 0.0, y: 0.0} is the upper left corner of the image/map
  // {x: 1.0, y: 1.0} is the bottom right corner of the image/map
  private normalizeCoords(event: LeafletClickEvent, map: Map): { x: number; y: number } {
    let bounds = map.options.maxBounds as LatLngBounds
    return this.leafletService.normalizeLatLngCoordinates(this.layer.getLatLng(), bounds.getNorthWest(), bounds.getSouthEast())
  }
}
