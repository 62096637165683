import { GeneralReport } from "@models/common/general-report"
import { map, mergeMap, shareReplay, switchMap } from "rxjs/operators"
import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { Observable, of as observableOf, zip } from "rxjs"

import {
  COLLECTIONS,
  Company,
  Image,
  Item,
  LegacyTemplate,
  ModelInCollection,
  Person,
  Project,
  ReportStatus,
  RoleType,
  Task,
  Timeline,
  ITimelineData,
  TimelineElement,
  TimelineType,
} from "@models/common"
import { ModelService } from "./model.service"
import { RelationService } from "./relation.service"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import { AnalyticsService } from "@services/analytics.service"
import WriteBatch = firebase.firestore.WriteBatch

@Injectable({
  providedIn: "root",
})
export class TimelineService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private analyticsService: AnalyticsService
  ) {}

  public addItemComment(creator: Person, item: Item, message: string) {
    const data: ITimelineData = {
      type: TimelineType.COMMENT_ADDED,
      // @ts-ignore
      creator: creator.ref,
      message,
      createdDate: Date.now(),
      currentData: { item: item.data },
    }

    // @ts-ignore
    return this.addTimelineElement(creator, item, data)
  }

  public addItemImage(creator: Person, item: Item, image: Image) {
    const timelineData: ITimelineData = {
      type: TimelineType.IMAGE_ADDED,
      // @ts-ignore
      creator: creator.ref,
      createdDate: Date.now(),
      currentData: { image: image.data },
    }

    // @ts-ignore
    return this.addTimelineElement(creator, item, timelineData)
  }

  public batchAddItemImage(batch: WriteBatch, creator: Person, item: Item, image: Image) {
    const timelineData: ITimelineData = {
      type: TimelineType.IMAGE_ADDED,
      // @ts-ignore
      creator: creator.ref,
      createdDate: Date.now(),
      currentData: { image: image.data },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData)
  }

  public listen(item: Item): Observable<Timeline> {
    // @ts-ignore
    const timelineListener = this.listenFor(item).pipe(shareReplay({ bufferSize: 1, refCount: true }))

    const disablersListener = timelineListener.pipe(
      map((timeline) => timeline.elements.map((elm) => this.listenToDisabler(elm))),
      switchMap((it) => this.relationService.combineLatestOrEmptyList(it))
    )

    const creatorsListener = timelineListener.pipe(
      map((timeline) => timeline.elements.map((elm) => this.listenToCreator(elm))),
      switchMap((it) => this.relationService.combineLatestOrEmptyList(it))
    )

    const zipped = zip(timelineListener.pipe(map((it) => it.elements)), creatorsListener, disablersListener).pipe(
      map((it) => {
        return it[0].map((elm, i) =>
          Object.assign(elm, {
            // @ts-ignore
            creator: it && it[1] && it[1][i] && it[1][i].uid ? it[1][i] : null,
            // @ts-ignore
            disabler: it && it[2] && it[2][i] && it[2][i].uid ? it[2][i] : null,
          })
        )
      })
    )

    return zipped.pipe(mergeMap((elements) => timelineListener.pipe(map((timeline) => Object.assign({}, timeline, { elements })))))
  }

  public listenToCreator(timelineElement: TimelineElement): Observable<Person> {
    const creator = timelineElement.creatorUid
      ? this.modelService.listenTo(COLLECTIONS.PEOPLE, timelineElement.creatorUid)
      : // : EMPTY
        // @ts-ignore
        observableOf(new Person({ email: null }, null, null))

    return creator
  }

  public listenToDisabler(timelineElement: TimelineElement): Observable<Person> {
    const disabler = timelineElement.disabledBy
      ? this.modelService.listenTo(COLLECTIONS.PEOPLE, timelineElement.disabledBy)
      : // : EMPTY
        // @ts-ignore
        observableOf(new Person({ email: null }, null, null))

    return disabler
  }

  public listenFor(m: ModelInCollection): Observable<Timeline> {
    if (m.ref == null) {
      throw new Error(`No ref found for ${m.uid}`)
    }

    const qRef = this.db.doc(m.ref.path).collection(TimelineElement.COLLECTION, (ref) => ref.orderBy("createdDate", "asc"))

    return (
      qRef
        // TODO Sort based on created timestamp
        .snapshotChanges()
        .pipe(
          map((docSnapshots) => {
            const elements = docSnapshots.map(
              (docSnapshot) =>
                new TimelineElement(
                  docSnapshot.payload.doc.data() as ITimelineData,
                  docSnapshot.payload.doc.id,
                  docSnapshot.payload.doc.ref
                )
            )

            return new Timeline(elements, qRef.ref)
          })
        )
    )
  }

  public taskAssigneeAdded(creator: Person, item: Item, task: Task, assignee: Person) {
    const timelineData = {
      type: TimelineType.ASSIGNEE_ADDED,
      currentData: {
        task: task.data,
        person: assignee.data,
      },
    }

    // @ts-ignore
    return this.addTimelineElement(creator, item, timelineData)
  }

  public batchTaskAssigneeAdded(
    batch: firestore.WriteBatch,
    creator: Person,
    item: Item,
    task: Task,
    assignee: Person,
    triggerAnalytics = true
  ) {
    const timelineData = {
      type: TimelineType.ASSIGNEE_ADDED,
      currentData: {
        task: task.data,
        person: assignee.data,
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData, triggerAnalytics)
  }

  public batchCollaboratorAdded(
    batch: firestore.WriteBatch,
    creator: Person,
    item: Item,
    task: Task,
    collaborator: Person,
    triggerAnalytics = true
  ) {
    const timelineData = {
      type: TimelineType.COLLABORATOR_ADDED,
      currentData: {
        task: task.data,
        person: collaborator.data,
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData, triggerAnalytics)
  }

  public batchStatusChanged(batch: firestore.WriteBatch, creator: Person, item: Item, task?: Task, triggerAnalytics = true) {
    const timelineData = {
      type: TimelineType.STATUS_CHANGE,
      currentData: {
        item: item.data,
        task: task ? task.data : null,
      },
      previousData: {
        item: item.data,
        task: task ? task.data : null,
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData, triggerAnalytics)
  }

  public batchReportStatusChanged(
    batch: firestore.WriteBatch,
    creator: Person,
    report: GeneralReport,
    previousStatus: ReportStatus,
    currentStatus: ReportStatus
  ) {
    const timelineData = {
      type: TimelineType.STATUS_CHANGE,
      previousData: {
        report: {
          status: previousStatus,
        },
      },
      currentData: {
        report: {
          status: currentStatus,
        },
      },
    }

    return this.batchAddTimelineElement(batch, creator, report, timelineData, false)
  }

  /**
   * @deprecated Use batchItemCreated instead
   *
   */
  public itemCreated(creator: Person, item: Item) {
    const timelineData = {
      type: TimelineType.ITEM_CREATED,
      currentData: {
        item: item.data,
      },
    }

    // @ts-ignore
    return this.addTimelineElement(creator, item, timelineData)
  }

  public itemEdited(batch: firestore.WriteBatch, editor: Person, item: Item) {
    const timelineData = {
      type: TimelineType.ITEM_EDITED,
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, editor, item, timelineData)
  }

  public batchItemCreated(batch: firestore.WriteBatch, creator: Person, item: Item) {
    const timelineData = {
      type: TimelineType.ITEM_CREATED,
      currentData: {
        item: item.data,
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData)
  }

  public batchItemConnectedToReport(batch: firestore.WriteBatch, creator: Person, item: Item, report: GeneralReport) {
    const timelineData = {
      type: TimelineType.ITEM_CONNECTED_TO_REPORT,
      currentData: {
        report: {
          name: report.name,
          uid: report.uid,
        },
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData)
  }

  public batchItemRemovedFromReport(batch: firestore.WriteBatch, remover: Person, item: Item, report: GeneralReport) {
    const timelineData = {
      type: TimelineType.ITEM_REMOVED_FROM_REPORT,
      currentData: {
        report: {
          name: report.name,
          uid: report.uid,
        },
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, remover, item, timelineData)
  }

  public batchItemExportedToBCF(batch: firestore.WriteBatch, creator: Person, item: Item) {
    const timelineData = {
      type: TimelineType.ITEM_EXPORTED_TO_BCF,
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, item, timelineData)
  }

  public projectArchived(batch: firestore.WriteBatch, archiver: Person, project: Project) {
    const timelineData = {
      type: TimelineType.PROJECT_ARCHIVED,
      currentData: {
        project: project.data,
      },
    }

    return this.batchAddTimelineElement(batch, archiver, project, timelineData)
  }

  public projectCreated(batch: firestore.WriteBatch, creator: Person, project: Project) {
    const timelineData = {
      type: TimelineType.PROJECT_CREATED,
      currentData: {
        project: project.data,
      },
    }

    return this.batchAddTimelineElement(batch, creator, project, timelineData)
  }

  // NB Not in use yet as we haven't implemented an unarchiving feature for end users
  public projectUnArchived(batch: firestore.WriteBatch, unArchiver: Person, project: Project) {
    const timelineData = {
      type: TimelineType.PROJECT_UNARCHIVED,
      currentData: {
        project: project.data,
      },
    }

    return this.batchAddTimelineElement(batch, unArchiver, project, timelineData)
  }

  public projectDisabled(batch: firestore.WriteBatch, disabler: Person, project: Project) {
    const timelineData = {
      type: TimelineType.PROJECT_DISABLED,
      currentData: {
        project: project.data,
      },
    }

    return this.batchAddTimelineElement(batch, disabler, project, timelineData)
  }

  public projectMemberRemoved(batch: firestore.WriteBatch, remover: Person, removed: Person, project: Project) {
    const timelineType = remover.uid === removed.uid ? TimelineType.PROJECT_PERSON_LEFT : TimelineType.PROJECT_PERSON_REMOVED

    const timelineData = {
      type: timelineType,
      currentData: {
        project: project.data,
        person: removed.data,
      },
    }

    return this.batchAddTimelineElement(batch, remover, project, timelineData)
  }

  public projectMemberAdded(
    batch: firestore.WriteBatch,
    addedBy: Person,
    personAdded: Person,
    personAddedRole: RoleType,
    project: Project
  ) {
    const timelineData = {
      type: TimelineType.PROJECT_PERSON_ADDED,
      currentData: {
        project: project.data,
        person: personAdded.data,
        addedWithRole: personAddedRole,
      },
    }

    return this.batchAddTimelineElement(batch, addedBy, project, timelineData)
  }

  public addItemEdited(batch: firestore.WriteBatch, editedBy: Person, item: Item) {
    const timelineData = {
      type: TimelineType.ITEM_EDITED,
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, editedBy, item, timelineData)
  }

  public reportVisibilityChanged(
    batch: firestore.WriteBatch,
    changedBy: Person,
    forReport: ModelInCollection,
    visibilityType:
      | TimelineType.REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS
      | TimelineType.REPORT_SHARE_WITH_PROJECT_MEMBERS
      | TimelineType.REPORT_SHARE_WITH_EVERYONE
  ) {
    const timelineData = {
      type: visibilityType,
    }

    return this.batchAddTimelineElement(batch, changedBy, forReport, timelineData)
  }

  public itemImageRemoved(batch: firestore.WriteBatch, remover: Person, item: Item, image: Image) {
    // @ts-ignore
    return this.batchAddTimelineElement(batch, remover, item, {
      currentData: { image: image.data },
      type: TimelineType.IMAGE_REMOVED,
    })
  }

  public batchItemDueDateUpdate(batch: firestore.WriteBatch, creator: Person, timelineForItem: Item, newDate: number | null) {
    const timelineData = {
      type: TimelineType.DUE_DATE_SET,
      currentData: {
        item: {
          dueDate: newDate,
        },
      },
      previousData: {
        item: {
          dueDate: timelineForItem.dueDate ? timelineForItem.dueDate * 1000 : null,
        },
      },
    }

    // @ts-ignore
    return this.batchAddTimelineElement(batch, creator, timelineForItem, timelineData)
  }

  companyLegacyTemplateRemoved(batch: firestore.WriteBatch, remover: Person, company: Company, template: LegacyTemplate) {
    const timelineData = {
      type: TimelineType.LEGACY_TEMPLATE_REMOVED,
      currentData: {
        legacyTemplate: {
          uid: template.uid,
          name: template.name,
        },
      },
    }

    return this.batchAddTimelineElement(batch, remover, company, timelineData, false)
  }

  /**
   * @deprecated
   */
  public async addTimelineElement(creator: Person, m: ModelInCollection, data: any, triggerAnalytics = true) {
    const mergedData = {
      ...data,
      creator: creator.ref,
      createdDate: Date.now(),
    }
    const timeline = await Timeline.getFor(m)

    await timeline.add(mergedData)

    if (triggerAnalytics) {
      await this.analyticsService.logTimelineEvent(data.type || TimelineType.NONE)
    }
  }

  public async batchAddTimelineElement(
    batch: firestore.WriteBatch,
    creator: Person,
    model: ModelInCollection,
    data: any,
    triggerAnalytics = true
  ) {
    const mergedData = {
      ...data,
      creator: (creator && creator.ref) || null,
      createdDate: Date.now(),
    }

    Timeline.batchAdd(batch, model, mergedData)
    if (triggerAnalytics) {
      await this.analyticsService.logTimelineEvent(data.type || TimelineType.NONE)
    }
  }
}
