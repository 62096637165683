import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"

import { Drawing, DrawingData } from "@models/common"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyRadioModule } from "@angular/material/legacy-radio"
import { NgIf } from "@angular/common"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

@Component({
  selector: "app-drawing-details-dialog",
  templateUrl: "./drawing-details-dialog.component.html",
  styleUrls: ["./drawing-details-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    FormsModule,
    NgIf,
    MatLegacyRadioModule,
    MatLegacyButtonModule,
  ],
})
export class DrawingDetailsDialogComponent implements OnInit {
  densityNumber: number
  showQualitySelector: boolean = false
  densityNumberIsSet: boolean = false

  drawing: Drawing
  drawingData: DrawingData

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.drawing = this.data.drawing
    this.drawingData = this.drawing.data
    // @ts-ignore
    this.handleQuality(null)
  }

  qualityChange(newQuality: number) {
    this.handleQuality(newQuality)
  }

  public quality(): number {
    if (this.densityNumberIsSet) {
      return this.densityNumber
    }

    const splittedUrl = this.drawingData.storage.url.split("/") // index 3 contains conversion information

    if (this.drawingData.storage.url.indexOf("density") !== -1) {
      const conversionSplitted = splittedUrl[3].split(",")
      const densitySplitted = conversionSplitted[conversionSplitted.length - 1].split(":")
      const density = densitySplitted[densitySplitted.length - 1]
      this.densityNumber = +density

      this.densityNumberIsSet = true

      return this.densityNumber
    }

    return -1
  }

  private handleQuality(newQuality: number) {
    if (this.drawingData.storage.fileType !== "application/pdf") return
    if (this.drawingData.storage.url.indexOf("filestack") === -1) return

    this.showQualitySelector = true

    const splittedUrl = this.drawingData.storage.url.split("/") // index 3 contains conversion information

    if (this.drawingData.storage.url.indexOf("density") !== -1) {
      const conversionSplitted = splittedUrl[3].split(",")
      const densitySplitted = conversionSplitted[conversionSplitted.length - 1].split(":")
      const density = densitySplitted[densitySplitted.length - 1]
      this.densityNumber = +density

      if (newQuality) {
        conversionSplitted[conversionSplitted.length - 1] = "density:" + newQuality
        splittedUrl[3] = conversionSplitted.join(",")
        this.drawingData.storage.url = splittedUrl.join("/")
      }
    } else {
      if (newQuality && this.drawingData.storage.url.indexOf("output") !== -1) {
        // Add density factor
        splittedUrl[3] += ",density:" + newQuality
        this.drawingData.storage.url = splittedUrl.join("/")
      }
    }
  }
}
