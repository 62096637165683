import { Component, OnDestroy, OnInit } from "@angular/core"
import { NavigationEnd, Router, RouterLink } from "@angular/router"
import { CompanyFeatures } from "@models/common"
import { FirebaseAuthService, NavigationService, ProjectService, UserService } from "@services"
import { SideNavService } from "@services/side-nav.service"
import { combineLatest, firstValueFrom, Observable, of, startWith, Subject, timeout } from "rxjs"
import { catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, take, takeUntil, tap } from "rxjs/operators"
import { AvatarModule } from "ngx-avatars"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { NotificationsComponent } from "../notifications/notifications.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatToolbarModule } from "@angular/material/toolbar"
import { NgIf, NgClass, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-top-bar",
  templateUrl: "./top-bar.component.html",
  styleUrls: ["./top-bar.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    MatToolbarModule,
    NgClass,
    ExtendedModule,
    MatLegacyButtonModule,
    MatIconModule,
    RouterLink,
    NotificationsComponent,
    MatLegacyMenuModule,
    AvatarModule,
    AsyncPipe,
  ],
})
export class TopBarComponent implements OnInit, OnDestroy {
  pathComponents: string[]

  constructor(
    public userService: UserService,
    private firebaseAuth: FirebaseAuthService,
    private projectService: ProjectService,
    public navigationService: NavigationService,
    public sideNavService: SideNavService,
    private router: Router,
  ) {}

  readonly ngUnsubscribe = new Subject<void>()

  readonly currentUser$ = this.userService.currentUser$
  readonly currentProject$ = this.projectService.currentProject$
  readonly currentCompany$ = this.userService.currentCompany$

  readonly userName$ = this.currentUser$.pipe(
    map((user) => (user?.name ? user.name : "")),
    distinctUntilChanged(),
  )

  readonly userEmail$ = combineLatest([this.currentUser$, this.firebaseAuth.authState$]).pipe(
    map(([user, authedUser]) => {
      if (authedUser && authedUser.email) {
        return authedUser.email
      }

      return user?.email || ""
    }),
    distinctUntilChanged(),
  )

  readonly userImage$ = this.currentUser$.pipe(
    map((user) => user?.image || ""),
    distinctUntilChanged(),
  )

  readonly projectName$ = this.currentProject$.pipe(
    map((project) => project?.name || ""),
    distinctUntilChanged(),
  )

  readonly currentCompanyName$ = this.currentCompany$.pipe(
    map((company) => company?.name || ""),
    distinctUntilChanged(),
  )

  ngOnInit() {
    this.navigationService.pathComponents$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((pathComponents) => (this.pathComponents = pathComponents))

    // this.userCompanyHasFieldChatFeature$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     map((data) => data.includes(true)),
    //     distinctUntilChanged()
    //   )
    //   .subscribe(async (hasChatFeature) => {
    //     if (hasChatFeature) {
    //       try {
    //         await this.fieldChatDashboardService.initializeChatClient()
    //       } catch (e: any) {
    //         console.error(e)
    //       }
    //     }
    //   })
  }

  async ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
    // TODO: uncomment these two when merging OneLogin
    // this.authority$.complete()
    // this.target$.complete()
    // await this.fieldChatDashboardService.disconnectUser()
  }

  async logout() {
    await this.userService.logout()

    // If the user is currently on an invitation page, just reload the page
    if (this.pathComponents.includes("invitations")) {
      return window.location.reload()
    }

    // Redirecto to login in all other cases
    return this.navigationService.goToLoginPage()
  }

  get darkTopBar() {
    return this.pathComponents && this.pathComponents.includes("insights")
  }

  get useShadow() {
    const withoutShadow = [
      "insights", // TODO: project page
    ]

    return this.pathComponents && !!withoutShadow.filter((value) => this.pathComponents.includes(value))
  }

  async onChatBubbleClicked() {
    const isInProject = this.projectService.isInProjectTest(this.router.url)

    let url: string
    if (isInProject) {
      const project = await firstValueFrom(
        this.projectService.currentProject$.pipe(
          take(1),
          timeout(1000),
          catchError((err) => of(null)),
        ),
      )

      if (project === null) {
        return console.error("Could not get current project UID")
      }

      url = this.router.createUrlTree(["/chat"], { queryParams: { projectUid: project.uid, projectName: project.name } }).toString()
    } else {
      url = this.router.createUrlTree(["/chat"]).toString()
    }

    window.open(url, "_blank")
  }
}
