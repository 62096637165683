import firebase from "firebase/compat"
import { ImageData } from "./image.interface"
import { PersonData } from "./person.interface"
import { InvitationData } from "./invitation.interface"
import { ItemData } from "./item.interface"
import { ReportData } from "./report"
import { TaskData } from "./task"
import DocumentReference = firebase.firestore.DocumentReference

export enum TimelineType {
  ASSIGNEE_ADDED = "ASSIGNEE_ADDED",
  ASSIGNEE_INVITED = "ASSIGNEE_INVITED",
  COLLABORATOR_ADDED = "COLLABORATOR_ADDED",
  COMMENT_ADDED = "COMMENT_ADDED",
  IMAGE_ADDED = "IMAGE_ADDED",
  IMAGE_REMOVED = "IMAGE_REMOVED",
  ITEM_CREATED = "ITEM_CREATED",
  ITEM_EDITED = "ITEM_EDITED",
  ITEM_EXPORTED_TO_BCF = "ITEM_EXPORTED_TO_BCF",
  NONE = "NONE",
  PROJECT_ARCHIVED = "PROJECT_ARCHIVED",
  PROJECT_CREATED = "PROJECT_CREATED",
  PROJECT_DISABLED = "PROJECT_DISABLED",
  PROJECT_INVITATION_ACCEPTED = "PROJECT_INVITATION_ACCEPTED",
  PROJECT_INVITATION_SENT = "PROJECT_INVITATION_SENT",
  PROJECT_PERSON_ADDED = "PROJECT_PERSON_ADDED",
  PROJECT_PERSON_LEFT = "PROJECT_PERSON_LEFT",
  PROJECT_PERSON_REMOVED = "PROJECT_PERSON_REMOVED",
  PROJECT_UNARCHIVED = "PROJECT_UNARCHIVED",
  REPORT_READONLY_CHANGED = "REPORT_READONLY_CHANGED",
  STATUS_CHANGE = "STATUS_CHANGE",
  TEMPLATE_CREATED = "TEMPLATE_CREATED",
  TEMPLATE_PUBLISHED = "TEMPLATE_PUBLISHED",
  TEMPLATE_UNPUBLISHED = "TEMPLATE_UNPUBLISHED",
  REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS = "REPORT_SHARE_WITH_PROJECT_OWNERS_AND_ADMINS",
  REPORT_SHARE_WITH_PROJECT_MEMBERS = "REPORT_SHARE_WITH_PROJECT_MEMBERS",
  REPORT_SHARE_WITH_EVERYONE = "REPORT_SHARE_WITH_EVERYONE",
  REPORT_TAGS_EDITED = "REPORT_TAGS_EDITED",
  DUE_DATE_SET = "DUE_DATE_SET",
  LEGACY_TEMPLATE_REMOVED = "LEGACY_TEMPLATE_REMOVED",
  COMPANY_INVITATION_ACCEPTED = "COMPANY_INVITATION_ACCEPTED",
  COMPANY_MEMBER_REMOVED = "COMPANY_MEMBER_REMOVED",
  REPORT_DUPLICATED = "REPORT_DUPLICATED",
  ITEM_CONNECTED_TO_REPORT = "ITEM_CONNECTED_TO_REPORT",
  ITEM_REMOVED_FROM_REPORT = "ITEM_REMOVED_FROM_REPORT",
}

export interface ITimelineDataSnapshot {
  item?: ItemData
  task?: TaskData
  image?: ImageData
  person?: PersonData
  invitation?: InvitationData
  report?: ReportData
  dueDate?: number | null
}

export interface ITimelineData {
  type: TimelineType
  creator: DocumentReference
  message?: string
  previousData?: ITimelineDataSnapshot
  currentData?: ITimelineDataSnapshot
  createdDate?: number
  disabled?: boolean
  disabledBy?: string
  disabledAt?: number
  bcfGuid?: string
}

export interface ITimelineDataForBcfExport extends ITimelineData {
  creatorEmail: string
}
