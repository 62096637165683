import { Injectable } from "@angular/core"
import { PdfDataService } from "./pdf-data.service"
import { ICheckdFormConfig, Form } from "../checkd-form/models/form"

@Injectable({
  providedIn: "root",
})
export class ExportService {
  constructor(private pdfDataService: PdfDataService) {}

  formToDocDefinition(form: Form) {
    return {}
  }

  exportReportToPdf(
    form: Form,
    projectTitle: string,
    user: any,
    qrcodeLink: string | null = null,
    companyLogo: string | null = null,
    createdAt: string | null = null,
    creatorName: string | null = null,
    filename: string | null = null,
    pdfFooter: string | null = null,
    templateVersion: string | null = null,
    footerLogo: string | null = null
  ) {
    const docDefinition = form.toPdfDocDefinition(
      projectTitle,
      user,
      // @ts-ignore
      qrcodeLink,
      companyLogo,
      createdAt,
      creatorName,
      // @ts-ignore
      null,
      null,
      pdfFooter,
      {},
      null,
      templateVersion,
      footerLogo
    )

    this.pdfDataService
      .generateReport(docDefinition)
      .download(filename != null ? filename : `${form.number ? form.number : ""} ${form.reportName}.pdf`)
  }
}
