import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core"
import { Person } from "@models/common"

import { FormControl } from "@angular/forms"
import { AvatarModule } from "ngx-avatars"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "people-list-item",
  templateUrl: "./people-list-item.component.html",
  styleUrls: ["./people-list-item.component.scss"],
  standalone: true,
  imports: [FlexModule, AvatarModule],
})
export class PeopleListItemComponent implements OnInit {
  @Input() person: Person
  @Input("name") _name: string
  @Input("email") _email: string

  constructor() {}

  ngOnInit() {}

  get avatarName() {
    return this.name || this.email
  }

  get name() {
    if (this.person != null && this.person.name != null) return this.person.name
    if (this._name != null) return this._name
    return ""
  }

  get email() {
    if (this.person != null && this.person.email != null) return this.person.email
    if (this._email != null) return this._email
    return ""
  }

  get image() {
    return this.person && this.person.image ? this.person.image : ""
  }
}
