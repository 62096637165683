import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"

import { Drawing, DummyRole, IMenuOption, Person, Role } from "@models/common"

import { DrawingService, FilestackService } from "@services/index"
import { DrawingContextmenuComponent } from "../drawing-contextmenu/drawing-contextmenu.component"
import { NgIf } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"

@Component({
  selector: "app-drawing-list-item",
  templateUrl: "./drawing-list-item.component.html",
  styleUrls: ["./drawing-list-item.component.scss"],
  standalone: true,
  imports: [MatLegacyTooltipModule, NgIf, DrawingContextmenuComponent],
})
export class DrawingListItemComponent implements OnInit {
  @Input() drawing: Drawing
  @Input() userProjectRole: Role = new DummyRole()
  @Input() currentUser: Person

  @Output() drawingClick = new EventEmitter()
  @Output() menuOptionSelected = new EventEmitter<IMenuOption>()

  menuOptions: IMenuOption[] = []
  readonly THUMBNAIL_SIZE = 190

  constructor(private drawingService: DrawingService, private fs: FilestackService) {}

  ngOnInit() {
    this.menuOptions = this.drawingService.getMenuOptions(this.drawing, this.userProjectRole)
  }

  onDrawingClick() {
    this.drawingClick.emit(this.drawing)
  }

  drawingThumbnail() {
    if (this.drawing && this.drawing.storage && this.drawing.storage.url && this.drawing.storage.url.trim()) {
      return this.fs.getThumbnailUrl(this.drawing.storage.url.trim(), this.THUMBNAIL_SIZE, undefined, undefined, "crop")
    }

    return null
  }

  get itemsCountStr() {
    return `${this.itemsCount} item${this.itemsCount !== 1 ? "s" : ""}`
  }

  get itemsCount() {
    return this.drawing && this.drawing.aggregateData && this.drawing.aggregateData["itemsCount"] != null
      ? this.drawing.aggregateData["itemsCount"]
      : 0
  }

  get aggregateData() {
    return this.drawing && this.drawing.aggregateData ? this.drawing.aggregateData : {}
  }
  get totalAndActiveItemsPerUser() {
    return this.aggregateData["totalAndActiveItemsPerUser"] || {}
  }
  get currentUserActiveItems() {
    if (this.currentUser != null && this.currentUser.uid != null && this.totalAndActiveItemsPerUser[this.currentUser.uid] != null) {
      return this.totalAndActiveItemsPerUser[this.currentUser.uid].active || 0
    }

    return null
  }
}
