import { Component, EventEmitter, Inject, OnInit, Output } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar"
import { CheckdFormDialogService } from "@checkd-form/services/checkd-form-dialog.service"
import { MatDividerModule } from "@angular/material/divider"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { ClipboardModule } from "ngx-clipboard"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"

@Component({
  selector: "app-report-sharing-dialog",
  templateUrl: "./report-sharing-dialog.component.html",
  styleUrls: ["./report-sharing-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    MatLegacyButtonModule,
    MatIconModule,
    ClipboardModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatDividerModule,
  ],
})
export class ReportSharingDialogComponent implements OnInit {
  // public _link: string;
  public isCopied: boolean
  @Output() sendEmail = new EventEmitter()

  constructor(
    private dialogRef: MatDialogRef<ReportSharingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private dialogService: CheckdFormDialogService
  ) {}

  get link() {
    if (this.data && this.data.reportSharingLink) return this.data.reportSharingLink
    return ""
  }

  get message() {
    if (this.data && this.data.message) return this.data.message
    return ""
  }

  get shouldShowAnchor() {
    return this.data.showAnchor || false
  }

  get shouldCloseOnCopyLink() {
    return this.data.closeOnCopyLink || false
  }

  ngOnInit() {}

  showMessage() {
    this.snackBar.open("Link copied to clipboard.", "", {
      duration: 2000,
    })
    if (this.shouldCloseOnCopyLink) {
      this.dialogRef.close()
    }
  }

  sendByMail() {
    this.dialogRef.close({ shareByEmail: true })
  }

  shareToNextProject() {
    this.dialogService.shareReportWithNextProject(this.data.project, this.data.report)
    this.dialogRef.close()
  }
}
