import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core"
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule } from "@angular/material/legacy-paginator"
import { MatSort, MatSortModule } from "@angular/material/sort"
import { MatLegacyTableDataSource as MatTableDataSource, MatLegacyTableModule } from "@angular/material/legacy-table"
import { CheckdAcSearchComponent, CheckdDaterangeComponent } from "@checkd-ui"
import { COLLECTIONS, IReportMenuActionsResponse, Person, Role } from "@models/common"
import { GeneralReport } from "@models/common/general-report"
import { TagCollection } from "@models/common/tag-collection"
import * as moment from "moment"
import { IMultiselect, CheckdMultiselectComponent } from "../../checkd-ui/checkd-multiselect/checkd-multiselect.component"
import { MomentModule } from "ngx-moment"
import { FlexModule } from "@angular/flex-layout/flex"
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader"
import { ReportContextmenuComponent } from "../report-contextmenu/report-contextmenu.component"
import { CheckdDaterangeComponent as CheckdDaterangeComponent_1 } from "../../checkd-ui/checkd-daterange/checkd-daterange.component"
import { CheckdTagsComponent } from "../../checkd-ui/checkd-tags/checkd-tags.component"
import { CheckdAcSearchComponent as CheckdAcSearchComponent_1 } from "../../checkd-ui/checkd-ac-search/checkd-ac-search.component"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatIconModule } from "@angular/material/icon"
import { NgIf } from "@angular/common"
import { ExtendedModule } from "@angular/flex-layout/extended"

enum ReportType {
  ITEM_REPORT = "ITEM_REPORT",
  FORM_REPORT = "FORM_REPORT",
  TEMPLATE = "TEMPLATE",
}

interface ReportRow {
  report: GeneralReport
  type: ReportType
  name: string
  templateName: string
  reportCreatorName: string
  reportCreatorCompanyName: string
  createdAt: number
  updatedAt: number
  totalItems: number
  itemsCount: number
}

interface ReportRowFilter {
  title: string
  tags: IMultiselect
  templateName: string
  reportCreatorNames: IMultiselect
  reportCreatorCompanyNames: IMultiselect
  createdAt?: { from?: number; to?: number }
  updatedAt?: { from?: number; to?: number }
}

function createDefaultReportRowFilter(): ReportRowFilter {
  return {
    title: "",
    templateName: "",
    tags: { selected: [], deselected: [] },
    reportCreatorNames: { selected: [], deselected: [] },
    reportCreatorCompanyNames: { selected: [], deselected: [] },
  }
}

@Component({
  selector: "checkd-report-list",
  templateUrl: "./report-list.component.html",
  styleUrls: ["./report-list.component.scss"],
  standalone: true,
  imports: [
    ExtendedModule,
    NgIf,
    MatLegacyTableModule,
    MatSortModule,
    MatIconModule,
    MatLegacyTooltipModule,
    CheckdAcSearchComponent_1,
    CheckdTagsComponent,
    CheckdMultiselectComponent,
    CheckdDaterangeComponent_1,
    ReportContextmenuComponent,
    NgxSkeletonLoaderModule,
    FlexModule,
    MatLegacyPaginatorModule,
    MomentModule,
  ],
})
export class ReportListComponent implements OnInit {
  @Input() set reports(reports: GeneralReport[]) {
    this._reports = reports
    this.transformedReports = (this._reports || []).map((report) => this.transformReportData(report))
    this.setupSearchValues(this.transformedReports)
    this.dataSource.data = this.transformedReports
    if (this._reports != null) {
      this.isLoading = false
    }
  }

  @Input() isReportListReady: boolean

  @Input() columns: string[]
  @Input() currentUser: Person
  @Input() userProjectRole: Role
  @Input() reportsTagCollection: TagCollection

  @Output() menuOptionSelected = new EventEmitter<IReportMenuActionsResponse>()
  @Output() reportSelected = new EventEmitter<GeneralReport>()

  @ViewChild("nameFilter") nameFilter: CheckdAcSearchComponent
  @ViewChild("templateFilter") templateFilter: CheckdAcSearchComponent
  @ViewChild("reportCreatorNameFilter") reportCreatorNameFilter: CheckdAcSearchComponent
  @ViewChild("reportCreatorCompanyNameFilter") reportCreatorCompanyNameFilter: CheckdAcSearchComponent
  @ViewChild("createdAtFilter") createdAtFilter: CheckdDaterangeComponent
  @ViewChild("updatedAtFilter") updatedAtFilter: CheckdDaterangeComponent
  @ViewChild("tagsFilter") tagsFilter: CheckdAcSearchComponent

  @ViewChild(MatSort, { static: true }) sort: MatSort
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator

  pageSizes: number[] = [15, 30, 50]

  isLoading: boolean = true

  filterValues: ReportRowFilter = createDefaultReportRowFilter()

  _reports: GeneralReport[]
  transformedReports: ReportRow[]

  dataSource = new MatTableDataSource<ReportRow>()

  defaultDisplayedColumns: string[] = [
    "type",
    "name",
    "template",
    "tags",
    "reportCreatorName",
    "reportCreatorCompanyName",
    "createdAt",
    "updatedAt",
    "menu",
  ]

  reportCreatorNames: string[] = []
  reportCreatorCompanyNames: string[] = []
  reportTags: string[] = []

  ngOnInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.filterPredicate = this.tableFilter()
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        // case 'numberAndStatus': return item.numberAndStatus.number
        default:
          // @ts-ignore
          return item[property]
      }
    }
    this.dataSource.sort = this.sort
  }

  resetFilters() {
    this.filterValues = createDefaultReportRowFilter()
    this.dataSource.filter = JSON.stringify(this.filterValues)

    const filters = [
      this.nameFilter,
      this.templateFilter,
      this.reportCreatorNameFilter,
      this.reportCreatorCompanyNameFilter,
      this.createdAtFilter,
      this.updatedAtFilter,
      this.tagsFilter,
    ].filter((it) => it)

    filters.forEach((filter) => filter.clear())
  }

  get displayedColumns() {
    return this.columns || this.defaultDisplayedColumns
  }

  get displayedSearchColumns() {
    return this.displayedColumns.map((col) => col + "Search")
  }

  getReportType(report: GeneralReport): ReportType {
    switch (report.collectionName) {
      case COLLECTIONS.REPORTS:
        return ReportType.ITEM_REPORT
      case COLLECTIONS.LEGACY_REPORTS:
        return ReportType.FORM_REPORT
      case COLLECTIONS.OPEN_REPORTS:
        return ReportType.FORM_REPORT
      case COLLECTIONS.LEGACY_TEMPLATES:
        return ReportType.TEMPLATE
      default:
        throw new Error(`Unrecognized report collection ${report.collectionName}`)
    }
  }

  transformReportData(report: GeneralReport): ReportRow {
    return {
      report,
      type: this.getReportType(report),
      name: report.name || "",
      templateName: report.aggregateData["legacyTemplateName"] || "",
      reportCreatorName: report.aggregateData["creatorName"] || "",
      createdAt: (report.createdAt || 0) * 0.001,
      updatedAt: (report.updatedAt || 0) * 0.001,
      totalItems: report.totalItems,
      reportCreatorCompanyName: report.aggregateData["creatorCompanyName"],
      itemsCount: report.aggregateData["itemsCount"] || 0,
    }
  }

  onAcFilterChange(type: string, event: any) {
    // @ts-ignore
    this.filterValues[type] = event
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }

  onDateRangeSelection(key: string, val: [string, string]) {
    if (!val) {
      return
    }

    const fromDate = val[0] ? moment(val[0]).unix() : null
    const toDate = val[1] ? moment(val[1]).unix() : null

    this.onAcFilterChange(key, { from: fromDate, to: toDate })
  }

  tableFilter(): (reportRow: ReportRow, filter: string) => boolean {
    const checkDateRange = (timestamp: number, timestampFrom: number, timestampTo: number) => {
      if (!timestampFrom && !timestampTo) {
        return true
      }
      if (!timestamp) {
        return false
      }
      if (timestampFrom && timestamp < timestampFrom) {
        return false
      }
      if (timestampTo && timestamp > timestampTo) {
        return false
      }

      return true
    }

    const filterFunction = function (reportRow: ReportRow, filter: string): boolean {
      const searchTerms = JSON.parse(filter) as ReportRowFilter

      const title = (searchTerms.title || "").trim().toLowerCase()
      const titleMatch = title === "" || (reportRow.name || "").trim().toLowerCase().includes(title)

      const templateName = (searchTerms.templateName ?? "").trim().toLowerCase()
      const templateNameMatch = templateName === "" || (reportRow.templateName ?? "").toLowerCase().includes(templateName)

      const tags = searchTerms.tags.selected || []
      const tagsMatch = tags.length < 1 || tags.every((tag) => reportRow.report.tags.indexOf(tag) > -1)

      const deselectedTags = searchTerms.tags.deselected || []
      const deselectedTagsMatch = deselectedTags.some((tag) => (reportRow.report.tags || []).includes(tag))

      const reportCreatorNames = searchTerms.reportCreatorNames.selected || []
      const reportCreatorNamesMatch = reportCreatorNames.length < 1 || reportCreatorNames.indexOf(reportRow.reportCreatorName) > -1

      const deselectedReportCreatorNames = searchTerms.reportCreatorNames.deselected || []
      const deselectedReportCreatorNamesMatch = deselectedReportCreatorNames.includes(reportRow.reportCreatorName)

      const reportCreatorCompanyNames = searchTerms.reportCreatorCompanyNames.selected || []
      const reportCreatorCompanyNamesMatch =
        reportCreatorCompanyNames.length < 1 || reportCreatorCompanyNames.indexOf(reportRow.reportCreatorCompanyName) > -1

      const deselectedCreatorCompanyNames = searchTerms.reportCreatorCompanyNames.deselected || []
      const deselectedCreatorCompanyNamesMatch = deselectedCreatorCompanyNames.includes(reportRow.reportCreatorCompanyName)

      // @ts-ignore
      const createdAtMatch = checkDateRange(reportRow.createdAt, (searchTerms.createdAt || {}).from, (searchTerms.createdAt || {}).to)
      // @ts-ignore
      const updatedAtMatch = checkDateRange(reportRow.updatedAt, (searchTerms.updatedAt || {}).from, (searchTerms.updatedAt || {}).to)

      return (
        titleMatch &&
        templateNameMatch &&
        reportCreatorNamesMatch &&
        reportCreatorCompanyNamesMatch &&
        createdAtMatch &&
        updatedAtMatch &&
        tagsMatch &&
        !deselectedTagsMatch &&
        !deselectedReportCreatorNamesMatch &&
        !deselectedCreatorCompanyNamesMatch
      )
    }

    return filterFunction
  }

  setupSearchValues(data: ReportRow[]) {
    this.reportCreatorNames = Array.from(new Set(data.map((row) => row.reportCreatorName).filter((d) => d)))
    this.reportCreatorCompanyNames = Array.from(new Set(data.map((row) => row.reportCreatorCompanyName).filter((d) => d)))
    this.reportTags = Array.from(new Set(data.map((row) => row.report.tags.filter(Boolean)).flat()))
  }

  onMenuOptionSelected(event: IReportMenuActionsResponse) {
    this.menuOptionSelected.emit(event)
  }

  reportClicked(row: ReportRow) {
    this.reportSelected.emit(row.report)
  }
}
