import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { Subscription } from "rxjs"
import { distinctUntilChanged } from "rxjs/operators"
import { NgIf } from "@angular/common"
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

@Component({
  selector: "checkd-daterange",
  templateUrl: "./checkd-daterange.component.html",
  styleUrls: ["./checkd-daterange.component.scss"],
  standalone: true,
  imports: [MatLegacyFormFieldModule, MatDatepickerModule, FormsModule, ReactiveFormsModule, NgIf],
})
export class CheckdDaterangeComponent implements OnInit, OnDestroy {
  @Input() placeholder: string
  @Output() onSelection = new EventEmitter<[string, string]>()

  rangeForm: UntypedFormGroup
  subscriptions = new Subscription()

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.rangeForm = this.fb.group({
      start: null,
      end: null,
    })

    this.subscriptions.add(
      this.rangeForm.valueChanges
        .pipe(
          distinctUntilChanged((a, b) => {
            return a.start === b.start && a.end === b.end
          })
        )
        .subscribe((rangeObj) => {
          if (this.rangeForm.valid && rangeObj.start && rangeObj.end) {
            this.onSelection.emit([rangeObj.start, rangeObj.end])
          }
        })
    )
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

  clear() {
    this.rangeForm.reset()
  }

  get _placeholder() {
    if (this.placeholder) {
      return this.placeholder
    }

    return "Enter a date range"
  }
}
