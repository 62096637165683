import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { convertWorkflowStateToStatus, Item, Project, Task } from "@models/common"
import { ItemService } from "@services"
import { Observable } from "rxjs"
import { map } from "rxjs/operators"
import { CheckdTagsComponent } from "../../checkd-ui/checkd-tags/checkd-tags.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { TextFieldModule } from "@angular/cdk/text-field"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgIf, NgClass, AsyncPipe } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "item-info",
  templateUrl: "./item-info.component.html",
  styleUrls: ["./item-info.component.scss"],
  standalone: true,
  imports: [
    FlexModule,
    NgIf,
    NgClass,
    ExtendedModule,
    MatLegacyInputModule,
    TextFieldModule,
    MatLegacyButtonModule,
    MatIconModule,
    CheckdTagsComponent,
    AsyncPipe,
  ],
})
export class ItemInfoComponent implements OnInit {
  @Input() item: Item
  @Input() project: Project
  @Input() areTagsEditable: boolean
  @Input() setFocusOnTagInput: EventEmitter<boolean>
  @Output("onTagsUpdated") onTagsUpdatedEmitter = new EventEmitter<string[]>()

  task: Task
  item$: Observable<Item>
  itemTags$: Observable<String[]>

  get creatorName() {
    return this.item.aggregateData.itemCreatorName || ""
  }
  get taskAssignerName() {
    return this.item.aggregateData.taskAssignerName || ""
  }
  get taskAssigneeName() {
    return this.item.aggregateData.taskAssigneeName || ""
  }

  constructor(private itemService: ItemService) {}

  ngOnInit() {
    this.setupListeners()
  }

  setupListeners() {
    this.item$ = this.itemService.listenTo(this.item)
    this.itemTags$ = this.itemService.listenTo(this.item).pipe(map((item) => item.tags))
  }

  onTagsUpdated(event: string[]) {
    this.onTagsUpdatedEmitter.emit(event)
  }

  get status() {
    return convertWorkflowStateToStatus(this.item && this.item.status ? this.item.status : "OPEN")
  }

  get statusClasses() {
    return ["item-cell", "item-status", this.getStatusColorClass(this.status)]
  }

  getStatusColorClass(status: string) {
    return this.itemService.statusToColorClass(status)
  }
}
