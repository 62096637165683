import { Component, OnInit, Input } from "@angular/core"

import { TimelineElement, Person } from "@models/common"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgClass } from "@angular/common"

@Component({
  selector: "timeline-comment-element",
  templateUrl: "./timeline-comment-element.component.html",
  styleUrls: ["./timeline-comment-element.component.scss"],
  standalone: true,
  imports: [NgClass, ExtendedModule],
})
export class TimelineCommentElementComponent implements OnInit {
  @Input() timeline: TimelineElement
  @Input() creator: Person
  @Input() currentUser: Person

  constructor() {}

  ngOnInit() {}

  get cssClass() {
    return [this.bubbleClass]
  }
  get bubbleClass() {
    return this.isCreatorCurrentUser() ? "bubble-right" : "bubble-left"
  }

  get message() {
    return this.createTextLinks(this.timeline.message || "")
  }

  get layoutClass() {
    return "row"
  }

  get layoutAlign() {
    return this.isCreatorCurrentUser() ? "end" : "start"
  }

  isCreatorCurrentUser() {
    return this.creator && this.currentUser && this.creator.uid == this.currentUser.uid
  }

  private createTextLinks(text: string) {
    return (text || "").replace(/([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi, (match, space, url) => {
      let hyperlink = url
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink
      }
      return space + '<a href="' + hyperlink + '" target="_blank">' + url + "</a>"
    })
  }
}
