import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Person } from "@models/common"
import { FormsModule } from "@angular/forms"
import { MatLegacyRadioModule } from "@angular/material/legacy-radio"
import { NgIf, NgFor } from "@angular/common"

@Component({
  selector: "app-people-list-dialog",
  templateUrl: "./people-list-dialog.component.html",
  styleUrls: ["./people-list-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, MatDialogModule, MatLegacyRadioModule, FormsModule, NgFor],
})
export class PeopleListDialogComponent {
  people: Person[]
  title: string
  selectedPerson: Person

  constructor(
    private dialogRef: MatDialogRef<PeopleListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { people: Person[]; title?: string }
  ) {
    this.people = data.people
    this.title = data.title!
  }

  personSelected() {
    this.dialogRef.close(this.selectedPerson)
  }
}
