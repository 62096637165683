import { Component, OnDestroy, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialog } from "@angular/material/dialog"
import { MatLegacySlideToggleChange as MatSlideToggleChange } from "@angular/material/legacy-slide-toggle"
import { Router } from "@angular/router"
import { Company, CompanyFeatures, Invitation, LABELS, LegacyTemplate, ModelInCollection, Person, Role, RoleType } from "@models/common"
import { SmsInviteWithRole } from "@models/common/invitation.interface"
import { UserFeatures } from "@models/common/person.interface"
import {
  CloudFunctionsService,
  CompanyService,
  FilestackService,
  InvitationService,
  RoleHandlerService,
  SnackbarService,
  UserService,
} from "@services"
import { MessageService } from "primeng/api"
import { combineLatest, Observable, Subscription } from "rxjs"
import { filter, map, switchMap, take } from "rxjs/operators"
import { DialogService } from "../../dialogs/dialog.service"
import { CompanyInvitationDialogComponent } from "../company-invitation-dialog/company-invitation-dialog.component"
import { ToastModule } from "primeng/toast"
import { CheckboxModule } from "primeng/checkbox"
import { CheckdTagsComponent } from "../../checkd-ui/checkd-tags/checkd-tags.component"
import { PendingInvitationsListComponent } from "../../invitations/pending-invitations-list/pending-invitations-list.component"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { PeopleRoleSelectorComponent } from "../../people/people-role-selector/people-role-selector.component"
import { PeopleListItemComponent } from "../../people/people-list-item/people-list-item.component"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { SettingsGroupComponent } from "../../settings-group/settings-group.component"
import { MatExpansionModule } from "@angular/material/expansion"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"
import { ExtendedModule } from "@angular/flex-layout/extended"

@Component({
  selector: "app-company-view",
  templateUrl: "./company-view.component.html",
  styleUrls: ["./company-view.component.scss"],
  standalone: true,
  imports: [
    ExtendedModule,
    FlexModule,
    NgIf,
    MatLegacyButtonModule,
    MatLegacyTooltipModule,
    MatIconModule,
    PrimaryButtonDirective,
    MatExpansionModule,
    SettingsGroupComponent,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    NgFor,
    PeopleListItemComponent,
    PeopleRoleSelectorComponent,
    MatLegacyMenuModule,
    PendingInvitationsListComponent,
    CheckdTagsComponent,
    CheckboxModule,
    ToastModule,
    AsyncPipe,
  ],
})
export class CompanyViewComponent implements OnInit, OnDestroy {
  get canInvitePeople() {
    return (
      this.currentUserCompanyRole != null &&
      this.currentUserCompanyRole.canCreateTargetDocuments(Invitation.COLLECTION) &&
      this.currentUserCompanyRole.canCreateTargetRelations(Invitation.COLLECTION)
    )
  }

  get canChangePeopleRole() {
    return this.currentUserCompanyRole != null && this.currentUserCompanyRole.canUpdateTargetRelations(Person.COLLECTION)
  }

  get canEditCompany() {
    return this.currentUserCompanyRole != null && this.currentUserCompanyRole.canUpdateTarget()
  }

  get companyHasPublicTemplateLibraryFeature(): boolean {
    return (this.company?.features || []).includes(CompanyFeatures.FORMS_LIBRARY)
  }

  get companyHasSsoAdLoginFeature(): boolean {
    return (this.company?.features || []).includes(CompanyFeatures.SSO_AD_LOGIN)
  }

  get logoSource() {
    return (this.company && this.company.logo) || ""
  }

  readonly roleType = RoleType

  currentUser: Person
  company: Company
  currentUserCompanyRole: Role
  subscriptions: Subscription[]

  company$: Observable<Company>
  people$: Observable<Person[]>
  templates$: Observable<LegacyTemplate[]>

  readonly pendingCompanyInvites$: Observable<Invitation[]> = this.userService.currentCompany$.pipe(
    switchMap((company) => this.invitationService.listenToPendingInvitationsFor(company))
  )

  tags: string[]

  userRemovalInProgress = false

  roles: any[] = [
    { label: LABELS.DEFAULT, description: "Normal user" },
    { label: LABELS.ADMINISTRATOR, description: "Admin user" },
  ]

  companyInfoForm: UntypedFormGroup
  // Contains an array allowed login methods
  // This is stored in company data
  loginMethods: string[]

  constructor(
    private companyService: CompanyService,
    private snackbarService: SnackbarService,
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private invitationService: InvitationService,
    private roleHandlerService: RoleHandlerService,
    private filestackService: FilestackService,
    private router: Router,
    public dialogService: DialogService,
    private cloudFunctionsService: CloudFunctionsService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.setupCompanyInfo()
    this.setupListeners()
    this.setupSubscriptions()
  }

  setupCompanyInfo() {
    this.companyInfoForm = this.formBuilder.group({
      name: "",
      pdfFooter: "",
    })
    this.companyInfoForm.disable()
  }

  onEditable(isEditable: boolean) {
    isEditable ? this.companyInfoForm.enable() : this.companyInfoForm.disable()
  }

  saveCompanyInfo() {
    this.company.ref!.update(this.companyInfoForm.value)
  }

  setupListeners() {
    this.company$ = this.userService.currentCompany$
    this.people$ = this.userService.currentCompanyMembers$
    this.templates$ = this.company$.pipe(
      switchMap((company) => this.companyService.listenToTemplates(company)),
      map((templates) => templates.filter((template) => !template.inactive))
    )
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.userService.currentUser$.subscribe((currentUser) => (this.currentUser = currentUser)),
      this.company$.subscribe((currentCompany) => {
        this.company = currentCompany
        this.tags = this.company.tags || []
        this.loginMethods = currentCompany.loginMethods || ["usernameAndPassword"]
        this.companyInfoForm.setValue({
          name: this.company.name,
          pdfFooter: this.company.pdfFooter,
        })
      }),
      this.userService.currentUserCompanyRole$.subscribe((userCompanyRole) => (this.currentUserCompanyRole = userCompanyRole)),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  updateTags(event: string[]) {
    this.companyService.updateCompanyTags(event, this.company.uid)
  }

  checkOrgNumber(orgNumber: number): boolean {
    if (orgNumber && orgNumber !== 0) {
      return true
    }

    return false
  }

  addEmployee() {
    combineLatest([this.userService.listenToCurrentUserAndMainCompany(), this.userService.userFeatures$])
      .pipe(take(1))
      .subscribe(([userAndCompany, userFeature]) => {
        const currentUser = userAndCompany.user
        const company = userAndCompany.company

        const dialogRef = this.dialog.open(CompanyInvitationDialogComponent, {
          data: { showSmsTab: userFeature.includes(UserFeatures.SEND_SMS_INVITES) },
        })

        dialogRef
          .afterClosed()
          .pipe(
            take(1),
            filter((r) => r)
          )
          .subscribe((result) => {
            if (result.emails) {
              this.invitationService.createCompanyEmailInvitations(result.emails, currentUser.uid, company).then((invitations) => {
                this.snackbarService.showMessage(`${invitations.length} invitations are sent!`)
              })
            }

            if (result.phone) {
              const smsInvite = result as SmsInviteWithRole
              this.messageService.add({ key: "bc", severity: "info", summary: "Processing", detail: "Sending sms invitation..." })
              this.invitationService.createCompanySmsInvitation(smsInvite, currentUser.uid, company).then((_) => {
                this.messageService.add({
                  key: "bc",
                  severity: "success",
                  summary: "Success",
                  detail: `Sms invitation sent to ${smsInvite.phone}`,
                })
              })
            }
          })
      })
  }

  async uploadLogo() {
    if (!this.company) {
      return
    }
    try {
      const logoUrl = await this.filestackService.uploadLogo()
      if (logoUrl != null) {
        await this.company.ref!.update({ logo: logoUrl })
        this.snackbarService.showMessage("Company logo is successfully uploaded!")
      }
    } catch (error) {
      this.snackbarService.showMessage("Error uploading logo! Please try again.")
    }
  }

  async removeCompanyMemberClicked(personToBeRemoved: Person) {
    const currentUserConfirmedRemovingUser = await this.dialogService.confirm(
      "Confirm removing member",
      `Are you sure you want to remove ${personToBeRemoved.name} from your company? ` +
        "This will not remove the user from projects they are in. That can be done in the project info page."
    )

    if (currentUserConfirmedRemovingUser) {
      const dialog = this.dialogService.showSpinner(`Removing user '${personToBeRemoved.name}'...`, {
        disableClose: true,
        subTitle: "Please wait",
      })

      try {
        this.userRemovalInProgress = true
        await this.cloudFunctionsService.removeCompanyUser({ userUid: personToBeRemoved.uid, companyUid: this.company.uid }).toPromise()

        await dialog.componentInstance.finishWithSuccess({
          message: `User ${personToBeRemoved.name} is now removed from this company!`,
          disableClose: false,
        })
      } catch (e) {
        await dialog.componentInstance.finishWithError({
          message: `Unable to remove user: ${e.message}`,
          disableClose: false,
        })

        return
      } finally {
        this.userRemovalInProgress = false
      }
    }
  }

  async updateTemplatesLibraryOption(event: MatSlideToggleChange) {
    if (!this.company) {
      return
    }
    const isTemplatePublisher = this.company.data.isTemplatePublisher ?? false
    if (isTemplatePublisher === true) {
      return this.companyService.updateCompanyData({ isTemplatePublisher: event.checked }, this.company.uid)
    }

    const confirmDialogResult = await this.dialogService.confirm(
      "Public Templates Library",
      "This action will publish your template for all your subscribers to use."
    )

    if (confirmDialogResult) {
      return this.companyService.updateCompanyData({ isTemplatePublisher: event.checked }, this.company.uid)
    } else {
      event.source.checked = false
    }
  }

  // Updates a list in firebase of allowed login types
  // Login type names are set in the HTML for now
  updateLoginMethods(): void {
    if (this.loginMethods.length < 1) {
      console.error(`Can't have less than one available login option`)
      // TODO: Give user feedback on this

      return
    }

    this.companyService.updateCompanyData({ loginMethods: this.loginMethods }, this.company.uid)
  }

  // Make sure to disable checkboxes so user can't have 0 login methods
  checkLoginMethodPresent(value: string): boolean {
    if (this.loginMethods?.length <= 1 && this.loginMethods.includes(value)) {
      return true
    }

    return false
  }

  trackByUid(index: number, model: ModelInCollection): string {
    return model.uid!
  }
}
