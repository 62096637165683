import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"

import { CompanyFeatures, Project, ReportGenerationData, ReportGenerationSettings } from "@models/common"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox"
import { FormsModule } from "@angular/forms"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

@Component({
  selector: "app-report-generation-dialog",
  templateUrl: "./report-generation-dialog.component.html",
  styleUrls: ["./report-generation-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, MatLegacyFormFieldModule, MatLegacyInputModule, FormsModule, MatLegacyCheckboxModule, MatLegacyButtonModule],
})
export class ReportGenerationDialogComponent {
  totalItemCount: number
  filteredItemCount: number

  reportGenerationSettings: ReportGenerationSettings = {
    drawing: true,
    drawingName: true,
    projectName: true,
    itemTitle: true,
    itemDescription: true,
    itemTags: true,
    taskStatus: true,
    creator: true,
    assignee: true,
    collaborators: true,
    createdDate: true,
    modifiedDate: true,
    company: true,
    dueDate: true,
    qrCode: true,
    pageNumber: true,
  }

  reportGenerationData: ReportGenerationData = {
    name: "",
    description: "",
    reportType: "PROJECT",
    projectUid: "",
    creatorUid: "",
    items: [],
    settings: this.reportGenerationSettings,
  }

  project: Project

  constructor(private dialogRef: MatDialogRef<ReportGenerationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.project = data.project
    this.totalItemCount = data.totalItemCount
    this.filteredItemCount = data.filteredItemCount
    this.reportGenerationData.projectUid = data.project.uid
    this.reportGenerationData.creatorUid = data.creator.uid
    this.data.reportType != null ? (this.reportGenerationData.reportType = this.data.reportType) : ""
    this.data.drawingUid != null ? (this.reportGenerationData.drawingUid = this.data.drawingUid) : ""

    if (!this.hasDrawingFeature) {
      this.reportGenerationSettings.drawing = false
      this.reportGenerationSettings.drawingName = false
    }
  }

  get hasDrawingFeature() {
    return (
      this.project &&
      this.project.aggregateData &&
      this.project.aggregateData.companyFeatures &&
      this.project.aggregateData.companyFeatures.includes(CompanyFeatures.DRAWINGS)
    )
  }

  get hasReportName() {
    return this.reportGenerationData.name != null && this.reportGenerationData.name.trim() !== ""
  }

  onOk() {
    if (this.hasReportName) {
      this.reportGenerationData.name = this.reportGenerationData.name.trim()
      this.reportGenerationData.description = this.reportGenerationData.description.trim()
      this.dialogRef.close(this.reportGenerationData)
    }
  }

  onCancel() {
    this.dialogRef.close(null)
  }
}
