import { OWL_DATE_TIME_FORMATS } from "@danielmoncada/angular-datetime-picker"

export const DATETIME_PICKER_PROVIDER_CONFIG = {
  provide: OWL_DATE_TIME_FORMATS,
  useValue: {
    parseInput: "l LT",
    fullPickerInput: "DD/MM/YYYY HH:mm",
    datePickerInput: "DD/MM/YYYY",
    timePickerInput: "HH:mm",
    monthYearLabel: "MMM YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY",
  },
}
