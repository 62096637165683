import { Component, OnInit, Input, AfterViewChecked } from "@angular/core"
import { Observable, BehaviorSubject } from "rxjs"

import { DrawingInterface, Item, ItemData, ItemReportData } from "@models/common"
import { CheckdDrawingComponent } from "./checkd-drawing/checkd-drawing.component"

interface ExternalAction {
  type: string
  payload: any
}

@Component({
  selector: "checkd-report-drawing",
  templateUrl: "./report-drawing.component.html",
  styleUrls: ["./report-drawing.component.css"],
  standalone: true,
  imports: [CheckdDrawingComponent],
})
export class ReportDrawingComponent implements OnInit, AfterViewChecked {
  @Input() itemReportDataList: ItemReportData[]
  @Input() drawingReportData: DrawingInterface
  drawingWasSet: boolean = false
  itemListWasSet: boolean = false

  initialDrawing: DrawingInterface = {
    name: "lala",
    drawingType: "image",
    storage: {
      url: "",
      fileType: "png",
    },
    width: 1010,
    height: 713,
  }

  itemsList = new Array<Item>()
  drawing: BehaviorSubject<DrawingInterface> = new BehaviorSubject<DrawingInterface>(this.initialDrawing)
  items: BehaviorSubject<Item[]> = new BehaviorSubject<Item[]>(this.itemsList)

  ngOnInit() {}

  ngAfterViewChecked() {
    if (this.drawingReportData != null && !this.drawingWasSet) this.setDrawing(this.drawingReportData)

    if (this.itemReportDataList != null && !this.itemListWasSet)
      this.setItems(
        this.itemReportDataList.map((it) => {
          it["itemType"] = "ITEM_REPORT_DATA"
          return it as ItemData
        })
      )
  }

  ///////////////////////////////////////////////////////////////////////////////
  /// Component Events
  ///////////////////////////////////////////////////////////////////////////////

  itemWasClicked(item: any) {}

  itemWasMoved(item: any) {}

  itemWasAdded(item: any) {}

  ///////////////////////////////////////////////////////////////////////////////
  // Methods used by performAction()
  ///////////////////////////////////////////////////////////////////////////////

  setItems(items?: ItemData[]) {
    if (items != null) {
      const itemObjs = items.map((item) => new Item(item, "", undefined))
      this.itemsList = itemObjs
      this.items.next([]) // To redraw all markers
      this.items.next(this.itemsList)
      this.itemListWasSet = true
    }
  }

  setDrawing(drawing: DrawingInterface) {
    this.drawing.next(drawing)
    this.drawingWasSet = true
  }
}
