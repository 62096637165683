import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Inject, ChangeDetectorRef } from "@angular/core"
import { BehaviorSubject, Observable } from "rxjs"
import { environment } from "../../../../../../environments/environment"

import { DrawingInterface, ItemInterface, WorkflowStates, WorkflowStatusFilter, Item } from "@models/common"
import { CRS, Map, Marker, Point, icon, marker, Layer, LatLng } from "leaflet"
import * as L from "leaflet"

// import { LeafletService, XYCoord, XYBounds } from '@services/leaflet.service';
import { LeafletService, XYCoord, XYBounds } from "../services/leaflet.service"

// import {
// ItemService,
// DrawingService,
// FirebaseAuthService,
// UserService// ,
// SnackbarService
// } from '../../services';
import { ItemService } from "@services"

import { Subscription, combineLatest } from "rxjs"
import { LeafletCheckdItemDirective } from "../directives/leaflet-checkd-item.directive"
import { NgFor } from "@angular/common"
import { LeafletCheckdDrawingDirective } from "../directives/leaflet-checkd-drawing.directive"
import { LeafletModule } from "@asymmetrik/ngx-leaflet"

export interface ItemMarkerOptions {
  item?: Item
  icon?: L.Icon
  draggable: boolean
}

@Component({
  selector: "checkd-drawing",
  templateUrl: "./checkd-drawing.component.html",
  styleUrls: ["./checkd-drawing.component.scss"],
  standalone: true,
  imports: [LeafletModule, LeafletCheckdDrawingDirective, NgFor, LeafletCheckdItemDirective],
})
export class CheckdDrawingComponent implements OnInit, OnDestroy {
  @Input() drawing: Observable<DrawingInterface>
  @Input("items") items$: Observable<Item[]>
  @Input() drawingId: string
  @Input() projectId: string

  @Input() forceMinHeight = false

  @Output() itemWasClicked = new EventEmitter<Item>()
  // @Output() itemWasMoved = new EventEmitter<Item>()
  // @Output() itemWasAdded = new EventEmitter<Item>()

  width: number
  height: number

  itemLayers = new Array<Layer>()

  private items: Item[]
  private drawingBounds: XYBounds

  subscriptions: Subscription[] = []

  drawingMapOptions = {
    attributionControl: false,
    minZoom: -4,
    maxZoom: 4,
    center: [0, 0],
    zoom: 1,
    crs: L.CRS.Simple,
    preferCanvas: true,
    zoomControl: false,
  }

  filter: WorkflowStatusFilter = {
    CLOSED: true,
    DELEGATED: true,
    FIXED: true,
    INPROGRESS: true,
    OPEN: true,
  }

  icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: "assets/marker-icon.png",
    shadowUrl: "assets/marker-shadow.png",
  })

  map: Map

  constructor(private itemService: ItemService, private leafletService: LeafletService, private ref: ChangeDetectorRef) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  onMapReady(map: Map) {
    this.map = map

    this.subscriptions.push(
      this.drawing.subscribe((data) => {
        // Clear all layers from map
        map.eachLayer((layer) => map.removeLayer(layer))

        const southWest = map.unproject([0, data.height!], map.getMaxZoom() - 2)
        const northEast = map.unproject([data.width!, 0], map.getMaxZoom() - 2)
        const bounds = new L.LatLngBounds(southWest, northEast)
        L.imageOverlay(data.storage.url, bounds).addTo(map)
        map.setMaxBounds(bounds)

        // Reset zoom levels
        map.fitBounds(bounds)

        this.drawingBounds = { x0: 0, y0: 0, x1: data.width, y1: data.height } as XYBounds
        this.subscriptions.push(
          this.items$.subscribe((items) => {
            this.items = items
            this.filterItems()
          })
        )
      })
    )
  }

  addItemGesture(event: any) {}

  itemClicked(event: any, item: Item) {
    this.itemWasClicked.emit(item)
  }

  itemMoved(event: any, item: Item) {}

  onStatusFilterChange(filter: WorkflowStatusFilter) {
    this.filter = filter
    this.filterItems()
  }

  private isItemVisible(item: any) {
    if (this.filter.hasOwnProperty(item.status)) {
      // @ts-ignore
      return this.filter[item.status]
    }
    return true
  }

  protected isItemWithCoords(item: any) {
    return item.data.positionX != null && item.data.positionY != null
  }

  private filterItems() {
    let filtered = this.items.filter((item) => this.isItemVisible(item)).filter((item) => this.isItemWithCoords(item))
    this.itemLayers = filtered.map((item) => {
      let coords = { x: item.data.positionX, y: item.data.positionY } as XYCoord
      let pixels = this.leafletService.denormalizeXYCoordinates(coords, this.drawingBounds)
      let location = this.map.unproject([pixels.x, pixels.y], this.map.getMaxZoom() - 2)
      return this.createItemMarker(location, item)
    })
    this.ref.detectChanges()
  }

  private createItemMarker(location: LatLng, item: Item): Marker {
    let options = {
      item: item,
      icon: this.createItemMarkerIcon(item.data.number!, item.data.status!),
      draggable: false,
    } as ItemMarkerOptions

    return new L.Marker(location, options)
  }

  private createItemMarkerIcon(itemNumber: number, itemStatus: string): L.DivIcon {
    let convertedStatus = this.itemService.workflowStateToStatus(itemStatus)
    let icon = this.leafletService.statusToIconHtml(convertedStatus, itemNumber)
    return icon
  }
}
