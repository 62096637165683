<mat-toolbar *ngIf="navigationService.showTopBar$ | async" [ngClass]="{ dark: darkTopBar, shadow: useShadow }">
  <button
    type="button"
    aria-label="Toggle sidenav"
    class="hamburger-menu"
    mat-icon-button
    (click)="sideNavService.toggle()"
    *ngIf="sideNavService.isHandset$ | async"
  >
    <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
  </button>
  <div class="top-bar__left-side">
    <!-- Show the icon when the apps are ready -->
    <div class="top-bar__dot-menu-container" *ngIf="false">
      <img src="./assets/icons/top-bar/dot-menu.svg" alt="dot-menu" class="top-bar__dot-menu" />
    </div>
    <a [routerLink]="'/'" class="top-bar__logo-link">
      <img class="top-bar__company-logo" src="/assets/logos/logoNextField.svg" alt="Next logo" />
    </a>
  </div>

  <!-- Pushes everything after this to the right -->
  <div class="top-bar__right-side">
    <checkd-notifications *ngIf="userName$ | async" id="notifications_btn"></checkd-notifications>

    <button mat-icon-button [mat-menu-trigger-for]="helpMenu" id="help_menu">
      <mat-icon>help_outline</mat-icon>
    </button>

    <button class="avatar-button" id="avatar_btn" *ngIf="userName$ | async" mat-icon-button [matMenuTriggerFor]="rootMenu">
      <div class="avatar-wrapper">
        <ngx-avatars [src]="userImage$ | async" [name]="userName$ | async" size="30"></ngx-avatars>
      </div>
    </button>
  </div>

  <mat-menu #helpMenu="matMenu">
    <a mat-menu-item href="https://intercom.help/checkd" id="intercom_guides" target="_blank">Guides</a>
    <a mat-menu-item id="intercom">Help & Support</a>
  </mat-menu>

  <mat-menu #rootMenu="matMenu">
    <button (click)="this.navigationService.goToProfilePage()" id="profile" mat-menu-item [matMenuTriggerFor]="infoMenu">
      <mat-icon svgIcon="peoplehat"></mat-icon>
      <span>Profile</span>
    </button>
    <button id="intercom" mat-menu-item>
      <mat-icon>help_outline</mat-icon>
      <span>Help & Support</span>
    </button>
    <button (click)="logout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>

  <mat-menu #infoMenu="matMenu">
    <button mat-menu-item disabled>
      <mat-icon>person</mat-icon>
      {{ userName$ | async }}
    </button>
    <button mat-menu-item disabled>
      <mat-icon>email</mat-icon>
      {{ userEmail$ | async }}
    </button>
    <button mat-menu-item disabled>
      <mat-icon>house</mat-icon>
      {{ currentCompanyName$ | async }}
    </button>
  </mat-menu>
</mat-toolbar>
