<div class="page-container">
  <!--  <img src="assets/logos/next-logo-icon.svg" alt="CHECKD logo" width="80"/>-->
  <img class="checkd-logo-gray" src="assets/logos/next-logo-full-white.svg" alt="CHECKD logo" width="200" />

  <div class="login-container flex flex-column align-content-center">
    <p class="title">SIGN IN TO YOUR ACCOUNT</p>

    <div class="input-area">
      <form [formGroup]="loginForm" (ngSubmit)="loginWithEmailAndPassword()">
        <div class="input-field-container">
          <label>
            <input placeholder="Your login email address" type="email" formControlName="email" autocomplete="username" />
            <mat-icon class="input-field-icon" svgIcon="email"></mat-icon>
          </label>
        </div>

        <div class="input-field-container">
          <label>
            <input placeholder="Your password" type="password" formControlName="password" autocomplete="current-password" />
            <mat-icon class="input-field-icon" svgIcon="password"></mat-icon>
          </label>
        </div>
        <p class="forgot-your-password">
          <a [routerLink]="'/passwordrecovery'"> Forgot your password? </a>
        </p>

        <button primary-button [isRounded]="true" class="login-button" [disabled]="loginForm.invalid || isLoggingIn" type="submit">
          {{ isLoggingIn ? "SIGNING IN..." : "SIGN IN" }}
        </button>
      </form>
    </div>

    <hr style="width: 100%; margin-bottom: 1.5em; color: red; border-top: 1px solid rgba(0, 0, 0, 0.1)" />

    <button class="microsoft-login-button" [disabled]="isLoggingIn" (click)="loginWithMicrosoft()">
      <img src="assets/ms-symbollockup_signin_light.svg" />
    </button>
  </div>

  <div class="store-links-container">
    <a
      href="https://apps.apple.com/us/app/checkd-field/id1328077051"
      target="_blank"
      title="Click here to download CHECKD Field on the App Store"
      class="app-store-link"
    >
      <img class="store-link-image" src="assets/logos/app_store.svg" alt="App store logo" />
    </a>
    <a
      href="https://play.google.com/store/apps/details?id=checkd.com.fieldapp&hl=en"
      target="_blank"
      title="Click here to download CHECKD Field on the Play Store"
      class="play-store-link"
    >
      <img class="store-link-image" src="assets/logos/play_store.svg" alt="Play store logo" />
    </a>
  </div>
</div>
