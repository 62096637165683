import { COMMA, ENTER } from "@angular/cdk/keycodes"
import { Component, ElementRef, Inject, ViewChild } from "@angular/core"
import { UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteModule,
} from "@angular/material/legacy-autocomplete"
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule } from "@angular/material/legacy-chips"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { EmailWithRoles, Person, PersonWithRoles, Role, RoleType } from "@models/common"
import { Observable } from "rxjs"
import { map, startWith, tap } from "rxjs/operators"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacyRadioModule } from "@angular/material/legacy-radio"
import { AvatarModule } from "ngx-avatars"
import { FlexModule } from "@angular/flex-layout/flex"
import { MatLegacyOptionModule } from "@angular/material/legacy-core"
import { MatIconModule } from "@angular/material/icon"
import { NgFor, NgIf, AsyncPipe } from "@angular/common"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

@Component({
  selector: "app-project-invitation-dialog",
  templateUrl: "./project-invitation-dialog.component.html",
  styleUrls: ["./project-invitation-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    MatLegacyFormFieldModule,
    MatLegacyChipsModule,
    NgFor,
    NgIf,
    MatIconModule,
    MatLegacyAutocompleteModule,
    ReactiveFormsModule,
    MatLegacyOptionModule,
    FlexModule,
    AvatarModule,
    MatLegacyRadioModule,
    MatLegacyTooltipModule,
    PrimaryButtonDirective,
    AsyncPipe,
  ],
})
export class ProjectInvitationDialogComponent {
  get showCompanyAssociates() {
    return this.companyAssociates != null && this.companyAssociates.length > 0
  }

  get numberOfInvites() {
    return this.selectedCompanyPeople.length + this.selectedCompanyAssociates.length + this.emailInvitations.length
  }

  get canSendInvites() {
    return this.numberOfInvites > 0
  }

  get inviteButtonMessage() {
    const addPeopleMessage =
      this.selectedCompanyPeople.length > 0 || this.selectedCompanyAssociates.length > 0
        ? `Add ${this.selectedCompanyPeople.length + this.selectedCompanyAssociates.length} member(s)`
        : ""

    let invitePeopleMessage = this.emailInvitations.length > 0 ? `send ${this.emailInvitations.length} invitation(s)` : ""

    invitePeopleMessage =
      addPeopleMessage && invitePeopleMessage
        ? " and " + invitePeopleMessage
        : invitePeopleMessage.charAt(0).toLocaleUpperCase() + invitePeopleMessage.slice(1)

    return addPeopleMessage + invitePeopleMessage
  }
  @ViewChild("auto", { static: true }) matAutocomplete: MatAutocomplete
  @ViewChild("mailInput", { static: true }) mailInput: ElementRef<HTMLInputElement>

  inviterRole: Role
  companyMembers: Person[]
  companyAssociates: Person[]
  selectedCompanyPeople: PersonWithRoles[] = []
  selectedCompanyAssociates: PersonWithRoles[] = []
  emailInvitations: EmailWithRoles[] = []
  projectName: string
  companyName: string

  myControl = new UntypedFormControl("", [Validators.required, Validators.email])

  selectedRole: string = "DEFAULT"
  readonly separatorKeysCodes = [ENTER, COMMA] as const

  filteredCompanyMembers$: Observable<Person[]>
  filteredCompanyAssociates$: Observable<Person[]>

  constructor(private dialogRef: MatDialogRef<ProjectInvitationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.companyMembers = data.companyMembers
    this.companyAssociates = data.companyAssociates
    this.projectName = data.projectName
    this.companyName = data.companyName
    this.inviterRole = data.inviterRole

    this.filteredCompanyMembers$ = this.myControl.valueChanges.pipe(
      startWith(""),
      map((mail: string) => this._filter(mail, this.companyMembers))
    )

    this.filteredCompanyAssociates$ = this.myControl.valueChanges.pipe(
      startWith(""),
      map((mail: string) => this._filter(mail, this.companyAssociates))
    )
  }

  get hasAdministratorPrivileges() {
    return (
      this.inviterRole != null &&
      (this.inviterRole.roleType === RoleType.PROJECT_ADMINISTRATOR || this.inviterRole.roleType === RoleType.PROJECT_OWNER)
    )
  }

  pasteEvent(event: ClipboardEvent) {
    const pasteText = event.clipboardData!.getData("text/plain")
    const mails = this.extractEmails(pasteText)

    if (mails && mails.length > 1) {
      mails.forEach((mail) => {
        const companyMember = this._filter(mail, this.companyMembers)[0]
        const companyAssociate = this._filter(mail, this.companyAssociates)[0]
        if (companyMember) {
          this.addCompanyMember(companyMember, this.selectedRole)
        } else if (companyAssociate) {
          this.addCompanyAssociate(companyAssociate, this.selectedRole)
        } else {
          this.emailInvitations.push({ email: mail, roles: [this.selectedRole] })
        }
      })

      // Timeout required to clear input otherwise it doesnt work
      setTimeout(() => {
        this.mailInput.nativeElement.value = ""
        this.myControl.setValue(null)
      }, 100)
    }
  }

  extractEmails(text: string) {
    return text.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)
  }

  add(event: MatChipInputEvent): void {
    if (this.myControl.valid) {
      const input = event.input
      const value = event.value

      const cleanedEmail = value?.toLowerCase().trim() ?? ""

      if (this.emailInvitations.some((emailWithRole) => emailWithRole.email === cleanedEmail)) {
        return
      }

      if (cleanedEmail) {
        this.emailInvitations.push({ email: cleanedEmail, roles: [this.selectedRole] })
      }

      if (input) {
        input.value = ""
      }

      this.myControl.setValue(null)
    }
  }

  remove(element: any, from: any[]) {
    const index = from.indexOf(element)
    if (index > -1) {
      from.splice(index, 1)
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const person = event.option.value
    if (event.option.group.label === this.companyName) {
      this.addCompanyMember(person, this.selectedRole)
    } else {
      this.addCompanyAssociate(person, this.selectedRole)
    }
    this.mailInput.nativeElement.value = ""
    this.myControl.setValue("")
  }

  addCompanyMember(person: Person, role: string) {
    if (this.isCompanyMemberInvited(person)) {
      return
    }
    this.selectedCompanyPeople.push({ person, roles: [role] })
  }

  addCompanyAssociate(person: Person, role: string) {
    if (this.isCompanyAssociateInvited(person)) {
      return
    }
    this.selectedCompanyAssociates.push({ person, roles: [role] })
  }

  removeCompanyMember(person: Person) {
    this.selectedCompanyPeople = this.selectedCompanyPeople.filter((it) => it.person !== person)
  }

  removeCompanyAssociate(person: Person) {
    this.selectedCompanyAssociates = this.selectedCompanyAssociates.filter((it) => it.person !== person)
  }

  isCompanyMemberInvited(person: Person) {
    return this.selectedCompanyPeople.map((p) => p.person).includes(person)
  }

  isCompanyAssociateInvited(person: Person) {
    return this.selectedCompanyAssociates.map((p) => p.person).includes(person)
  }

  sendInvites() {
    this.dialogRef.close({
      members: this.selectedCompanyPeople,
      associates: this.selectedCompanyAssociates,
      emails: this.emailInvitations,
    })
  }

  updateSelectedRole() {
    this.selectedCompanyPeople.forEach((selected) => (selected.roles = [this.selectedRole]))
    this.selectedCompanyAssociates.forEach((selected) => (selected.roles = [this.selectedRole]))
    this.emailInvitations.forEach((invitation) => (invitation.roles = [this.selectedRole]))
  }

  private _filter(value: string | Person, from: Person[]): Person[] {
    if (typeof value === "string") {
      const filterValue = value.toLowerCase()

      return from.filter((person) => {
        const email = (person?.email ?? "").toLowerCase()
        const name = (person?.name ?? "").toLowerCase()

        return email.includes(filterValue) || name.includes(filterValue)
      })
    }

    return from
  }
}
