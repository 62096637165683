import { BaseModel } from "./base-model"
import { Person } from "./person"
import { LABELS, RelationData } from "./relation.interface"
import { CompanyData, LoginProviders } from "./company.interface"

export class Company extends BaseModel<CompanyData> {
  public static override COLLECTION: string = "companies"

  get features() {
    return this.data.features || ["FREE"]
  }
  set features(features: string[]) {
    this.data.features = features

    // FIXME This updates the database whenever the 'features'
    // property is modified. Since we're not doing this anywhere else
    // we should remove this and use the update() method directly, to
    // avoid confusion about potential side effects of setting an
    // object property.
    this.update({ features })
  }

  override get lockedByCheckd() {
    return this.data && this.data.lockedByCheckd ? this.data.lockedByCheckd : false
  }
  get name() {
    return this.data.name
  }

  get loginMethods() {
    return this.data && this.data.loginMethods ? this.data.loginMethods : [LoginProviders.USERNAME_PASSWORD]
  }
  get address() {
    return this.data.address
  }
  get orgNumber() {
    return this.data.orgNumber
  }
  get logo() {
    return this.data.logo
  }
  get associatedPeople() {
    return this.data.associatedPeople || []
  }
  get companyType() {
    return this.data.companyType
  }
  get pdfFooter() {
    if (this.data.pdfFooter) {
      return this.data.pdfFooter
    }
    return "Powered by NEXT"
  }

  /**
   * Whether to display Next Field <-> Next Project "integration" stuff for
   * customers that opted into the beta feature.
   *
   * We don't promote this feature any more, but we need to hide the UI stuff for
   * most customers + customers who decided to opt-out of the feature.
   *
   * The only companies in the database who should have this field are customers who at some point
   * opted in (there should be very few of these).
   * If they still want to be in the beta, it's true, false otherwise.
   * For all other companies, the field is undefined, and we return false by default.
   *
   * Usually this would be a company feature that would be propagated to
   * project.aggregateData.companyFeatures but cloud functions can't be deployed ATM.
   */
  get showNextProjectIntegration(): boolean {
    return this.data["showNextProjectIntegration"] ?? false
  }

  get isTemplatePublisher() {
    return this.data.isTemplatePublisher || false
  }

  override get tags(): string[] {
    if (!this.data.tags) {
      return []
    }
    return this.data.tags.map((tag) => {
      if (typeof tag == "string") {
        return tag
      } else if (tag instanceof Object) {
        return tag.value
      }
    })
  }

  get email() {
    return this.data.email ?? ""
  }

  getPeople() {
    return this.collection(Person.COLLECTION)
      .get()
      .then((querySnapshot) => {
        return Promise.all(
          querySnapshot.docs.map((documentSnapshot) => {
            let relationData = documentSnapshot.data() as RelationData
            return Person.get(relationData.targetRef!.id)
          })
        )
      })
  }

  getParentCompanies() {
    return this.getTargets(Company.COLLECTION, [LABELS.HAS_PARENT_COMPANY])
  }
  getChildCompanies() {
    return this.getTargets(Company.COLLECTION, [LABELS.IS_PARENT_COMPANY_FOR])
  }

  public static get(uid: string): Promise<Company> {
    return Company.getModel(Company, uid)
  }

  public static create(data: CompanyData): Promise<Company> {
    return Company.db
      .collection(Company.COLLECTION)
      .add(data)
      .then((docRef) => docRef.get())
      .then((docSnapshot) => new Company(docSnapshot.data() as CompanyData, docSnapshot.id, docSnapshot.ref))
  }

  //////////////////////////////////////////////////////////////////////
  // NB: The following properties are not stored in the database.
  //     They are used to mark templates for various purposes (e.g. UI) during runtime.
  // TODO Change all getters to normal attributes and make a child model with these attributes for public-template-library

  // Marks a company as being subscribed to (public-template-library)
  public isSubscribedTo = false

  // END NB
  //////////////////////////////////////////////////////////////////////
}
