import { Component, inject } from "@angular/core"
import { Router } from "@angular/router"
import { FirebaseAuthService, SnackbarService, UserService } from "@services"
import { PrimaryButtonDirective } from "../next-ui/button/primary-button.directive"
import { MatIconModule } from "@angular/material/icon"
import { FormBuilder, type FormGroup, FormsModule, ReactiveFormsModule, Validators } from "@angular/forms"
import { filter, take } from "rxjs/operators"
import { firstValueFrom } from "rxjs"
import { RouterModule } from "@angular/router"

@Component({
  selector: "app-login-view",
  templateUrl: "./login-view.component.html",
  styleUrls: ["./login-view.component.scss"],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, MatIconModule, PrimaryButtonDirective, RouterModule],
})
export class LoginViewComponent {
  private readonly authService = inject(FirebaseAuthService)
  private readonly userService = inject(UserService)
  private readonly router = inject(Router)
  private readonly snackBar = inject(SnackbarService)
  private readonly fb: FormBuilder = inject(FormBuilder)

  loginForm: FormGroup = this.fb.group({
    email: ["", [Validators.required, Validators.email]],
    password: ["", [Validators.required]],
  })

  isLoggingIn = false

  async loginWithEmailAndPassword() {
    if (this.loginForm.invalid || this.isLoggingIn) {
      return
    }

    this.isLoggingIn = true
    const { email, password } = this.loginForm.value

    const result = await this.authService.signInWithEmailAndPassword(email, password)
    if (!result.success) {
      this.showErrorMessage(result.error.message)
      this.isLoggingIn = false
      return
    }

    // Wait for auth state to be updated before navigation
    await firstValueFrom(
      this.authService.authState$.pipe(
        filter((user) => !!user),
        take(1),
      ),
    )

    this.router.navigate(["projects"])
  }

  public async loginWithMicrosoft() {
    this.isLoggingIn = true
    const result = await this.authService.handleMicrosoftAuth()
    if (!result.success) {
      this.showErrorMessage(result.error.message)
      this.isLoggingIn = false
      return
    }

    // Wait for auth state to be updated before navigation
    await firstValueFrom(
      this.authService.authState$.pipe(
        filter((user) => !!user),
        take(1),
      ),
    )

    await this.router.navigate(["projects"])

    // This shouldn't be needed
    // try {
    //   const userDocSnap = await this.userService.fetchUserDocSnap(authResult.data.user!.uid)
    //   if (userDocSnap.exists) {
    //   } else {
    //     this.showErrorMessage("Access Denied: This is a beta feature enabled for selected customers only.")
    //     await this.userService.logout()
    //   }
    // } catch (e) {
    //   this.showErrorMessage("Access Denied: This is a beta feature enabled for selected customers only.")
    // }
  }

  showErrorMessage(errorMessage?: string) {
    this.snackBar.showMessage(errorMessage == null ? SnackbarService.LOGIN_ERROR : errorMessage)
  }
}
