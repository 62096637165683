import { Component, Inject } from "@angular/core"
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf, NgFor } from "@angular/common"

@Component({
  selector: "checkd-duplicate-report-dialog",
  templateUrl: "./duplicate-report-dialog.component.html",
  styleUrls: ["./duplicate-report-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    NgFor,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyButtonModule,
  ],
})
export class DuplicateReportDialogComponent {
  duplicateForm: UntypedFormGroup

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string; uid: string },
    public dialogRef: MatDialogRef<DuplicateReportDialogComponent>,
    private fb: UntypedFormBuilder
  ) {
    this.duplicateForm = this.fb.group({
      name: data.title,
      uid: data.uid,
      duplicates: this.fb.array([]),
    })
    // Make sure to start with one duplicate
    this.addDuplicate(data.title)
  }

  /**
   * Getter for duplicates form
   */
  get duplicates(): UntypedFormArray {
    return this.duplicateForm.get("duplicates") as UntypedFormArray
  }

  /**
   * returns a formgroup with duplicateName
   * @param title
   */
  newDuplicate(title: string): UntypedFormGroup {
    return this.fb.group({
      duplicateName: `${title} (copy)`,
    })
  }

  /**
   * calls newDuplicate and adds it to list of duplicates
   * @param title
   */
  addDuplicate(title: string) {
    if (this.duplicates.length < 10) {
      this.duplicates.push(this.newDuplicate(title))
    }
  }

  /**
   * removes duplicate on position i
   * @param i
   */
  removeDuplicate(i: number) {
    this.duplicates.removeAt(i)
  }

  /**
   * Called whenever user clicks "duplicate" in the dialog
   * Returns list of duplicates and some info of the original report.
   */
  onDuplicateClicked() {
    // Convert to an array of strings
    const returnValues: string[] = []
    for (const duplicateFormGroup of this.duplicates.controls) {
      returnValues.push(duplicateFormGroup?.get("duplicateName")!.value)
    }

    this.dialogRef.close(returnValues)
  }
}
