import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core"

import { MatIconModule } from "@angular/material/icon"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "app-settings-group",
  templateUrl: "./settings-group.component.html",
  styleUrls: ["./settings-group.component.scss"],
  standalone: true,
  imports: [FlexModule, NgIf, MatLegacyButtonModule, MatLegacyTooltipModule, MatIconModule],
})
export class SettingsGroupComponent implements OnInit {
  @Input() title: string
  @Input() readonly: boolean = false
  @Input() saveButtonDisabled: boolean

  @Output() onEditable = new EventEmitter<boolean>()
  @Output() onSave = new EventEmitter()
  editMode: boolean = false

  constructor() {}

  ngOnInit() {}

  setEditMode(editMode: boolean) {
    this.editMode = editMode
    this.onEditable.emit(editMode)
  }

  save() {
    this.setEditMode(false)
    this.onSave.emit()
  }
}
