import { Component, OnInit } from "@angular/core"
import { ActivatedRoute, Router } from "@angular/router"
import { FirebaseAuthService } from "@services"
import { map, take } from "rxjs/operators"

@Component({
  selector: "checkd-auth-action",
  templateUrl: "./auth-action.component.html",
  styleUrls: ["./auth-action.component.scss"],
  standalone: true,
})
export class AuthActionComponent implements OnInit {
  constructor(private router: Router, private route: ActivatedRoute, private authService: FirebaseAuthService) {}

  ngOnInit(): void {
    this.route.queryParams
      .pipe(
        map((params) => params["oobCode"]),
        take(1)
      )
      .subscribe((oobCode) => {
        this.authService.checkActionCode(oobCode).then((authCodeInfo) => {
          switch (authCodeInfo.operation) {
            case "VERIFY_EMAIL":
              this.router.navigateByUrl(this.router.url.replace("authaction", "emailverification"))
              break
            case "PASSWORD_RESET":
              this.router.navigateByUrl(this.router.url.replace("authaction", "passwordreset"))
              break
            default:
              console.log("UNSUPPORTED OPERATION")
          }
        })
      })
  }
}
