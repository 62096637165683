import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Observable } from "rxjs"

import { Drawing, DummyRole, IMenuOption, Role, Person } from "@models/common"
import { NameFilterPipe } from "../../../filters/project-filter.pipe"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { EmptyStateComponent } from "../../../checkd-ui/components/empty-state/empty-state.component"
import { DrawingListItemComponent } from "../drawing-list-item/drawing-list-item.component"
import { NgIf, NgFor, SlicePipe } from "@angular/common"

@Component({
  selector: "app-drawing-list",
  templateUrl: "./drawing-list.component.html",
  styleUrls: ["./drawing-list.component.scss"],
  standalone: true,
  imports: [NgIf, NgFor, DrawingListItemComponent, EmptyStateComponent, MatLegacyButtonModule, SlicePipe, NameFilterPipe],
})
export class DrawingListComponent {
  @Input() drawings: Drawing[]
  @Input() showDrawingOptions: boolean = true
  @Input() canDeleteDrawings: boolean = false
  @Input() userProjectRole: Role = new DummyRole()
  @Input() currentUser: Person

  //Empty state message

  noProjectsTitle = "No drawings matching your search."
  noProjectsDescription = "You might not have access to the drawing. Admins can add drawings in the bottom right. "
  noProjectsImgSrc = "./assets/images/ilustrations/not-found-drawing.svg"

  @Output() drawingClick = new EventEmitter()
  @Output() menuOptionSelected = new EventEmitter<{ drawing: Drawing; option: IMenuOption }>()

  sliceSize: number = 10

  get orderedDrawings() {
    if (this.drawings == null) {
      return []
    }

    return this.drawings.sort((a, b) =>
      (a.name || "").localeCompare(b.name || "", ["no", "nb", "nn", "kn"], { sensitivity: "base", numeric: true })
    )
  }

  setMaxSliceSize() {
    this.sliceSize = this.drawings.length
  }

  onMenuOptionSelected(option: IMenuOption, drawing: Drawing) {
    this.menuOptionSelected.emit({ drawing, option })
  }
}
