import { AngularSignaturePadModule } from "@almothafar/angular-signature-pad"
import { DragDropModule } from "@angular/cdk/drag-drop"
import { ScrollingModule } from "@angular/cdk/scrolling"
import { provideHttpClient, withInterceptorsFromDi } from "@angular/common/http"
import { APP_INITIALIZER, enableProdMode, ErrorHandler, importProvidersFrom, Injectable } from "@angular/core"
import { AngularFireModule } from "@angular/fire/compat"
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from "@angular/fire/compat/analytics"
import { AngularFirestoreModule, SETTINGS } from "@angular/fire/compat/firestore"
import { AngularFireFunctionsModule, REGION } from "@angular/fire/compat/functions"
import { AngularFirePerformanceModule } from "@angular/fire/compat/performance"
import { AngularFireStorageModule } from "@angular/fire/compat/storage"
import { FlexLayoutModule } from "@angular/flex-layout"
import { FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatBadgeModule } from "@angular/material/badge"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_NATIVE_DATE_FORMATS, NativeDateAdapter } from "@angular/material/core"
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator"
import { MatLegacySnackBarModule } from "@angular/material/legacy-snack-bar"
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table"
import { MatSortModule } from "@angular/material/sort"
import { bootstrapApplication, BrowserModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, Title } from "@angular/platform-browser"
import { provideAnimations } from "@angular/platform-browser/animations"
import { provideRouter, Router } from "@angular/router"
import { LeafletModule } from "@asymmetrik/ngx-leaflet"
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome"
import { GalleryModule } from "@ks89/angular-modal-gallery"
import { TranslateModule } from "@ngx-translate/core"
import * as Sentry from "@sentry/angular"
import { IntercomModule } from "@supy-io/ngx-intercom"
import { AvatarModule } from "ngx-avatars"
import { ClipboardModule } from "ngx-clipboard"
import { MomentModule } from "ngx-moment"
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader"
import { MessageService } from "primeng/api"
import { CheckboxModule } from "primeng/checkbox"
import { DataViewModule } from "primeng/dataview"
import { DropdownModule } from "primeng/dropdown"
import { GalleriaModule } from "primeng/galleria"
import { ImageModule } from "primeng/image"
import { InputNumberModule } from "primeng/inputnumber"
import { InputSwitchModule } from "primeng/inputswitch"
import { OrderListModule } from "primeng/orderlist"
import { OverlayPanelModule } from "primeng/overlaypanel"
import { RadioButtonModule } from "primeng/radiobutton"
import { ToastModule } from "primeng/toast"
import { TooltipModule } from "primeng/tooltip"
import { routes } from "./app/app.routes"
import { AppComponent } from "./app/app.component"
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field"
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/legacy-form-field"
import { MatDialogModule } from "@angular/material/dialog"
import { OwlMomentDateTimeModule } from "@danielmoncada/angular-datetime-picker-moment-adapter"

import { environment } from "@environments/environment"
import { initializeApp, provideFirebaseApp } from "@angular/fire/app"
import { provideAuth, getAuth } from "@angular/fire/auth"
import { DATETIME_PICKER_PROVIDER_CONFIG } from "./app/shared/config/datetime-picker.provider.config"
import { FeatureCheckerService } from "./app/dashboard/features/feature-checker/services/feature-checker.service"
import { MISSING_FEATURE_DIALOG_DATA } from "./app/dashboard/features/feature-checker/feature-checker.interface"

Sentry.init({
  dsn: "https://3537d377ffa64e1abcf0c9803056fc0a@o239530.ingest.sentry.io/1410307",
  environment: environment.sentry.environment,
  release: environment.sentry.version,

  integrations: [Sentry.browserTracingIntegration()],
  tracePropagationTargets: ["https://web-stag.checkd.it", "https://web.checkd.it"],
  tracesSampleRate: 0.5,
})

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  // override this method to make the week start on Mondays instead of Sundays
  override getFirstDayOfWeek(): number {
    return 1
  }
}

@Injectable()
class SentryErrorHandler implements ErrorHandler {
  constructor() {
    return
  }

  handleError(error: any) {
    const err = error.originalError || error.error || error
    const msg: string = (err.message || "").toLowerCase()

    if (msg.includes("chunkload") || msg.includes("non-error")) {
      return
    }

    if (err) {
      Sentry.captureException(err)
    }

    throw error
  }
}

@Injectable()
class MyHammerConfig extends HammerGestureConfig {
  override overrides = <any>{
    press: { time: 500 },
  }
}

if (environment.production) {
  enableProdMode()
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes),
    importProvidersFrom(
      // new modular approach
      provideFirebaseApp(() => initializeApp(environment.firebase)),
      provideAuth(() => getAuth()),

      // old approach, should be removed when modular is ready
      AngularFireModule.initializeApp(environment.firebase),
      AngularFirestoreModule,
      AngularFireFunctionsModule,
      AngularFireStorageModule,
      AngularFirePerformanceModule,
      AngularFireAnalyticsModule,

      FormsModule,
      BrowserModule,
      DragDropModule,
      FlexLayoutModule,
      LeafletModule,
      AvatarModule,
      IntercomModule.forRoot({
        appId: environment.INTERCOM_APP_ID,
        updateOnRouterChange: true, // will automatically run `update` on router event changes. Default: `false`
      }),
      ReactiveFormsModule,
      AngularSignaturePadModule,
      ClipboardModule,
      MomentModule,
      GalleryModule,
      ScrollingModule,
      MatBadgeModule,
      MatTableModule,
      MatPaginatorModule,
      MatSortModule,
      MatDialogModule,
      TooltipModule,
      InputSwitchModule,
      DropdownModule,
      RadioButtonModule,
      OverlayPanelModule,
      OrderListModule,
      DataViewModule,
      CheckboxModule,
      ImageModule,
      GalleriaModule,
      ToastModule,
      InputNumberModule,
      TranslateModule.forRoot(),
      FontAwesomeModule,
      NgxSkeletonLoaderModule,
      MatLegacySnackBarModule,
      OwlMomentDateTimeModule,
    ),
    DATETIME_PICKER_PROVIDER_CONFIG,
    { provide: REGION, useValue: "europe-west1" },
    // Use this only in dev when debugging with cloud functions
    // { provide: ORIGIN, useValue: 'http://localhost:5000' },
    MessageService,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: "localhost:8081",
            ssl: false,
          }
        : undefined,
    },
    // {
    //   provide: GET_STREAM_ACCESS_TOKEN,
    //   useValue: environment.STREAM_ACCESS_KEY,
    // },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig,
    },
    Title,
    ScreenTrackingService,
    UserTrackingService,
    { provide: MAT_DATE_LOCALE, useValue: "no" },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: MAT_NATIVE_DATE_FORMATS },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
    { provide: MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: "outline" } },
    {
      provide: MISSING_FEATURE_DIALOG_DATA,
      useClass: FeatureCheckerService,
    },
  ],
}).catch((err) => console.error(err))
