import { Observable, of as observableOf } from "rxjs"
import { map } from "rxjs/operators"

import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Image, Item } from "@models/common"
import { ItemService } from "@services"
import { AsyncPipe } from "@angular/common"
import { ItemImagesComponent } from "../item-images/item-images.component"

@Component({
  selector: "item-image-gallery-dialog",
  templateUrl: "./item-image-gallery-dialog.component.html",
  styleUrls: ["./item-image-gallery-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, ItemImagesComponent, AsyncPipe],
})
export class ItemImageGalleryDialogComponent implements OnInit {
  item: Item
  image: Image
  images: Image[]

  itemImages$: Observable<Image[]>
  currentImage$: Observable<Image>

  constructor(private itemService: ItemService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.item = data.item
    this.image = data.image
    this.images = data.images
  }

  ngOnInit() {
    if (this.item) {
      this.itemImages$ = this.itemService.listenToImages(this.item)
      this.currentImage$ = this.itemService.listenToImages(this.item).pipe(map((images) => (this.image != null ? this.image : images[0])))
    }
    if (this.images && this.images.length > 0) {
      this.itemImages$ = observableOf(this.images)
      this.currentImage$ = observableOf(this.image)
    }
  }
}
