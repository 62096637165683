import { Component, Inject, Input, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { GeoServiceService } from "@checkd-form/services/geo-service.service"

import { EmailWithRoles } from "@models/common"
import { SmsInviteWithRole } from "@models/common/invitation.interface"
import { PrimaryButtonDirective } from "../../next-ui/button/primary-button.directive"
import { MatLegacyRadioModule } from "@angular/material/legacy-radio"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { PhoneMaskDirective } from "../../utilities/phone-mask.directive"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { NgFor, NgIf } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { CheckdAutocompleteEmailsComponent } from "../../checkd-ui/checkd-autocomplete-emails/checkd-autocomplete-emails.component"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyTabsModule } from "@angular/material/legacy-tabs"

enum SelectedTab {
  email,
  sms,
}

@Component({
  selector: "app-company-invitation-dialog",
  templateUrl: "./company-invitation-dialog.component.html",
  styleUrls: ["./company-invitation-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    MatLegacyTabsModule,
    MatIconModule,
    CheckdAutocompleteEmailsComponent,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    FlexModule,
    NgFor,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyInputModule,
    PhoneMaskDirective,
    NgIf,
    MatLegacyFormFieldModule,
    MatLegacyRadioModule,
    PrimaryButtonDirective,
  ],
})
export class CompanyInvitationDialogComponent implements OnInit {
  emailInvitations: EmailWithRoles[] = []
  enableSMSInvitations: boolean = true
  selectedTab: SelectedTab = SelectedTab.email

  selectedRole: string = "DEFAULT"

  // Country code input
  @Input() phoneType: string
  // Default flag to show
  selectedCountryCode: string = "no"
  // What countries to have available in the list
  countryCodes = ["no", "se", "gb", "pl"]
  phoneTypeFormGroup: UntypedFormGroup
  // Phone number input
  phoneInput: string = ""
  // Text area input
  textAreaInput: string = ""

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { showSmsTab: boolean },
    private dialogRef: MatDialogRef<CompanyInvitationDialogComponent>,
    // GeoService is for handling country codes
    public geoService: GeoServiceService,
    private fb: UntypedFormBuilder
  ) {}

  ngOnInit() {
    this.initForm()
    this.enableSMSInvitations = this.data.showSmsTab
  }

  private initForm() {
    // Allows all numbers and spaces within 4 and 20 characters
    // This will be trimmed to remove spaces later
    this.phoneTypeFormGroup = this.fb.group({
      phoneType: [this.phoneType],
      phone: ["", [Validators.pattern("[0-9 ]{4,20}"), Validators.required]],
    })
  }

  get canSendInvites() {
    return this.emailInvitations.length > 0 || !this.phoneTypeFormGroup.controls["phone"].invalid
  }

  get inviteButtonMessage() {
    if (this.emailInvitations.length < 1) {
      // If on email tab
      if (this.selectedTab === SelectedTab.email) {
        return "Add an email to send invite"
      }

      // If on SMS tab

      return "Send sms invite"
    }

    return `send ${this.emailInvitations.length} ${this.emailInvitations.length > 1 ? "invitations" : "invitation"}`
  }

  updateEmailInvites(mails: string[]) {
    this.emailInvitations = mails.map((email) => {
      return { email: email, roles: [this.selectedRole] } as EmailWithRoles
    })
  }

  updateSelectedRole() {
    this.emailInvitations.forEach((invitation) => (invitation.roles = [this.selectedRole]))
  }

  sendInvites() {
    if (this.selectedTab === SelectedTab.email) {
      this.dialogRef.close({
        emails: this.emailInvitations,
      })
    } else if (this.selectedTab === SelectedTab.sms) {
      // Get sms string from variable
      const numberToSendInviteTo = `+${this.geoService.findCountryCode(this.selectedCountryCode)}${this.phoneInput}`
      // Replace spaces with nothing
      const trimmedNumberToSendInviteTo = numberToSendInviteTo.replace(/\s/g, "")

      // Make it into an object
      const smsInvite = {
        phone: trimmedNumberToSendInviteTo,
        message: this.textAreaInput.toString(),
        role: this.selectedRole,
      } as SmsInviteWithRole

      this.dialogRef.close(smsInvite)
    } else {
      throw new Error("Error sending invitation from selected tab")
    }
  }

  changeTab(newIndex: number) {
    this.selectedTab = newIndex
    this.emailInvitations = []
  }
}
