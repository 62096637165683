import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { Drawing } from "@models/common/drawing"
import { IMenuOption } from "@models/common/actions/menu-actions.interface"
import { Role } from "@models/common/roles-and-permissions"
import { DrawingService } from "@services/drawing.service"
import { DialogService } from "../../../dialogs/dialog.service"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf, NgFor, NgStyle } from "@angular/common"

@Component({
  selector: "checkd-drawing-contextmenu",
  templateUrl: "./drawing-contextmenu.component.html",
  styleUrls: ["./drawing-contextmenu.component.scss"],
  standalone: true,
  imports: [NgIf, MatLegacyButtonModule, MatLegacyMenuModule, MatIconModule, NgFor, NgStyle, ExtendedModule],
})
export class DrawingContextmenuComponent implements OnInit {
  @Input() drawing: Drawing
  @Input() userProjectRole: Role
  @Output() menuOptionSelected = new EventEmitter<IMenuOption>()

  menuOptions: IMenuOption[] = []

  constructor(private drawingService: DrawingService, private dialogService: DialogService) {}

  ngOnInit() {
    this.menuOptions = this.drawingService.getMenuOptions(this.drawing, this.userProjectRole)
  }

  get hasOptions() {
    return this.menuOptions.length > 0
  }

  async menuClicked(option: IMenuOption) {
    if (option.confirmationDialog) {
      const result = await this.dialogService.confirm(option.confirmationDialog.title, option.confirmationDialog.content)
      if (result) {
        this.menuOptionSelected.emit(option)
      }

      return
    }
    this.menuOptionSelected.emit(option)
  }
}
