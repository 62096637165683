import { Component, EventEmitter, Inject, Output } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"

import { Item, ItemData, Person, Project, TaskData } from "@models/common"
import { ItemCreationViewComponent } from "../../items/item-creation-view/item-creation-view.component"

export interface IItemCreationData {
  itemData: ItemData
  taskData?: TaskData
  isInEditMode: boolean
  itemDetailsChanged: boolean
  dueDateChanged: boolean
  selectedNextCustomer: any
}

@Component({
  selector: "app-item-creation-dialog",
  templateUrl: "./item-creation-dialog.component.html",
  styleUrls: ["./item-creation-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, ItemCreationViewComponent],
})
export class ItemCreationDialogComponent {
  @Output() onSave = new EventEmitter<IItemCreationData>()

  project: Project
  positionX: number
  positionY: number
  item: Item
  currentUser: Person
  projectMembers: Person[]
  prefill: { [key: string]: any }

  constructor(private dialogRef: MatDialogRef<ItemCreationDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.project = data.project
    this.positionX = data.positionX
    this.positionY = data.positionY
    this.item = data.item
    this.currentUser = data.currentUser
    this.projectMembers = data.projectMembers
    this.prefill = data.prefill
  }

  save(itemAndTaskData: IItemCreationData) {
    if (this.positionX != null && this.positionY != null) {
      itemAndTaskData.itemData.positionX = this.positionX
      itemAndTaskData.itemData.positionY = this.positionY
    }
    this.onSave.emit(itemAndTaskData)
    this.dialogRef.close()
  }
}
