import { Component, Inject, OnInit } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf, NgFor, NgStyle } from "@angular/common"

@Component({
  selector: "app-option-list-dialog",
  templateUrl: "./option-list-dialog.component.html",
  styleUrls: ["./option-list-dialog.component.scss"],
  standalone: true,
  imports: [NgIf, MatDialogModule, NgFor, MatLegacyButtonModule, NgStyle, ExtendedModule],
})
export class OptionListDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { list: any[]; title?: string },
    private dialogRef: MatDialogRef<OptionListDialogComponent>
  ) {}

  ngOnInit() {}

  optionSelected(option: any) {
    this.dialogRef.close(option)
  }
}
