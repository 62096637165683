import { Injectable } from "@angular/core"
import { Observable, Observer, of as observableOf } from "rxjs"
import { environment } from "../../../environments/environment"

import { Image, Item, Relation } from "../models/common"
import { ImageData } from "../models/common/image.interface"
import { FilestackService } from "./filestack.service"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore
import WriteBatch = firestore.WriteBatch
import { AngularFirestore } from "@angular/fire/compat/firestore"

@Injectable({
  providedIn: "root",
})
export class ImageService {
  constructor(private filestackService: FilestackService, private db: AngularFirestore) {}

  /**
   *
   * @deprecated Use batchAddImageToItem instead.
   *
   */
  addImageToItem(image: Image, item: Item): Promise<Image> {
    return (
      image
        // @ts-ignore
        .add(item, [Relation.LABELS.CONTAINED_BY])
        .then((_) => item.add(image, [Relation.LABELS.CONTAINS]))
        .then((_) => image)
    )
  }

  batchAddImageToItem(batch: WriteBatch, image: Image, item: Item): Image {
    // @ts-ignore
    image.batchAdd(batch, item, [Relation.LABELS.CONTAINED_BY])
    item.batchAdd(batch, image, [Relation.LABELS.CONTAINS])

    return image
  }

  batchAddImages(images: ImageData[]): WriteBatch {
    const batch = this.db.firestore.batch()
    images.map((image) => Image.batchCreate(batch, Image, image))
    return batch
  }

  convertFileToDataURLviaFileReader(url: string) {
    return ImageService.convertFileToDataURLviaFileReader(url)
  }

  static convertFileToDataURLviaFileReader(url: string): Observable<any> {
    return Observable.create((observer: Observer<any>) => {
      let xhr: XMLHttpRequest = new XMLHttpRequest()
      xhr.onload = function () {
        let reader: FileReader = new FileReader()
        reader.onloadend = function () {
          if (url == null || url.trim() == "") observer.next(null)
          xhr.status == 200 ? observer.next(reader.result) : observer.next(null)
          observer.complete()
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open("GET", url)
      xhr.responseType = "blob"
      xhr.send()
    })
  }

  toDataURL(url: any, callback: any) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function () {
      var reader = new FileReader()
      reader.onloadend = function () {
        callback(reader.result)
      }
      reader.readAsDataURL(xhr.response)
    }
    xhr.open("GET", url)
    xhr.responseType = "blob"
    xhr.send()
  }

  convertFileToDataURLviaFileReaderPromise(url: string): Promise<string> {
    return new Promise((resolve, reject) => {
      let xhr: XMLHttpRequest = new XMLHttpRequest()
      xhr.onload = function () {
        let reader: FileReader = new FileReader()
        reader.onloadend = function () {
          // @ts-ignore
          if (url == null || url.trim() == "") resolve(null)
          // @ts-ignore
          xhr.status == 200 ? resolve(reader.result as string) : resolve(null)
        }
        reader.readAsDataURL(xhr.response)
      }
      xhr.open("GET", url)
      xhr.responseType = "blob"
      xhr.send()
    })
  }

  duplicate(image: Image, attributeBlacklist: string[]) {
    return image.duplicate(attributeBlacklist)
  }

  /**
   * Physically copies the image file to a new URL and duplicates the image data.
   *
   * @param image an Image model instance
   */
  hardCopyImage(image: Image): Promise<Image> {
    if (image.url == null || image.url.trim() == "") throw `Image ${image.uid} does not have a URL`

    return this.filestackService.duplicateImageUrl(image.url, image.name || "Untitled image").then((result: any) => {
      const resJson = result
      return image.duplicate(["url", "name"]).then((duplicatedImage: Image) =>
        duplicatedImage.update({
          url: resJson.url,
          name: resJson["name"] || "Untitled image",
        })
      )
    })
  }

  /**
   * Physically copies the image file to a new URL and duplicates the image data.
   * Batch version.
   *
   * @param batch
   * @param image an Image model instance
   */
  async batchHardCopyImage(batch: firestore.WriteBatch, image: Image): Promise<Image> {
    if (image.url == null || image.url.trim() == "") throw `Image ${image.uid} does not have a URL`

    // @ts-ignore
    const result: { url?: string; name?: string } = await this.filestackService.duplicateImageUrl(image.url, image.name || "Untitled image")

    const duplicatedImage: Image = image.batchDuplicate(batch, ["url", "name", "aggregateData"])
    duplicatedImage.batchUpdate(batch, {
      url: result.url,
      name: result["name"] || "Untitled image",
    })

    return duplicatedImage
  }

  static isUrlComplete(url: string) {
    return url && url.startsWith("http")
  }

  static getFileStackUrl(exifRotation: boolean, resize: boolean) {
    let fileStackUrl = `https://process.filestackapi.com/${environment.FILESTACK_KEY}/`
    resize ? fileStackUrl.concat("resize=w:1000/") : ""
    exifRotation ? fileStackUrl.concat("rotate=deg:exif/") : ""
    return fileStackUrl
  }
}
