import { NgIf } from "@angular/common"
import { Component, Inject } from "@angular/core"
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog"

import { CheckdButtonComponent } from "../../checkd-ui/checkd-button/checkd-button.component"
import { IConfirmDialogData } from "./confirm-dialog-data.interface"

@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, NgIf, CheckdButtonComponent],
})
export class ConfirmDialogComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData) {}
}
