import { Component, inject, OnDestroy, OnInit, ViewChild } from "@angular/core"
import { MatSidenav, MatSidenavModule } from "@angular/material/sidenav"
import { RouterLinkActive, RouterLink, RouterOutlet } from "@angular/router"
import { Company, CompanyFeatures, LABELS, Role } from "@models/common"
import { AnalyticsService } from "@services/analytics.service"
import { NavigationService } from "@services/navigation.service"
import { SideNavService } from "@services/side-nav.service"
import { UserService } from "@services/user.service"
import { Observable, of, Subscription } from "rxjs"
import { map, switchMap } from "rxjs/operators"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { MatLegacyListModule } from "@angular/material/legacy-list"
import { NgIf, NgStyle, AsyncPipe } from "@angular/common"
import { FlexModule } from "@angular/flex-layout/flex"
import { TopBarComponent } from "./top-bar/top-bar.component"
import { FirebaseAuthService } from "@services"

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  standalone: true,
  imports: [
    TopBarComponent,
    MatSidenavModule,
    FlexModule,
    NgIf,
    MatLegacyListModule,
    RouterLinkActive,
    RouterLink,
    NgStyle,
    ExtendedModule,
    RouterOutlet,
    AsyncPipe,
  ],
})
export class DashboardComponent implements OnInit, OnDestroy {
  public userService = inject(UserService)
  private analyticsService = inject(AnalyticsService)
  public navigationService = inject(NavigationService)
  public sideNavService = inject(SideNavService)
  private readonly authService = inject(FirebaseAuthService)

  subscriptions: Subscription[]

  @ViewChild("snav", { static: true }) snav: MatSidenav

  readonly currentCompanyLogo$ = this.userService.listenToCurrentMainCompany().pipe(map((company) => company?.logo || ""))

  // readonly formsLibraryActive$ = this.navigationService.pathComponents$.pipe(
  //   map(
  //     (pathComponents) =>
  //       pathComponents.indexOf("forms") === 0 && (pathComponents.indexOf("library") === 1 || pathComponents.indexOf("templates") === 1)
  //   )
  // )

  // readonly companyFormsActive$ = this.navigationService.pathComponents$.pipe(
  //   map((pathComponents) => pathComponents.indexOf("forms") === 0 && pathComponents.indexOf("company") === 1)
  // )

  // readonly memberCompaniesActive$ = this.navigationService.pathComponents$.pipe(
  //   map((pathComponents) => pathComponents.indexOf("forms") === 0 && pathComponents.indexOf("member-companies") === 1)
  // )

  ngOnInit() {
    this.setupSubscriptions()
    this.analyticsService.initializeIntercom()
  }

  setupSubscriptions() {
    this.subscriptions = [
      this.authService.authState$
        .pipe(
          switchMap((authState: any) => {
            if (!authState) {
              // No signed in user - update Intercom with empty data
              this.analyticsService.updateIntercomData(null)
              // Return observable that emits null to keep subscription alive
              return of(null)
            }
            // User is signed in - get user and company data
            return this.userService.listenToCurrentUserAndMainCompany()
          }),
        )
        .subscribe((data) => {
          if (data) {
            this.analyticsService.updateIntercomData(data)
          }
        }),

      this.sideNavService.sideNavButtonClicked$.subscribe((value) => {
        // tslint:disable-next-line:switch-default
        switch (value) {
          case "open":
            this.snav.open()
            break
          case "close":
            this.snav.close()
            break
          case "toggle":
            this.snav.toggle()
            break
        }
      }),
    ]
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe())
  }

  currentCompanyHasCompanyInsightsFeature(currentCompany: Company) {
    return currentCompany && (currentCompany.features || []).includes(CompanyFeatures.COMPANY_INSIGHTS)
  }

  readonly shouldDisplayFormsMenuInSidebar$: Observable<boolean> = this.userService.currentCompanyFeatures$.pipe(
    map((currentCompanyFeatures) => {
      // The current company needs at least one of these features to be able to view the forms menu in the sidebar
      const authorizedFeatures = [CompanyFeatures.FORMS_LIBRARY, CompanyFeatures.MEMBER_TEMPLATES_VIEWER, CompanyFeatures.FORMS]

      return currentCompanyFeatures.some((feature) => authorizedFeatures.includes(feature))
    }),
  )

  readonly currentCompanyHasFormsFeature$: Observable<boolean> = this.userService.currentCompanyFeatures$.pipe(
    map((features) => features.includes(CompanyFeatures.FORMS)),
  )

  userIsCompanyAdmin(userCompanyRole: Role): boolean {
    return userCompanyRole && (userCompanyRole.labels || []).includes(LABELS.ADMINISTRATOR)
  }
}
