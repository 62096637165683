import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import { map, switchMap } from "rxjs/operators"

import { combineLatest, EMPTY, Observable } from "rxjs"
import { ImageService } from "./image.service"
import { RelationService } from "./relation.service"
import { SnackbarService } from "./snackbar.service"
import { TimelineService } from "./timeline.service"
import { UserService } from "./user.service"

import {
  COLLECTIONS,
  convertStatusToWorkflowStates,
  convertWorkflowStateToStatus,
  Drawing,
  FilestackUploadData,
  FilestackUploadResult,
  Image,
  Item,
  ItemData,
  ItemMenuActions,
  ItemSorter,
  ItemTaskHandler,
  LABELS,
  Person,
  PersonData,
  Project,
  Relation,
  ReportType,
  Role,
  RoleType,
  SortingConfig,
  SortingDirection,
  Task,
  TaskAggregateData,
  TaskData,
  Timeline,
  TimelineType,
  WorkflowStates,
} from "@models/common"
import { createItemMenuActionData } from "@models/common/actions/item-menu-actions"
import { GeneralReport } from "@models/common/general-report"
import { orderByCreatedAt } from "@services/utilities"
import firebase from "firebase/compat/app"
import { v4 as uuidv4 } from "uuid"
import { ItemDialogService } from "../items/item-dialog.service"
import firestore = firebase.firestore
import { FilestackService } from "./filestack.service"
import { ModelService } from "./model.service"
import WriteBatch = firestore.WriteBatch

@Injectable({
  providedIn: "root",
})
export class ItemService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private filestackService: FilestackService,
    private timelineService: TimelineService,
    private snackbarService: SnackbarService,
    private relationService: RelationService,
    private itemDialogService: ItemDialogService,
    private imageService: ImageService // TODO See if we need an ItemImageService if we need more decoupling
  ) {}

  listenToUid(itemUid: string): Observable<Item> {
    return this.modelService.listenTo(COLLECTIONS.ITEMS, itemUid) as Observable<Item>
  }

  /**
   * @deprecated
   */
  listenTo(item: Item) {
    return this.listenToUid(item.uid)
  }

  listenToCreator(item: Item): Observable<Person> {
    return (
      this.relationService
        // @ts-ignore
        .listenToTargets(item, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY])
        .pipe(map((people) => (people ? people[0] : EMPTY))) as Observable<Person>
    )
  }

  listenToTask(item: Item): Observable<Task> {
    return this.listenToTasks(item).pipe(map((tasks) => (tasks ? tasks[0] : EMPTY))) as Observable<Task>
  }

  listenToTasks(item: Item): Observable<Task[]> {
    // @ts-ignore
    return this.relationService.listenToTargets(item, COLLECTIONS.TASKS) as Observable<Task[]>
  }

  listenToDrawing(item: Item): Observable<Drawing> {
    return this.listenToDrawings(item).pipe(map((drawings) => (drawings ? drawings[0] : EMPTY))) as Observable<Drawing>
  }

  listenToDrawings(item: Item): Observable<Drawing[]> {
    // @ts-ignore
    return this.relationService.listenToTargets(item, COLLECTIONS.DRAWINGS) as Observable<Drawing[]>
  }

  listenToDrawingProject(item: Item): Observable<Project> {
    // @ts-ignore
    return this.listenToDrawingProjects(item).pipe(map((projects) => (projects ? projects[0] : EMPTY)))
  }

  listenToDrawingProjects(item: Item) {
    return this.listenToDrawings(item).pipe(
      // @ts-ignore
      switchMap((drawings) =>
        this.relationService.combineLatestOrEmptyList(
          drawings.map((drawing: Drawing) => this.relationService.listenToTargets(drawing, COLLECTIONS.PROJECTS))
        )
      ),
      // @ts-ignore
      map((nestedProjects: Project[]) => [].concat(...nestedProjects))
    )
  }

  listenToProject(item: Item): Observable<Project> {
    return combineLatest([this.listenToProjects(item), this.listenToDrawingProject(item)]).pipe(
      map((it) => (it[0] != null && it[0].length > 0 ? it[0][0] : it[1]))
    ) as Observable<Project>
  }

  listenToProjects(item: Item): Observable<Project[]> {
    // @ts-ignore
    return this.relationService.listenToTargets(item, COLLECTIONS.PROJECTS) as Observable<Project[]>
  }

  listenToImages(item: Item): Observable<Image[]> {
    return (
      this.relationService
        // @ts-ignore
        .listenToTargets(item, COLLECTIONS.IMAGES)
        .pipe(map((images) => orderByCreatedAt(images, SortingDirection.ASC))) as Observable<Image[]>
    )
  }

  async removeItemImage(remover: Person, item: Item, timeline: Timeline, image: Image) {
    const batch = this.db.firestore.batch()
    const imageElements = (timeline.elements || []).filter(
      (element) =>
        element.type === TimelineType.IMAGE_ADDED &&
        element.currentData &&
        element.currentData.image &&
        element.currentData.image.url === image.url
    )
    image.batchDisable(batch)
    imageElements.forEach((element) => element.disable(batch, remover))
    this.timelineService.itemImageRemoved(batch, remover, item, image)

    return batch.commit()
  }

  // if passing in newDate as a number, make sure to include milliseconds
  public async updateDueDate(batch: firestore.WriteBatch, currentUser: Person, item: Item, newDate: number | Date | null) {
    let date: number

    if (newDate !== null && typeof newDate !== "number") {
      date = newDate.valueOf()
    } else {
      date = newDate as number
    }

    await this.timelineService.batchItemDueDateUpdate(batch, currentUser, item, date)
    item.batchUpdate(batch, { dueDate: date })
  }

  async disable(item: Item, itemImages?: Image[]) {
    const batch = this.db.firestore.batch()

    const task: Task = await item.getTask()
    if (task != null) {
      task.batchDisable(batch)
    }

    // Fetch the images if they are not supplied in the arguments
    // @ts-ignore
    const images = itemImages || (await this.relationService.getTargets(item, COLLECTIONS.IMAGES))

    for (const image of images) {
      image.batchDisable(batch)
    }

    item.batchDisable(batch)

    await batch.commit()
  }

  async close(closer: Person, item: Item, itemImages?: Image[]) {
    const batch = this.db.firestore.batch()
    // @ts-ignore
    const task = (await this.relationService.getFirstTarget(item, COLLECTIONS.TASKS)) as Task
    const itemAndTask = this.batchClose(batch, item, task)

    // Fetch the images if they are not supplied in the arguments
    // @ts-ignore
    const images = itemImages || (await this.relationService.getTargets(item, COLLECTIONS.IMAGES))

    for (const image of images) {
      image.batchUpdateAggregateData(batch, {
        itemStatus: WorkflowStates.CLOSED,
      })
    }

    await this.timelineService.batchStatusChanged(batch, closer, itemAndTask.item, itemAndTask.task)
    await batch.commit()

    return { item: itemAndTask.item, task: itemAndTask.task }
  }

  batchAddTags(batch: WriteBatch, item: Item, tags: string[], bulkUid: string | null) {
    if (item == null) {
      throw new Error("Item was null")
    }

    item.batchUpdate(batch, { tags: firebase.firestore.FieldValue.arrayUnion(...tags) })

    return { item }
  }

  batchClose(batch: WriteBatch, item: Item, task: Task, bulkUid?: string) {
    if (item == null) {
      throw new Error("Item was null")
    }

    item.batchUpdate(batch, { status: WorkflowStates.CLOSED })
    if (task != null) {
      task.batchUpdate(batch, { status: WorkflowStates.CLOSED, bulkUid: bulkUid || null })
    }

    return { item, task }
  }

  async duplicateItemImages(originalItem: Item, duplicatedItem: Item) {
    const batch = this.db.firestore.batch()

    const originalImages = await originalItem.getImages()

    const duplicatedImages = await Promise.all(originalImages.map((image) => this.imageService.batchHardCopyImage(batch, image)))

    for (const image of duplicatedImages) {
      this.batchAddImage(batch, duplicatedItem, image)
    }

    await batch.commit()

    return duplicatedImages
  }

  async batchDuplicateItem(batch: firestore.WriteBatch, item: Item, duplicator?: Person): Promise<Item> {
    const duplicatedItem: Item = await item.batchDuplicate(batch, ["number", "status", "positionX", "positionY"])
    if (duplicator != null) {
      this.batchAddItemCreator(batch, duplicatedItem, duplicator)
    }

    duplicatedItem.batchUpdate(batch, {
      status: WorkflowStates.OPEN,
      aggregateData: {
        itemCreatorUid: duplicator!.uid,
        itemCreatorName: duplicator!.name,
        itemCreatorCompanyName: duplicator!.aggregateData["companyName"] || null,
        projectUid: item.aggregateData.projectUid || null,
        projectName: item.aggregateData.projectName || null,
        drawingUid: item.aggregateData.drawingUid || null,
        drawingName: item.aggregateData.drawingName || null,
      },
    })

    return duplicatedItem
  }

  async batchDuplicateOnDrawing(batch: firestore.WriteBatch, originalItem: Item, drawing: Drawing, duplicator?: Person) {
    const duplicatedItem = await this.batchDuplicateItem(batch, originalItem, duplicator)
    this.batchOffsetDuplicatedItemCoordinates(batch, duplicatedItem, originalItem)
    this.batchAddItemToDrawing(batch, duplicatedItem, drawing)

    return duplicatedItem
  }

  async duplicateDrawingItem(originalItem: Item, project: Project, drawing: Drawing, duplicator?: Person) {
    const batch = this.db.firestore.batch()
    const duplicatedItem = await this.batchDuplicateOnDrawing(batch, originalItem, drawing, duplicator)

    if (duplicatedItem.aggregateData.projectName !== project.name) {
      duplicatedItem.batchUpdate(batch, {
        aggregateData: { ...duplicatedItem.aggregateData, projectName: project.name },
      })
    }

    if (duplicator != null) {
      await this.timelineService.batchItemCreated(batch, duplicator, duplicatedItem)
    }

    await batch.commit()

    this.duplicateItemImages(originalItem, duplicatedItem)

    return duplicatedItem
  }

  async updateItem(item: Item, itemData: any, editor: Person, dueDateChanged: boolean) {
    try {
      const batch = this.db.firestore.batch()
      item.batchUpdate(batch, {
        name: itemData.name || "",
        description: itemData.description || "",
        tags: itemData.tags || [],
      })
      await this.timelineService.addItemEdited(batch, editor, item)

      await batch.commit()
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async batchUpdateItem(batch: firestore.WriteBatch, item: Item, itemData: any, editor: Person) {
    item.batchUpdate(batch, {
      name: itemData.name || "",
      description: itemData.description || "",
      tags: itemData.tags || [],
    })
    await this.timelineService.addItemEdited(batch, editor, item)
  }

  async editItemDetails(item: Item, editor: Person, project: Project, itemImages?: Image[]) {
    const originalItemData = item.data
    // Fetch the images if they are not supplied in the arguments
    // @ts-ignore
    const images = itemImages || (await this.relationService.getTargets(item, COLLECTIONS.IMAGES))

    // @ts-ignore
    const { itemData, dueDateChanged, itemDetailsChanged } = await this.itemDialogService.editItemDetails(item, editor, project)

    try {
      const batch = this.db.firestore.batch()
      if (itemData != null && itemDetailsChanged) {
        await this.batchUpdateItem(batch, item, itemData, editor)
        if (originalItemData.name !== itemData.name) {
          for (const image of images) {
            image.batchUpdateAggregateData(batch, {
              itemName: itemData.name,
            })
          }
        }
      }

      if (itemData != null && dueDateChanged) {
        await this.updateDueDate(batch, editor, item, itemData.dueDate)
      }
      await batch.commit()
    } catch (err) {
      this.snackbarService.showMessage(err.message)
    }
  }

  async duplicateProjectItem(originalItem: Item, project: Project, duplicator?: Person) {
    const batch = this.db.firestore.batch()
    const drawing = await originalItem.getDrawing()

    if (drawing != null) {
      const duplicatedItem = await this.batchDuplicateOnDrawing(batch, originalItem, drawing, duplicator)
      if (duplicatedItem.aggregateData.projectName !== project.name) {
        duplicatedItem.batchUpdate(batch, {
          aggregateData: { ...duplicatedItem.aggregateData, projectName: project.name },
        })
      }

      await batch.commit()
      this.duplicateItemImages(originalItem, duplicatedItem)
      if (duplicator != null) {
        this.timelineService.itemCreated(duplicator, duplicatedItem)
      }

      return duplicatedItem
    }

    const duplicatedItem = await this.batchDuplicateItem(batch, originalItem, duplicator)
    this.batchAddItemToProject(batch, duplicatedItem, project)
    await batch.commit()
    this.duplicateItemImages(originalItem, duplicatedItem)

    return duplicatedItem
  }

  sortItems(items: Item[], sortingConfig: SortingConfig): Item[] {
    return ItemSorter.sortBy(items, sortingConfig)
  }

  batchOffsetDuplicatedItemCoordinates(
    batch: firestore.WriteBatch,
    duplicatedItem: Item,
    originalItem: Item,
    amountMin = 0.01,
    amountMax = 0.02
  ): Item {
    if (originalItem.positionX == null && originalItem.positionY == null) {
      return duplicatedItem
    }

    const amount = Math.random() * (amountMax - amountMin) + amountMax
    const positionX = originalItem.positionX! < 0.5 ? originalItem.positionX! + amount : originalItem.positionX! - amount
    const positionY = originalItem.positionY! < 0.5 ? originalItem.positionY! + amount : originalItem.positionY! - amount

    return duplicatedItem.batchUpdate(batch, { positionX, positionY })
  }

  async checkBulkAssignPrivileges(assigner: Person, personProjectRole: Role, items: Item[]) {
    const itemCreators = await Promise.all(
      // @ts-ignore
      items.map((item) => this.relationService.getFirstTarget(item, COLLECTIONS.PEOPLE, [LABELS.CREATED_BY]))
    )
    let allowed: any[] = []
    let denied: any[] = []

    for (let i = 0; i < itemCreators.length; i++) {
      if (itemCreators[i].uid === assigner.uid) {
        allowed = [...allowed, items[i]]
      } else {
        denied = [...denied, items[i]]
      }
    }

    return { allowed, denied }
  }

  /**
   *
   * Check privileges for bulk accepting a list of items.
   *
   * Requirements:
   * - The item/task needs to be in a delegated state
   * - The acceptor must be the assignee
   *
   * @param acceptor a person who wants to accept the tasks
   * @param items a list of items whose tasks should be accepted by the acceptor
   */
  async checkBulkAcceptPrivileges(acceptor: Person, items: Item[]) {
    // @ts-ignore
    const tasks = (await Promise.all(items.map((item) => this.relationService.getFirstTarget(item, COLLECTIONS.TASKS)))) as Task[]

    const assignees = await Promise.all(
      tasks.map((task: Task) => (task != null ? this.relationService.getRelations(task, COLLECTIONS.PEOPLE, [LABELS.ASSIGNED_TO]) : []))
    )

    let allowed: any[] = []
    let denied: any[] = []

    for (let i = 0; i < items.length; i++) {
      const workflowStates = convertStatusToWorkflowStates("DELEGATED")

      // Add to denied if it is in the wrong state
      if (workflowStates == null || workflowStates.length < 1 || !workflowStates.includes(items[0].status)) {
        denied = [...denied, { item: items[i], task: tasks[i] }]
        continue
      }

      // Add to allowed if the acceptor user is the assignee
      if (assignees[i].map((rel) => rel.uid).includes(acceptor.uid)) {
        allowed = [...allowed, { item: items[i], task: tasks[i] }]
        continue
      }

      // Add to denied if all previous checks fail
      denied = [...denied, { item: items[i], task: tasks[i] }]
    }

    return { allowed, denied }
  }

  async bulkAddTags(tags: string[], items: Item[], actorProjectRole: Role, actor: Person) {
    // Uses the same privileges as bulkAssign
    const { allowed, denied } = await this.checkBulkAssignPrivileges(actor, actorProjectRole, items)

    const allowedRoleTypes = [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR]
    if (denied.length > 0) {
      if (!allowedRoleTypes.includes(actorProjectRole.roleType)) {
        throw new Error(
          `Insufficient permissions for ${denied.length} out of ${allowed.length + denied.length} items. Deselect these and try again.`
        )
      }
    }

    const bulkUid = items.length > 1 ? uuidv4() : null

    await Promise.all(
      items.map(async (item) => {
        const batch = this.db.firestore.batch()

        await this.batchAddTags(batch, item, tags, bulkUid)
        this.timelineService.itemEdited(batch, actor, item)

        return batch.commit()
      })
    )
  }

  async bulkAssign(assigner: Person, assignee: Person, personProjectRole: Role, items: Item[]) {
    const { allowed, denied } = await this.checkBulkAssignPrivileges(assigner, personProjectRole, items)

    const allowedRoleTypes = [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR]

    if (denied.length > 0) {
      if (!allowedRoleTypes.includes(personProjectRole.roleType)) {
        throw new Error(
          `Insufficient permissions for ${denied.length} out of ${allowed.length + denied.length} items. Deselect these and try again.`
        )
      }
    }

    const bulkUid = items.length > 1 ? uuidv4() : null

    const itemsWithTasks = await Promise.all(
      items.map(async (item) => {
        // @ts-ignore
        const task = (await this.relationService.getFirstTarget(item, COLLECTIONS.TASKS)) as Task

        return { item, task }
      })
    )

    await Promise.all(
      itemsWithTasks.map(async (itemAndTask) => {
        const batch = this.db.firestore.batch()

        if (itemAndTask.task == null) {
          // @ts-ignore
          const assignedItemAndTask = await ItemTaskHandler.batchAssignItem(batch, itemAndTask.item, assigner, assignee, bulkUid)
          this.timelineService.batchTaskAssigneeAdded(batch, assigner, assignedItemAndTask.item, assignedItemAndTask.task, assignee, false)
        } else {
          // @ts-ignore
          const task = await ItemTaskHandler.batchAssignOrReassignTask(batch, itemAndTask.task, assigner, assignee, bulkUid)
          this.timelineService.batchTaskAssigneeAdded(batch, assigner, itemAndTask.item, task, assignee, false)
        }

        return batch.commit()
      })
    )
  }

  async bulkClose(closer: Person, personProjectRole: Role, items: Item[]) {
    const { allowed, denied } = await this.checkBulkAssignPrivileges(closer, personProjectRole, items)

    const allowedRoleTypes = [RoleType.PROJECT_OWNER, RoleType.PROJECT_ADMINISTRATOR]

    if (denied.length > 0) {
      if (!allowedRoleTypes.includes(personProjectRole.roleType)) {
        throw new Error(`Insufficient permissions for ${denied.length} out of ${allowed.length + denied.length}`)
      }
    }

    const bulkUid = items.length > 1 ? uuidv4() : null

    const itemsWithTasks = await Promise.all(
      items.map(async (item) => {
        // @ts-ignore
        const task = (await this.relationService.getFirstTarget(item, COLLECTIONS.TASKS)) as Task

        return { item, task }
      })
    )

    await Promise.all(
      itemsWithTasks.map(async (itemAndTask) => {
        const batch = this.db.firestore.batch()
        // @ts-ignore
        await this.batchClose(batch, itemAndTask.item, itemAndTask.task, bulkUid)
        this.timelineService.batchStatusChanged(batch, closer, itemAndTask.item, itemAndTask.task, false)

        return batch.commit()
      })
    )
  }

  async bulkAccept(acceptor: Person, selectedItems: Item[]) {
    const { allowed, denied } = await this.checkBulkAcceptPrivileges(acceptor, selectedItems)

    if (denied.length > 0) {
      throw new Error(
        `Insufficient permissions for ${denied.length} out of ${allowed.length + denied.length} tasks. Deselect these and try again.`
      )
    }

    await Promise.all(
      allowed.map(({ item, task }) => {
        const batch = this.db.firestore.batch()

        if (item != null && task != null) {
          ItemTaskHandler.batchSetStatus(batch, WorkflowStates.ACCEPTED, item, task)
          this.timelineService.batchStatusChanged(batch, acceptor, item, task, false)
        }

        return batch.commit()
      })
    )
  }

  async assign(item: Item, assigner: Person, assignee: Person, itemImages: Image[]): Promise<Task> {
    const batch = this.db.firestore.batch()
    const { task } = await ItemTaskHandler.batchAssignItem(batch, item, assigner, assignee)

    if (task.status) {
      await Promise.all(
        itemImages.map((image) =>
          image.batchUpdateAggregateData(batch, {
            itemStatus: task.status,
          })
        )
      )
    }

    await batch.commit()

    return task
  }

  batchAddTaskToItem(batch: firestore.WriteBatch, task: Task, item: Item): Relation[] {
    // @ts-ignore
    const relations = [task.batchAdd(batch, item, [LABELS.CONCERNS]), item.batchAdd(batch, task, [LABELS.CONCERNED_BY])]

    return relations
  }

  batchAddItemToDrawing(batch: firestore.WriteBatch, item: Item, drawing: Drawing): Relation[] {
    if (item.aggregateData.drawingName !== drawing.name) {
      item.batchUpdate(batch, {
        aggregateData: { ...item.aggregateData, drawingName: drawing.name, drawingUid: drawing.uid },
      })
    }

    // @ts-ignore
    const relations = [item.batchAdd(batch, drawing, [LABELS.CONTAINED_BY]), drawing.batchAdd(batch, item, [LABELS.CONTAINS])]

    return relations
  }

  async addItemToDrawingWithPosition(item: Item, drawing: Drawing, positionX: number, positionY: number): Promise<void> {
    const batch = this.db.firestore.batch()
    this.batchAddItemToDrawing(batch, item, drawing)
    item.batchUpdate(batch, { positionX, positionY })

    return batch.commit()
  }

  /**
   * @deprecated use batchAddItemToDrawing instead
   */
  async addItemToDrawing(item: Item, drawing: Drawing): Promise<Relation[]> {
    if (item.aggregateData.drawingName !== drawing.name) {
      await item.update({
        aggregateData: { ...item.aggregateData, drawingName: drawing.name, drawingUid: drawing.uid },
      })
    }

    // @ts-ignore
    return Promise.all([item.add(drawing, [LABELS.CONTAINED_BY]), drawing.add(item, [LABELS.CONTAINS])])
  }

  batchAddItemToProject(batch: firestore.WriteBatch, item: Item, project: Project): Relation[] {
    if (item.aggregateData.projectName !== project.name) {
      item.batchUpdate(batch, {
        aggregateData: { ...item.aggregateData, projectName: project.name },
      })
    }

    // @ts-ignore
    const relations = [item.batchAdd(batch, project, [LABELS.CONTAINED_BY]), project.batchAdd(batch, item, [LABELS.CONTAINS])]

    return relations
  }

  batchAddItemToReport(batch: firestore.WriteBatch, item: Item, report: GeneralReport): Relation[] {
    // @ts-ignore
    return [item.batchAdd(batch, report, [LABELS.CONTAINED_BY]), report.batchAdd(batch, item, [LABELS.CONTAINS])]
  }

  batchAddImage(batch: firestore.WriteBatch, item: Item, image: Image): Relation[] {
    // @ts-ignore
    const relations = [item.batchAdd(batch, image, [LABELS.CONTAINS]), image.batchAdd(batch, item, [LABELS.CONTAINED_BY])]
    image.batchUpdateAggregateData(batch, {
      projectUid: item.aggregateData.projectUid || null,
      drawingUid: item.aggregateData.drawingUid || null,
      itemUid: item.uid,
      itemName: item.name || null,
      itemStatus: item.status || null,
      itemNumber: item.number || null,
    })

    return relations
  }

  batchAddItemCreator(batch: firestore.WriteBatch, item: Item, creator: Person): Relation[] {
    if (item.aggregateData.itemCreatorName !== creator.name) {
      item.batchUpdate(batch, {
        aggregateData: { ...item.aggregateData, itemCreatorName: creator.name },
      })
    }

    // @ts-ignore
    const relations = [item.batchAdd(batch, creator, [LABELS.CREATED_BY]), creator.batchAdd(batch, item, [LABELS.CREATOR])]

    return relations
  }

  /**
   *
   * @deprecated use batchAddItemCreator
   */
  async addItemCreator(item: Item, creator: Person): Promise<Relation[]> {
    if (item.aggregateData.itemCreatorName !== creator.name) {
      await item.update({
        aggregateData: { ...item.aggregateData, itemCreatorName: creator.name },
      })
    }

    // @ts-ignore
    const relations = await Promise.all([item.add(creator, [LABELS.CREATED_BY]), creator.add(item, [LABELS.CREATOR])])

    return relations
  }

  batchSetTaskAssigner(batch: firestore.WriteBatch, task: Task, assigner: Person): Relation[] {
    if (task.aggregateData["taskAssignerName"] !== assigner.name) {
      task.batchUpdate(batch, {
        aggregateData: { ...task.aggregateData, taskAssignerName: assigner.name },
      })
    }

    const relations = [task.batchAdd(batch, assigner, [LABELS.ASSIGNED_BY]), assigner.batchAdd(batch, task, [LABELS.HAS_ASSIGNED])]

    return relations
  }

  batchSetTaskAssignee(batch: firestore.WriteBatch, task: Task, assignee: Person): Relation[] {
    if (task.aggregateData["taskAssigneeName"] !== assignee.name) {
      task.batchUpdate(batch, {
        aggregateData: { ...task.aggregateData, taskAssigneeName: assignee.name },
      })
    }

    const relations = [task.batchAdd(batch, assignee, [LABELS.ASSIGNED_TO]), assignee.batchAdd(batch, task, [LABELS.IS_ASSIGNED])]

    return relations
  }

  async batchCreateTaskForItem(batch: firestore.WriteBatch, taskData: TaskData, item: Item): Promise<{ item: Item; task: Task }> {
    const aggregateData: Partial<TaskAggregateData> = {}
    const fullTaskData = { aggregateData, ...taskData }
    let assigner = null
    let assignee = null

    if (taskData.assigner != null) {
      assigner = await Person.get(taskData.assigner.id)
      fullTaskData.aggregateData.taskAssignerName = assigner.name
    }

    if (taskData.assignee != null) {
      assignee = await Person.get(taskData.assignee.id)
      fullTaskData.aggregateData.taskAssigneeName = assignee.name
    }

    const task = Task.batchCreate(batch, Task, fullTaskData)
    this.batchAddTaskToItem(batch, task, item)

    if (assigner) {
      this.batchSetTaskAssigner(batch, task, assigner)
    }
    if (assignee) {
      this.batchSetTaskAssignee(batch, task, assignee)
    }

    return { item, task }
  }

  async createDrawingItem(
    creator: Person,
    project: Project,
    drawing: Drawing,
    itemData: ItemData,
    taskData?: TaskData
  ): Promise<{ item: Item; task?: Task }> {
    const batch = this.db.firestore.batch()

    const aggregateData = {
      ...(itemData.aggregateData || {}),
      itemCreatorName: creator.name || null,
      itemCreatorUid: creator.uid || null,
      drawingName: drawing.name || null,
      drawingUid: drawing.uid || null,
      projectName: project.name || null,
      projectUid: project.uid || null,
    }

    // @ts-ignore
    const item = Item.batchCreate(batch, Item, {
      ...itemData,
      aggregateData,
    })
    let task = null

    // @ts-ignore
    this.batchAddItemCreator(batch, item, creator)
    // @ts-ignore
    this.batchAddItemToDrawing(batch, item, drawing)

    if (taskData != null) {
      // @ts-ignore
      const result = await this.batchCreateTaskForItem(batch, taskData, item)
      task = result.task
    }
    // @ts-ignore
    this.timelineService.batchItemCreated(batch, creator, item)

    await batch.commit()

    // @ts-ignore
    return { item, task }
  }

  async createReportItem(
    creator: Person,
    project: Project,
    report: GeneralReport,
    itemData: ItemData,
    taskData?: TaskData
  ): Promise<{ item: Item; task?: Task }> {
    const batch = this.db.firestore.batch()

    const agReportName = report.reportType === ReportType.FIELD ? "reportName" : "legacyReportName"
    const agReportUid = report.reportType === ReportType.FIELD ? "reportUid" : "legacyReportUid"

    const aggregateData = {
      ...(itemData.aggregateData || {}),
      itemCreatorName: creator.name || null,
      itemCreatorUid: creator.uid || null,
      projectName: project.name || null,
      projectUid: project.uid || null,
      [agReportName]: report.name || null,
      [agReportUid]: report.uid || null,
    }

    // @ts-ignore
    const item = Item.batchCreate(batch, Item, { ...itemData, aggregateData })
    let task = null

    // @ts-ignore
    this.batchAddItemCreator(batch, item, creator)
    // @ts-ignore
    this.batchAddItemToProject(batch, item, project)
    // @ts-ignore
    this.batchAddItemToReport(batch, item, report)

    if (taskData != null) {
      // @ts-ignore
      const result = await this.batchCreateTaskForItem(batch, taskData, item)
      task = result.task
    }
    // @ts-ignore
    this.timelineService.batchItemCreated(batch, creator, item)
    // @ts-ignore
    this.timelineService.batchItemConnectedToReport(batch, creator, item, report)

    await batch.commit()

    // @ts-ignore
    return { item, task }
  }

  async createProjectItem(
    creator: Person,
    project: Project,
    itemData: ItemData,
    taskData?: TaskData
  ): Promise<{ item: Item; task?: Task }> {
    const batch = this.db.firestore.batch()

    const aggregateData = {
      ...(itemData.aggregateData || {}),
      itemCreatorName: creator.name || null,
      itemCreatorUid: creator.uid || null,
      projectName: project.name || null,
      projectUid: project.uid || null,
    }

    // @ts-ignore
    const item = Item.batchCreate(batch, Item, { ...itemData, aggregateData })

    let task = null

    // @ts-ignore
    this.batchAddItemCreator(batch, item, creator)
    // @ts-ignore
    this.batchAddItemToProject(batch, item, project)

    if (taskData != null) {
      // @ts-ignore
      const result = await this.batchCreateTaskForItem(batch, taskData, item)
      task = result.task
    }
    // @ts-ignore
    await this.timelineService.batchItemCreated(batch, creator, item)

    await batch.commit()

    // @ts-ignore
    return { item, task }
  }

  workflowStateToStatus(state: string) {
    return convertWorkflowStateToStatus(state) || "OPEN"
  }

  workflowStateToColorClass(state: string) {
    return this.statusToColorClass(convertWorkflowStateToStatus(state))
  }

  statusToColorClass(status: string): string {
    const mappings: { [key: string]: string } = {
      OPEN: "status-color-white",
      DELEGATED: "status-color-red",
      INPROGRESS: "status-color-yellow",
      FIXED: "status-color-blue",
      CLOSED: "status-color-green",
    }

    return mappings[status] ?? "status-color-white"
  }

  getMenuOptions(
    item: Item,
    itemCreatorName: string,
    userProjectRole: Role,
    currentUser: Person,
    additionalItemMenuActions: ItemMenuActions[] = []
  ) {
    const menuOptions = []

    if (!item.archived) {
      if (
        (this.isUserCreator(item, itemCreatorName, currentUser) || this.isProjectAdmin(userProjectRole)) &&
        item.status !== WorkflowStates.CLOSED
      ) {
        menuOptions.push(createItemMenuActionData(item, ItemMenuActions.SET_AS_CLOSED))
      }

      if (this.isUserCreator(item, itemCreatorName, currentUser) || this.isProjectAdmin(userProjectRole)) {
        menuOptions.push(createItemMenuActionData(item, ItemMenuActions.REMOVE_ITEM))
      }

      menuOptions.push(createItemMenuActionData(item, ItemMenuActions.ADD_COLLABORATOR))

      menuOptions.push(createItemMenuActionData(item, ItemMenuActions.DUPLICATE_ITEM))

      if (
        (this.isUserCreator(item, itemCreatorName, currentUser) || this.isProjectAdmin(userProjectRole)) &&
        item.status !== WorkflowStates.CLOSED
      ) {
        menuOptions.push(createItemMenuActionData(item, ItemMenuActions.EDIT_DETAILS))
      }

      if (this.isUserCreator(item, itemCreatorName, currentUser) || this.isProjectAdmin(userProjectRole)) {
        menuOptions.push(createItemMenuActionData(item, ItemMenuActions.UPLOAD_IMAGE))
      }
    }

    menuOptions.push(createItemMenuActionData(item, ItemMenuActions.VIEW_IMAGES))

    const additionalActions = (additionalItemMenuActions || []).map((action) => createItemMenuActionData(item, action)).filter((it) => it)

    return [...menuOptions, ...additionalActions]
  }

  public async handleFilesUpload(item: Item, uploadResult: FilestackUploadResult, uploader: Person) {
    if (uploadResult.filesFailed != null && uploadResult.filesFailed.length > 0) {
      this.handleFailedFilesUpload(uploadResult.filesFailed)
    }

    const imagesData = await Promise.all(
      uploadResult.filesUploaded.map((uploadData: FilestackUploadData) => this.filestackService.createImageDataFromUploadData(uploadData))
    )

    const batch = this.db.firestore.batch()
    const images = imagesData.map((imageData) => Image.batchCreate(batch, Image, imageData))
    for (const image of images) {
      this.batchAddImage(batch, item, image)
    }

    await Promise.all(images.map((image) => this.timelineService.batchAddItemImage(batch, uploader, item, image)))

    await batch.commit()

    return images
  }

  private isUserCreator(item: Item, itemCreatorName: string, currentUser: Person): boolean {
    if (item == null || itemCreatorName == null || currentUser == null) {
      return false
    }

    return itemCreatorName == currentUser.name
  }

  private isProjectAdmin(userProjectRole: Role) {
    return userProjectRole && userProjectRole.canUpdateTargetDocuments(Item.COLLECTION)
  }

  private handleFailedFilesUpload(failedFiles: any) {
    // TODO implement
    console.error("Failed upload:", failedFiles)
  }

  public async removeCollaborator(item: Item, collaboratorUid: string) {
    const [collaborator, task]: [Person, Task] = await Promise.all([
      Person.get(collaboratorUid),
      // @ts-ignore
      Relation.getAllTargets(item, COLLECTIONS.TASKS).then((tasks) => (tasks ? tasks[0] : null)),
    ])

    if (collaborator == null) {
      throw new Error(`Unable to fetch collaborator ${collaboratorUid}`)
    }
    if (task == null) {
      throw new Error(`Unable to fetch task for item ${item.uid}`)
    }

    const [userTaskRelation, taskUserRelation] = await Promise.all([
      this.relationService.getRelation(collaborator, task),
      this.relationService.getRelation(task, collaborator),
    ])

    const batch = this.db.firestore.batch()

    // Update taskCollaboratorUids and taskCollaboratorNames in the item aggregate data
    const collaboratorUids = item.aggregateData.taskCollaboratorUids || []
    const collaboratorNames = item.aggregateData.taskCollaboratorNames || []
    const collaboratorIndex = collaboratorUids.indexOf(collaboratorUid)
    const taskCollaboratorUids = collaboratorUids.filter((_, i) => i !== collaboratorIndex)
    const taskCollaboratorNames = collaboratorNames.filter((_, i) => i !== collaboratorIndex)

    batch.set(
      item.ref!,
      {
        aggregateData: {
          taskCollaboratorUids,
          taskCollaboratorNames,
        },
      },
      { merge: true }
    )

    // LEGACY: Update collaborator attribute for task dockument
    const collaboratorRefIndex = task.collaboratorRefs.findIndex((ref) => ref.id === collaboratorUid)
    const collaborators = task.collaboratorRefs.filter((_, i) => i !== collaboratorRefIndex)

    batch.set(
      task.ref!,
      {
        collaborators,
      },
      { merge: true }
    )

    // NB: We're taking some security measures here.
    // We only disable the relations if there are no other labels than COLLABORATOR_TO / HAS_COLLABORATOR
    // so that if the user has any other type of relationship to the task it is not gone after this operation.
    // (e.g. the user has somehow managed to become both an assignee and a collaborator)
    if (
      userTaskRelation.labels.length < 1 ||
      (userTaskRelation.labels.length === 1 && userTaskRelation.labels[0] === LABELS.COLLABORATOR_TO)
    ) {
      userTaskRelation.batchDisable(batch)
    } else {
      userTaskRelation.batchRemoveLabels(batch, [LABELS.COLLABORATOR_TO])
    }

    if (
      taskUserRelation.labels.length < 1 ||
      (taskUserRelation.labels.length === 1 && taskUserRelation.labels[0] === LABELS.HAS_COLLABORATOR)
    ) {
      taskUserRelation.batchDisable(batch)
    } else {
      taskUserRelation.batchRemoveLabels(batch, [LABELS.HAS_COLLABORATOR])
    }

    await batch.commit()
  }

  public async addCollaborators(collaborators: Person[], item: Item, project: Project, currentUser: Person) {
    for (const c of collaborators) {
      await this.addCollaborator(c, item, project, currentUser)
    }
  }

  private async addCollaborator(c: Person, i: Item, p: Project, currentUser: Person) {
    const [collaborator, item, project] = await Promise.all([Person.get(c.uid), Item.get(i.uid), Project.get(p.uid)])

    const batch = this.db.firestore.batch()

    // Add the collaborator to the project as a normal user
    const collaboratorProjectLabels = [LABELS.DEFAULT]
    collaborator.batchAdd(batch, project, collaboratorProjectLabels)
    // @ts-ignore
    project.batchAdd(batch, collaborator, Relation.invertLabels(collaboratorProjectLabels))

    // LEGACY: Get the task
    const task = await item.getTask()

    // LEGACY: Add the collaborator to the task
    const collaboratorTaskLabels = [LABELS.COLLABORATOR_TO]
    collaborator.batchAdd(batch, task, collaboratorTaskLabels)
    // @ts-ignore
    task.batchAdd(batch, collaborator, Relation.invertLabels(collaboratorTaskLabels))

    // LEGACY: Update the collaborators attribute in the task document
    batch.set(
      task.ref!,
      {
        collaborators: [...(task.collaboratorRefs || []), collaborator.ref],
      },
      { merge: true }
    )

    // Update aggregate data for the item with collaborator information
    batch.set(
      item.ref!,
      {
        aggregateData: {
          taskCollaboratorUids: [...(item.aggregateData.taskCollaboratorUids || []), collaborator.uid],
          taskCollaboratorNames: [...(item.aggregateData.taskCollaboratorNames || []), collaborator.name],
        },
      },
      { merge: true }
    )

    this.timelineService.batchCollaboratorAdded(batch, currentUser, item, task, c)

    await batch.commit()
  }
}
