import { Clipboard } from "@angular/cdk/clipboard"
import { DOCUMENT, NgFor, NgIf } from "@angular/common"
import { Component, Inject, Input, OnInit } from "@angular/core"
import { Invitation, ModelInCollection } from "@models/common"
import { InvitationService, SnackbarService } from "@services"
import { MomentModule } from "ngx-moment"
import { MatLegacyMenuModule } from "@angular/material/legacy-menu"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLineModule } from "@angular/material/core"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyListModule } from "@angular/material/legacy-list"
import { MatDividerModule } from "@angular/material/divider"

@Component({
  selector: "checkd-pending-invitations-list",
  templateUrl: "./pending-invitations-list.component.html",
  styleUrls: ["./pending-invitations-list.component.scss"],
  standalone: true,
  imports: [
    MatDividerModule,
    MatLegacyListModule,
    NgFor,
    MatIconModule,
    MatLineModule,
    NgIf,
    MatLegacyButtonModule,
    MatLegacyMenuModule,
    MomentModule,
  ],
})
export class PendingInvitationsListComponent implements OnInit {
  @Input() canRemoveInvitations: boolean = false
  @Input() invitations: Invitation[]

  constructor(
    private invitationService: InvitationService,

    @Inject(DOCUMENT) private document: Document,
    private clipboard: Clipboard,
    private snackbar: SnackbarService
  ) {}

  ngOnInit(): void {}

  trackByUid(index: number, model: ModelInCollection): string {
    return model.uid!
  }

  getIcon(invitation: Invitation): "sms" | "email" {
    return invitation.deliveryMethod === "sms" ? "sms" : "email"
  }

  getTarget(invitation: Invitation): string {
    // @ts-ignore
    return invitation.deliveryMethod === "sms" ? invitation.targetPhoneNumber : invitation.targetEmail
  }

  async removePendingInvitationClicked(invitation: Invitation) {
    await this.invitationService.removeInvitationDialog(invitation)
  }

  public copyInvitationLink(invitation: Invitation) {
    const url = `${this.document.location.origin}/${invitation.ref!.path}`
    const success = this.clipboard.copy(url)

    if (success) {
      this.snackbar.showMessage("The invitation was copied to your clipboard", 5000)
    } else {
      this.snackbar.showMessage("There was an error copying the invitation to your clipboard", 5000)
    }
  }
}
