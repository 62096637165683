import { inject, Injectable } from "@angular/core"
import { finalize, map, shareReplay, tap } from "rxjs/operators"

import * as Sentry from "@sentry/angular"

import {
  applyActionCode,
  Auth,
  authState,
  checkActionCode,
  confirmPasswordReset,
  linkWithPopup,
  OAuthProvider,
  signInWithCustomToken,
  signInWithEmailAndPassword as _signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  unlink,
  verifyPasswordResetCode,
  type UserCredential,
  sendPasswordResetEmail,
  sendEmailVerification,
} from "@angular/fire/auth"

type AuthSuccess = {
  success: true
  data: UserCredential
}

type AuthError = {
  success: false
  error: {
    code: string
    message: string
  }
}

@Injectable({
  providedIn: "root",
})
export class FirebaseAuthService {
  readonly auth = inject(Auth)

  readonly authState$ = authState(this.auth).pipe(
    tap((user) => Sentry.setUser(user?.uid ? { id: user!.uid } : null)),
    finalize(() => Sentry.setUser(null)),
    shareReplay({ refCount: true, bufferSize: 1 }),
  )

  // private backChannelLogoutListener$: Observable<string | null> = this.afAuth.authState.pipe(
  //   switchMap(user => {
  //     if (!user) {
  //       return of(null)
  //     }
  //
  //     return this.afAuth.idTokenResult.pipe(
  //       map(idTokenResult => {
  //         const sid: string = idTokenResult?.claims?.['firebase']?.['sign_in_attributes']?.['sid']
  //         if (!sid) {
  //           console.error('SID not found in claims')
  //
  //           return of(null)
  //         }
  //
  //         return of(sid)
  //       }))
  //   }),
  //   switchMap(sid => {
  //     if (!sid) {
  //       return of(null)
  //     }
  //
  //     return this.aFirestore.firestore.collection('sessions').doc(sid)
  //   })
  // )

  /**
   * Handles the Microsoft authentication flow
   *
   * If the user already exists in Firebase Auth, they will be signed in
   * If the user doesn't exist in Firebase Auth, a new account will be created automatically
   *
   * IMPORTANT: This method only handles the Firebase Auth process, not the Firestore user document creation
   * This method handles all error cases (popup blocked, cancelled requests, unauthorized emails)
   */

  async handleMicrosoftAuth(): Promise<AuthSuccess | AuthError> {
    try {
      const userCredential = await signInWithPopup(this.auth, new OAuthProvider("microsoft.com"))
      return { success: true, data: userCredential }
    } catch (error) {
      console.error("Microsoft authentication error:", error)

      return this.getUserFriendlyErrorResponse(error)
    }
  }

  private getUserFriendlyErrorResponse(error: any): AuthError {
    const errorResponse = {
      code: error.code || "unknown",
      message: "An error occurred during authentication.",
    }

    // Map common Firebase Auth error codes to user-friendly messages
    switch (error.code) {
      case "auth/wrong-password":
      case "auth/invalid-credential":
        errorResponse.message = "Invalid password. Please try again."
        break
      case "auth/user-not-found":
        errorResponse.message = "User not found. Please check your email address."
        break
      case "auth/popup-blocked":
        errorResponse.message = "Authentication popup was blocked by your browser. Please allow popups for this site."
        break
      case "auth/cancelled-popup-request":
        errorResponse.message = "Authentication was cancelled. Please try again."
        break
      case "auth/email-already-in-use":
        errorResponse.message = "This email is already in use. Please try signing in instead."
        break
      case "auth/invalid-email":
        errorResponse.message = "Invalid email address. Please check and try again."
        break
      case "auth/weak-password":
        errorResponse.message = "Password is too weak. Please use a stronger password."
        break
      case "auth/too-many-requests":
        errorResponse.message = "Too many unsuccessful login attempts. Please try again later."
        break
      case "auth/network-request-failed":
        errorResponse.message = "Network error. Please check your internet connection and try again."
        break
    }

    // Special case for email domains that are not authorized to sign in
    // This error will be thrown from a blocking auth cloud function
    if (error.message.includes("unauthorized_client")) {
      errorResponse.message =
        "Access denied: this is a beta feature enabled for selected customers only. Please contact support for more information."
    }

    return { success: false, error: errorResponse }
  }

  async signInWithEmailAndPassword(email: string, password: string): Promise<AuthSuccess | AuthError> {
    try {
      const userCredential = await _signInWithEmailAndPassword(this.auth, email, password)
      return { success: true, data: userCredential }
    } catch (error) {
      console.error("email/password authentication error:", error)

      return this.getUserFriendlyErrorResponse(error)
    }
  }

  /**
   * @deprecated Use signInWithEmailAndPassword instead
   */
  login(email: string, password: string) {
    return _signInWithEmailAndPassword(this.auth, email, password)
  }

  loginWithCustomToken(token: string) {
    return signInWithCustomToken(this.auth, token)
  }

  logout() {
    return signOut(this.auth)
  }

  verifyPasswordResetCode(code: string) {
    return verifyPasswordResetCode(this.auth, code)
  }

  checkActionCode(code: string) {
    return checkActionCode(this.auth, code)
  }

  applyActionCode(code: string) {
    return applyActionCode(this.auth, code)
  }

  resetPassword(code: string, newPassword: string) {
    return confirmPasswordReset(this.auth, code, newPassword)
  }

  sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(this.auth, email)
  }

  sendEmailVerificationEmail() {
    const user = this.auth.currentUser

    if (user) {
      return sendEmailVerification(user)
    }

    return Promise.reject("current user was null")
  }

  isLoggedIn() {
    return this.authState$.pipe(map((user) => !!user))
  }

  public async linkAccount(providerId = "microsoft.com") {
    const provider = new OAuthProvider(providerId)
    const currentUser = this.auth.currentUser

    return linkWithPopup(currentUser!, provider)
  }

  public async unlinkAccount(providerId = "microsoft.com") {
    const currentUser = this.auth.currentUser

    return unlink(currentUser!, providerId)
  }
}
