import { BaseModel } from "./base-model"
import { InvitationData, InvitationStatus, InvitationType } from "./invitation.interface"
import { LoginProviders } from "@models/common/company.interface"

export class Invitation extends BaseModel<InvitationData> {
  public static override COLLECTION: string = "invitations"

  get deliveryMethod() {
    return this.data.deliveryMethod || "email"
  }

  get invitationType() {
    return this.data.invitationType || InvitationType.NONE
  }
  get status() {
    return this.data.status || InvitationStatus.NONE
  }
  get active() {
    return this.data.active === null ? true : this.data.active
  }

  /**
   * The email address of the user who is being invited
   */
  get targetEmail() {
    return this.data.targetEmail
  }

  /**
   * The user ID of the user who sent the invitation
   */
  get sourceUid() {
    return this.data.sourceUid
  }
  get targetPhoneNumber() {
    return this.data.targetPhoneNumber || ""
  }

  get loginMethods() {
    return this.data && this.data.loginMethods ? this.data.loginMethods : [LoginProviders.USERNAME_PASSWORD]
  }

  updateStatus(status: InvitationStatus) {
    return this.update({ status })
  }
}
