import { Component, Inject, OnInit } from "@angular/core"
import { MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { MatIconModule } from "@angular/material/icon"
import { ClipboardModule } from "ngx-clipboard"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgFor, NgIf } from "@angular/common"

interface IDialogData {
  title: string
  link: string
  message: string[]
  linkType?: LinkType
}

export enum LinkType {
  OPEN_IN_NEW_WINDOW = "OPEN_IN_NEW_WINDOW",
  DOWNLOAD = "DOWNLOAD",
}

@Component({
  selector: "checkd-link-sharing-dialog",
  templateUrl: "./link-sharing-dialog.component.html",
  styleUrls: ["./link-sharing-dialog.component.scss"],
  standalone: true,
  imports: [
    MatDialogModule,
    NgFor,
    NgIf,
    MatLegacyButtonModule,
    ClipboardModule,
    MatIconModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
  ],
})
export class LinkSharingDialogComponent implements OnInit {
  public isCopied: boolean
  public linkType: LinkType = LinkType.DOWNLOAD

  constructor(private snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    if (data.linkType) {
      this.linkType = data.linkType
    }
  }

  ngOnInit(): void {}

  showMessage() {
    this.snackBar.open("Link copied to clipboard.", "", {
      duration: 2000,
    })
  }
}
