import firebase from "firebase/compat/app"
import "firebase/compat/firestore"
import { environment } from "src/environments/environment"

let firebaseApp: firebase.app.App

// Initialize immediately but guard against multiple inits
try {
  firebaseApp = firebase.initializeApp(environment.firebase)
} catch (err) {
  // If already initialized, catch the error
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack)
  }

  firebaseApp = firebase.app()
}

// Export both the app and DB
export const DB = firebaseApp.firestore()
