import { Injectable } from "@angular/core"
import { AngularFirestore } from "@angular/fire/compat/firestore"
import {
  COLLECTIONS,
  Company,
  DB,
  EmailWithRoles,
  Invitation,
  InvitationData,
  InvitationStatus,
  InvitationType,
  LABELS,
  ModelInCollection,
  Person,
  Project,
  Relation,
  Task,
  TimelineType,
} from "@models/common"
import { IFormsLibraryMemberCompanyEmailInvitation } from "@models/common/forms-library-member-company-invitation.interface"
import { TimelineService } from "@services/timeline.service"
import { UserService } from "@services/user.service"
import { Timestamp } from "firebase/firestore"
import { firstValueFrom, Observable, timeout } from "rxjs"
import { map } from "rxjs/operators"
import { DialogService } from "../dialogs/dialog.service"
import { PendingInvitationRemovalDialogComponent } from "../invitations/dialogs/pending-invitation-removal-dialog/pending-invitation-removal-dialog.component"
import { ModelService } from "./model.service"
import { RelationService } from "./relation.service"
import { CloudFunctionsService } from "@services/cloud-functions.service"
import { AnalyticsService } from "@services/analytics.service"
import { SnackbarService } from "@services/snackbar.service"
import { SmsInviteWithRole } from "@models/common/invitation.interface"

@Injectable({
  providedIn: "root",
})
export class InvitationService {
  constructor(
    private db: AngularFirestore,
    private modelService: ModelService,
    private relationService: RelationService,
    private timelineService: TimelineService,
    private cloudFunctionsService: CloudFunctionsService,
    private analyticsService: AnalyticsService,
    private snackbarService: SnackbarService,
    private dialogService: DialogService,
    private userService: UserService,
    private afs: AngularFirestore
  ) {}

  listenToUid(invitationUid: string): Observable<Invitation> {
    return this.modelService.listenTo(COLLECTIONS.INVITATIONS, invitationUid) as Observable<Invitation>
  }

  listenToPendingInvitationsFor(model: ModelInCollection): Observable<Invitation[]> {
    return this.relationService.listenToTargets(model, COLLECTIONS.INVITATIONS).pipe(
      map((invitations) =>
        invitations.filter((invitation) => invitation.active !== false && invitation.status === InvitationStatus.PENDING)
      ),
      map((invitations) => invitations.sort((a: Invitation, b: Invitation) => (b.createdAt || 0) - (a.createdAt || 0)))
    )
  }

  listenToCompany(invitation: Invitation): Observable<Company> {
    return this.relationService
      .listenToTargets(invitation, COLLECTIONS.COMPANIES)
      .pipe(map((companies) => companies[0])) as Observable<Company>
  }

  listenToProjects(invitation: Invitation): Observable<Project[]> {
    return this.relationService.listenToTargets(invitation, COLLECTIONS.PROJECTS) as Observable<Project[]>
  }

  private createInvitationData(
    emailWithRoles: EmailWithRoles,
    inviterUid: string,
    invitationType: InvitationType
  ): Partial<InvitationData> {
    return {
      sourceUid: inviterUid,
      invitationType,
      targetEmail: emailWithRoles.email,
      status: InvitationStatus.PENDING,
      triggerEmail: true,
      active: true,
      deliveryMethod: "email",
    }
  }

  private createSmsInvitationData(invite: SmsInviteWithRole, inviterUid: string, invitationType: InvitationType): Partial<InvitationData> {
    return {
      targetPhoneNumber: invite.phone,
      message: invite.message,
      sourceUid: inviterUid,
      invitationType,
      status: InvitationStatus.PENDING,
      active: true,
      deliveryMethod: "sms",
    }
  }

  createCompanyEmailInvitationData(emailWithRoles: EmailWithRoles, inviterUid: string, company: Company): InvitationData {
    return {
      ...this.createInvitationData(emailWithRoles, inviterUid, InvitationType.COMPANY),
      resources: [company.ref],
      labels: [...emailWithRoles.roles, LABELS.HAS_MAIN_COMPANY],
      loginMethods: company.loginMethods,
    } as InvitationData
  }

  createCompanySmsInvitationData(invite: SmsInviteWithRole, inviterUid: string, company: Company): InvitationData {
    return {
      ...this.createSmsInvitationData(invite, inviterUid, InvitationType.COMPANY),
      resources: [company.ref],
      labels: [invite.role, LABELS.HAS_MAIN_COMPANY],
    } as InvitationData
  }

  createProjectEmailInvitationData(email: EmailWithRoles, inviterUid: string, project: Project) {
    return {
      ...this.createInvitationData(email, inviterUid, InvitationType.PROJECT),
      resources: [project.ref],
      labels: email.roles,
    } as InvitationData
  }

  async createProjectEmailInvitations(emailsWithRoles: EmailWithRoles[], inviterUid: string, project: Project): Promise<Invitation[]> {
    const allInvitations = emailsWithRoles.map((emailWithRoles) =>
      this.createProjectEmailInvitationData(emailWithRoles, inviterUid, project)
    )

    const batch = this.db.firestore.batch()

    const invitations = []

    for (const invitationData of allInvitations) {
      const invitationLabels = invitationData.labels
      delete invitationData.labels

      const invitation = Invitation.batchCreate(batch, Invitation, invitationData)
      // @ts-ignore
      invitation.batchAdd(batch, project, invitationLabels)
      // @ts-ignore
      project.batchAdd(batch, invitation, Relation.invertLabels(invitationLabels))
      invitations.push(invitation)
    }

    await batch.commit()

    return invitations
  }

  private async createCompanyEmailInvitation(invitationData: InvitationData, company: Company): Promise<Invitation> {
    const batch = this.db.firestore.batch()
    const invitationLabels = invitationData.labels
    delete invitationData.labels

    const invitation = Invitation.batchCreate(batch, Invitation, invitationData)
    // @ts-ignore
    invitation.batchAdd(batch, company, invitationLabels)
    // @ts-ignore
    company.batchAdd(batch, invitation, Relation.invertLabels(invitationLabels))
    await batch.commit()

    return invitation
  }

  async createCompanyEmailInvitations(emailsWithRoles: EmailWithRoles[], inviterUid: string, company: Company): Promise<Invitation[]> {
    const invitationsData = emailsWithRoles.map((emailWithRoles) =>
      this.createCompanyEmailInvitationData(emailWithRoles, inviterUid, company)
    )

    const invitations = await Promise.all(
      invitationsData.map((invitationData) => this.createCompanyEmailInvitation(invitationData, company))
    )

    return invitations
  }

  async createCompanySmsInvitation(invite: SmsInviteWithRole, inviterUid: string, company: Company): Promise<Invitation> {
    const invitationData = this.createCompanySmsInvitationData(invite, inviterUid, company)
    const invitation = await this.createCompanyEmailInvitation(invitationData, company)

    return invitation
  }

  async acceptInvitation(invitation: Invitation, person: Person) {
    try {
      await this.cloudFunctionsService
        .acceptInvitation({
          userUid: person.uid,
          invitationUid: invitation.uid,
        })
        .toPromise()

      switch (invitation.invitationType) {
        case InvitationType.COMPANY:
          return this.analyticsService.logTimelineEvent(TimelineType.COMPANY_INVITATION_ACCEPTED)

        // TODO Remove TASK invitation type when we get rid of them
        case InvitationType.TASK:
          return this.analyticsService.logTimelineEvent(TimelineType.PROJECT_INVITATION_ACCEPTED)
        case InvitationType.PROJECT:
          return this.analyticsService.logTimelineEvent(TimelineType.PROJECT_INVITATION_ACCEPTED)
        case InvitationType.PROJECT_AND_TASK:
          return this.analyticsService.logTimelineEvent(TimelineType.PROJECT_INVITATION_ACCEPTED)
        default:
          return
      }
    } catch (error) {
      this.snackbarService.showMessage(error.message)
    }
  }

  /*
  Shows a dialog asking the user to confirm whether they want to remove the invitation, and disables the invitation if confirm is clicked.
  Works/displays the correct text with both company and project invitations and email and sms invitations
  (the data is inferred from the invitation object)
   */
  async removeInvitationDialog(invitation: Invitation) {
    const result = await this.dialogService.openDialog(PendingInvitationRemovalDialogComponent, {
      data: { invitation },
    })

    if (!result || result !== "userConfirmedRemovePendingInvitation") {
      return
    }

    const target = invitation.deliveryMethod === "sms" ? invitation.targetPhoneNumber : invitation.targetEmail

    await invitation.disable()
    this.snackbarService.showMessage(`Invitation for ${target} has been removed`)
  }

  async createFormsLibraryMemberCompanyInvitation(targetEmail: string) {
    const [currentUser, currentCompany] = await Promise.all([
      firstValueFrom(this.userService.currentUser$.pipe(timeout(5000))),
      firstValueFrom(this.userService.currentCompany$.pipe(timeout(5000))),
    ])

    if (!currentUser) {
      throw new Error("Could not fetch the current user when trying to create the invitation")
    }
    if (!currentCompany) {
      throw new Error("Could not fetch the current company when trying to create the invitation")
    }

    const invitation: IFormsLibraryMemberCompanyEmailInvitation = {
      invitationType: "FORMS_LIBRARY_MEMBER_COMPANY_INVITATION_EMAIL",
      createdAt: Timestamp.now(),
      disabled: null,
      invitedByPublisherCompany: {
        uid: currentCompany.uid,
        name: currentCompany.name,
      },
      invitedByUser: {
        uid: currentUser.uid,
        name: currentUser.name,
        email: currentUser.email,
      },
      status: "PENDING",
      targetEmail,
      triggerEmail: true,
    }

    const invitationRef = DB.collection(COLLECTIONS.INVITATIONS).doc()
    await invitationRef.set(invitation)

    return invitationRef
  }
}
