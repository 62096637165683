import { Component, Inject } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { IAnonymousReportDialogData } from "./anonymous-report-dialog.interface"
import { AvatarModule } from "ngx-avatars"
import { NgIf } from "@angular/common"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatIconModule } from "@angular/material/icon"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-anonymous-report-dialog",
  templateUrl: "./anonymous-report-dialog.component.html",
  styleUrls: ["./anonymous-report-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, FlexModule, ExtendedModule, MatIconModule, MatLegacyButtonModule, NgIf, AvatarModule],
})
export class AnonymousReportDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<AnonymousReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IAnonymousReportDialogData
  ) {}
}
