import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core"
import { DocumentReference } from "@angular/fire/compat/firestore"
import { Notification } from "@models/common/notification"
import { FilestackService } from "@services/filestack.service"
import { NotificationType } from "@models/common/notification-type"
import { MomentModule } from "ngx-moment"
import { AvatarModule } from "ngx-avatars"
import { MatIconModule } from "@angular/material/icon"
import { ExtendedModule } from "@angular/flex-layout/extended"
import { NgClass, NgSwitch, NgSwitchCase, NgIf } from "@angular/common"
import { MatRippleModule } from "@angular/material/core"
import { FlexModule } from "@angular/flex-layout/flex"

@Component({
  selector: "checkd-notification-row-item",
  templateUrl: "./notification-row-item.component.html",
  styleUrls: ["./notification-row-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FlexModule, MatRippleModule, NgClass, ExtendedModule, NgSwitch, NgSwitchCase, MatIconModule, AvatarModule, NgIf, MomentModule],
})
export class NotificationRowItemComponent implements OnInit {
  @Input() notification: Notification
  @Output() notificationClicked = new EventEmitter<Notification>()

  notificationType = NotificationType

  constructor(private filestackService: FilestackService) {
    return
  }

  get taskName(): string {
    try {
      return this.notification.notificationData.itemData!.name!
    } catch (e) {
      return ""
    }
  }

  get taskNumber(): string {
    try {
      const num = this.notification.notificationData.itemData!.number

      if (num === undefined) {
        return ""
      }

      return `#${num}`
    } catch (e) {
      return ""
    }
  }

  get assignerName(): string {
    return (
      this.notification?.notificationData?.assignerData?.name ||
      this.notification?.notificationData?.assignerData?.fullName ||
      "a CHECKD user"
    )
  }

  get assigneeName(): string {
    return (
      this.notification?.notificationData?.assigneeData?.name ||
      this.notification?.notificationData?.assigneeData?.fullName ||
      "a CHECKD user"
    )
  }

  get projectName(): string {
    try {
      return this.notification.notificationData.projectData!.name
    } catch (e) {
      return ""
    }
  }

  get archiverName(): string {
    try {
      return this.notification.notificationData["archiverData"].fullName
    } catch (e) {
      return ""
    }
  }

  get timelineItemNumber(): string {
    try {
      const num = this.notification.notificationData.timelineData!.currentData!.item!.number

      return `#${num} `
    } catch (e) {
      return ""
    }
  }

  get timelineCreatorName(): string {
    try {
      return this.notification.notificationData.timelineCreatorData!.fullName!
    } catch (e) {
      return ""
    }
  }

  get timelineItemName(): string {
    try {
      return this.notification.notificationData.timelineData!.currentData!.item!.name!
    } catch (e) {
      return ""
    }
  }

  get hasDrawingName(): boolean {
    return Boolean(this.drawingName)
  }

  get drawingName(): string {
    try {
      return this.notification.notificationData.itemData!.aggregateData!.drawingName!
    } catch (e) {
      return ""
    }
  }

  ngOnInit() {
    return
  }

  // @ts-ignore
  get avatarThumbnailUrl() {
    try {
      let url

      switch (this.notification.notificationType) {
        case NotificationType.TASK_FIXED:
        case NotificationType.TASK_REJECTED:
          url = this.notification.notificationData.assigneeData!.image!.trim()
          break
        case NotificationType.ITEM_CHAT_MESSAGE_NEW:
          url = this.notification.notificationData.timelineCreatorData!.image!.trim()
          break
        case NotificationType.PROJECT_ARCHIVED:
          url = this.notification.notificationData["archiverData"].image.trim()
          break
        default:
          url = this.notification.notificationData.assignerData!.image!.trim()
          break
      }

      if (url) {
        return this.filestackService.getThumbnailUrl(url, 30, false)
      }
    } catch (e) {
      return ""
    }
  }

  get avatarFallbackName(): string {
    try {
      switch (this.notification.notificationType) {
        case NotificationType.TASK_FIXED:
        case NotificationType.TASK_REJECTED:
          // @ts-ignore
          return this.notification.notificationData.assigneeData.fullName
        case NotificationType.ITEM_CHAT_MESSAGE_NEW:
          // @ts-ignore
          return this.notification.notificationData.timelineCreatorData.fullName
        case NotificationType.PROJECT_ARCHIVED:
          return this.notification.notificationData["archiverData"].fullName
        default:
          // @ts-ignore
          return this.notification.notificationData.assignerData.fullName
      }
    } catch (e) {
      return "a CHECKD user"
    }
  }

  async rowClicked() {
    if (!this.notification.isOpened) {
      await this.notification.markAsRead()
    }

    const ref: DocumentReference = this.notification.target as DocumentReference
    if (!ref) {
      return
    }

    this.notificationClicked.emit(this.notification)
  }

  get collaboratorName(): string {
    try {
      return this.notification.notificationData["collaboratorData"].fullName
    } catch (e) {
      return "A CHECKD user"
    }
  }
}
