import { combineLatest as observableCombineLatest, of as observableOf, Observable, EMPTY } from "rxjs"
import { catchError, map, mergeMap, switchMap, tap, filter } from "rxjs/operators"
import { Injectable } from "@angular/core"
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore"
import { BaseModel, Relation, RelationData, ModelInCollection } from "@models/common"
import { createNewModelInstance } from "@models/common/utilities"
import firebase from "firebase/compat/app"
import firestore = firebase.firestore

@Injectable({
  providedIn: "root",
})
export class RelationService {
  constructor(private db: AngularFirestore) {}

  listenToRelations<M extends ModelInCollection>(from: M, collectionName: string, withLabels: string[] = []): Observable<Relation[]> {
    return (
      this.db
        // @ts-ignore
        .doc(from.ref.path)
        .collection(collectionName, (ref) => ref.where("disabled", "==", false))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            return withLabels
              ? snaps.filter((snap) => {
                  const relationData = snap.payload.doc.data() as RelationData
                  for (const label of withLabels) {
                    if (!relationData.labels.includes(label)) return false
                  }
                  return true
                })
              : snaps
          }),
          map((snaps) => {
            return snaps.map((snap) => new Relation(snap.payload.doc.data() as RelationData, snap.payload.doc.id, snap.payload.doc.ref))
          }),
          catchError((err, caught) => {
            console.error(err)
            return caught
          })
        )
    )
  }

  relationsToTargetListener(collectionName: string, relations: any[]) {
    return this.combineLatestOrEmptyList(
      relations.map((rel) => {
        return this.db
          .doc(rel.targetPath)
          .snapshotChanges()
          .pipe(
            // NB This is a fallback for old relation documents with missing 'targetPath' properties
            // TODO Fix old relation documents, then remove this fallback
            catchError((err, caught) => this.db.collection(collectionName).doc(rel.link).snapshotChanges())
          )
      })
    ).pipe(
      // @ts-ignore
      map((snaps: any[]) =>
        snaps.map((snap) => createNewModelInstance(collectionName, snap.payload.data(), snap.payload.id, snap.payload.ref))
      )
    )
  }

  relationToTargetListener(collectionName: string, relation: Relation) {
    return (
      this.db
        // @ts-ignore
        .doc(relation.targetPath)
        .snapshotChanges()
        .pipe(
          // NB This is a fallback for old relation documents with missing 'targetPath' properties
          // TODO Fix old relation documents, then remove this fallback
          catchError((err, caught) => this.db.collection(collectionName).doc(relation.link).snapshotChanges())
        )
        .pipe(map((snap) => createNewModelInstance(collectionName, snap.payload.data(), snap.payload.id, snap.payload.ref)))
    )
  }

  listenToTargets<M extends ModelInCollection>(from: M, collectionName: string, withLabels: string[] = []) {
    return this.listenToRelations(from, collectionName, withLabels).pipe(
      map((relations) =>
        relations.map((rel) => {
          return (
            this.db
              // @ts-ignore
              .doc(rel.targetPath)
              .snapshotChanges()
              .pipe(
                // NB This is a fallback for old relation documents with missing 'targetPath' properties
                // TODO Fix old relation documents, then remove this fallback
                catchError((err, caught) => this.db.collection(collectionName).doc(rel.link).snapshotChanges())
              )
          )
        })
      ),
      mergeMap((snaps) => this.combineLatestOrEmptyList(snaps)),
      map((snaps: any[]) =>
        snaps.map((snap) => createNewModelInstance(collectionName, snap.payload.data(), snap.payload.id, snap.payload.ref))
      ),
      map((models) => models.filter((model) => model && !model.disabled))
    )
  }

  listenToDisabledRelations<M extends ModelInCollection>(
    from: M,
    collectionName: string,
    withLabels: string[] = []
  ): Observable<Relation[]> {
    return (
      this.db
        // @ts-ignore
        .doc(from.ref.path)
        .collection(collectionName, (ref) => ref.where("disabled", "==", true))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            return withLabels
              ? snaps.filter((snap) => {
                  const relationData = snap.payload.doc.data() as RelationData
                  for (const label of withLabels) {
                    if (!relationData.labels.includes(label)) return false
                  }
                  return true
                })
              : snaps
          }),
          map((snaps) => {
            return snaps.map((snap) => new Relation(snap.payload.doc.data() as RelationData, snap.payload.doc.id, snap.payload.doc.ref))
          }),
          catchError((err, caught) => {
            console.error(err)
            return caught
          })
        )
    )
  }

  //TODO: Filter out disabled
  listenToArchivedRelations<M extends ModelInCollection>(
    from: M,
    collectionName: string,
    withLabels: string[] = []
  ): Observable<Relation[]> {
    return (
      this.db
        // @ts-ignore
        .doc(from.ref.path)
        .collection(collectionName, (ref) => ref.where("archived", "==", true))
        .snapshotChanges()
        .pipe(
          map((snaps) => {
            return withLabels
              ? snaps.filter((snap) => {
                  const relationData = snap.payload.doc.data() as RelationData
                  for (const label of withLabels) {
                    if (!relationData.labels.includes(label)) {
                      return false
                    }
                  }
                  return true
                })
              : snaps
          }),
          map((snaps) => {
            return snaps.map((snap) => new Relation(snap.payload.doc.data() as RelationData, snap.payload.doc.id, snap.payload.doc.ref))
          }),
          catchError((err, caught) => {
            console.error(err)
            return caught
          })
        )
    )
  }

  listenToDisabledTargets<M extends ModelInCollection>(from: M, collectionName: string, withLabels: string[] = []) {
    return this.listenToDisabledRelations(from, collectionName, withLabels).pipe(
      map((relations) =>
        relations.map((rel) => {
          return (
            this.db
              // @ts-ignore
              .doc(rel.targetPath)
              .snapshotChanges()
              .pipe(
                // NB This is a fallback for old relation documents with missing 'targetPath' properties
                // TODO Fix old relation documents, then remove this fallback
                catchError((err, caught) => this.db.collection(collectionName).doc(rel.link).snapshotChanges())
              )
          )
        })
      ),
      mergeMap((snaps) => this.combineLatestOrEmptyList(snaps)),
      map((snaps: any[]) =>
        snaps.map((snap) => createNewModelInstance(collectionName, snap.payload.data(), snap.payload.id, snap.payload.ref))
      ),
      map((models) => models.filter((model) => model && !model.disabled))
    )
  }

  //TODO: Filter out disabled
  listenToArchivedTargets<M extends ModelInCollection>(from: M, collectionName: string, withLabels: string[] = []) {
    return this.listenToArchivedRelations(from, collectionName, withLabels).pipe(
      map((relations) =>
        relations.map((rel) => {
          return (
            this.db
              // @ts-ignore
              .doc(rel.targetPath)
              .snapshotChanges()
              .pipe(
                // NB This is a fallback for old relation documents with missing 'targetPath' properties
                // TODO Fix old relation documents, then remove this fallback
                catchError((err, caught) => this.db.collection(collectionName).doc(rel.link).snapshotChanges())
              )
          )
        })
      ),
      mergeMap((snaps) => this.combineLatestOrEmptyList(snaps)),
      map((snaps: any[]) =>
        snaps.map((snap) => createNewModelInstance(collectionName, snap.payload.data(), snap.payload.id, snap.payload.ref))
      ),
      map((models) => models.filter((model) => model && model.archived))
    )
  }

  listenToFirstTarget<M extends ModelInCollection>(from: M, collectionName: string, withLabels: string[] = []) {
    return this.listenToTargets(from, collectionName, withLabels).pipe(
      switchMap((targets) => (targets && targets.length > 0 ? observableOf(targets[0]) : EMPTY))
    )
  }

  combineLatestOrEmptyList<T>(snaps: T[]) {
    return snaps == null || snaps.length < 1 ? observableOf([]) : observableCombineLatest(snaps)
  }

  private invertRelation(source: string, relation: RelationData): RelationData {
    return Relation.invertRelation(source, relation)
  }

  getRelation<M1 extends ModelInCollection, M2 extends ModelInCollection>(source: M1, target: M2): Promise<Relation> {
    return Relation.get(source, target)
  }

  getRelations<M extends ModelInCollection>(from: M, collectionName: string, labels: string[] = []): Promise<Relation[]> {
    return Relation.getAll(from, collectionName, labels)
  }

  getTargets<M1 extends ModelInCollection, M2>(source: M1, collectionName: string, labels: string[] = []): Promise<M2[]> {
    return Relation.getAllTargets(source, collectionName, labels)
  }

  async getFirstTarget<M1 extends ModelInCollection, M2 extends ModelInCollection>(
    source: M1,
    collectionName: string,
    labels: string[] = []
  ): Promise<M2> {
    const targets = await Relation.getAllTargets(source, collectionName, labels)
    return targets.length > 0 ? targets[0] : null
  }

  async batchUpdateRelationDisabledState<M1 extends ModelInCollection, M2 extends ModelInCollection>(
    batch: firestore.WriteBatch,
    source: M1,
    target: M2,
    disabled: boolean,
    inverse = true
  ) {
    const [relation, inverseRelation] = await Promise.all([this.getRelation(source, target), this.getRelation(target, source)])

    // @ts-ignore
    batch.update(relation.ref, { disabled })
    if (inverse) {
      // @ts-ignore
      batch.update(inverseRelation.ref, { disabled })
    }
  }

  /**
   * @deprecated Use batchUpdateRelationDisabledState() instead
   */
  disableRelation(fromCollection: string, fromUid: string, toCollection: string, toUid: string, inverse: boolean = false) {
    this.setRelationDisabledStatus(fromCollection, fromUid, toCollection, toUid, true)
    if (inverse) {
      this.setRelationDisabledStatus(toCollection, toUid, fromCollection, fromUid, true)
    }
  }

  /**
   * @deprecated Use batchUpdateRelationDisabledState() instead
   */
  enableRelation(fromCollection: string, fromUid: string, toCollection: string, toUid: string, inverse: boolean = false) {
    this.setRelationDisabledStatus(fromCollection, fromUid, toCollection, toUid, false)
    if (inverse) this.setRelationDisabledStatus(toCollection, toUid, fromCollection, fromUid, false)
  }

  /**
   * @deprecated Use batchUpdateRelationDisabledState() instead
   */
  protected setRelationDisabledStatus(fromCollection: string, fromUid: string, toCollection: string, toUid: string, disabled: boolean) {
    this.db.firestore
      .collection(fromCollection)
      .doc(fromUid)
      .collection(toCollection)
      .where("link", "==", toUid)
      .get()
      .then((result) => {
        if (result.size > 0) {
          result.forEach((documentSnapshot) => {
            documentSnapshot.ref.update({ disabled: disabled })
          })
        }
      })
      .catch(this.handleError)
  }

  protected handleError(error: any) {
    console.error(error)
  }
}
