import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

@Component({
  selector: "app-legacy-template-edit-dialog",
  templateUrl: "./legacy-template-edit-dialog.component.html",
  styleUrls: ["./legacy-template-edit-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, NgIf, MatLegacyButtonModule],
})
export class LegacyTemplateEditDialogComponent implements OnInit {
  reportForm: UntypedFormGroup

  constructor(
    private dialogRef: MatDialogRef<LegacyTemplateEditDialogComponent>,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { reportName: string }
  ) {}

  ngOnInit() {
    this.reportForm = this.fb.group({
      title: [
        this.data.reportName || "",
        [
          Validators.required,
          Validators.pattern("^(?=\\s*\\S).*$"), // at least one non-whitespace character required
        ],
      ],
    })
  }

  get title() {
    return this.reportForm.get("title")
  }

  save() {
    const title: string = this.title!.value.trim()
    this.dialogRef.close(title)
  }

  cancel() {
    this.dialogRef.close(null)
  }
}
