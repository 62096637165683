import { Injectable } from "@angular/core"
import { environment } from "../../../environments/environment"
import { HttpClient, HttpParams } from "@angular/common/http"
import { Observable } from "rxjs"

export interface GeocodingQueryResult {
  status: string
  pluscode: any
  results: any[]
}

@Injectable({
  providedIn: "root",
})
export class GeocodingService {
  apiUrl: string = "https://maps.googleapis.com/maps/api/geocode/json"
  apiKey: string

  constructor(private http: HttpClient) {
    this.apiKey = environment.GOOGLE_MAPS_API_KEY
  }

  makeSearchParams(searchParams: any) {
    let params: HttpParams = new HttpParams()
    params = params.append("key", this.apiKey)
    for (const k of Object.keys(searchParams)) {
      params = params.append(k, searchParams[k])
    }
    return params
  }

  sendRequest(searchParams: HttpParams): Observable<GeocodingQueryResult> {
    return this.http.get(this.apiUrl, { params: searchParams }) as Observable<GeocodingQueryResult>
  }

  reverseLookup(address: string) {
    return this.sendRequest(
      this.makeSearchParams({
        address: address,
      })
    )
  }

  lookup(latitude: number, longitude: number) {
    return this.sendRequest(
      this.makeSearchParams({
        latlng: `${latitude},${longitude}`,
      })
    )
  }
}
