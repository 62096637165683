import { Directive, Input, OnChanges, OnInit, SimpleChange, EventEmitter, HostListener } from "@angular/core"
import { DrawingInterface, Item, DrawingAnnotation } from "../models/common"
import { LeafletService, XYBounds, XYCoord } from "../services"

import * as L from "leaflet"
import { Map, LatLng, LatLngBounds, Point, LeafletEvent, Marker } from "leaflet"
import { LeafletDirective, LeafletDirectiveWrapper } from "@asymmetrik/ngx-leaflet"

interface LeafletClickEvent {
  containerPoint: Point
  latlng: LatLng
  layerPoint: Point
  originalEvent?: any
  target?: any
  type?: string
  [propName: string]: any
}

@Directive({
  selector: "[leafletCheckdDrawing]",
  outputs: ["clickInsideBounds", "clickOutsideBounds", "addItemGesture"],
  standalone: true,
})
export class LeafletCheckdDrawingDirective {
  leafletDirective: LeafletDirective

  clickInsideBounds = new EventEmitter()
  clickOutsideBounds = new EventEmitter()
  addItemGesture = new EventEmitter()

  map: Map
  newMarkerAdded: Marker

  // To keep track of long press gesture
  private isLongPressing = false

  @HostListener("press", ["$event"]) onLongPress($event: any) {
    let event = this.convertEventCoordinatesToLeafletLatLng($event, this.map)
    if (this.isClickWithinBounds(event, this.map)) {
      this.isLongPressing = true
      this.map.dragging.disable()
      this.addMarker(event, this.map)
    }
  }

  // When touch moves on screen
  @HostListener("touchmove", ["$event"]) onTouchMove($event: TouchEvent) {
    if (!this.isLongPressing && !this.newMarkerAdded) return
    let event = this.convertEventCoordinatesToLeafletLatLng($event, this.map)
    this.makeMarkerFollowFinger(event, this.newMarkerAdded, this.map)
  }

  // When users leaves the surface after a touch event
  @HostListener("touchend", ["$event"]) onTouchEnd($event: TouchEvent) {
    if (this.newMarkerAdded && this.isLongPressing) {
      this.addItemGesture.emit(
        Object.assign(
          { layer: this.newMarkerAdded },
          this.normalizeClickCoords({ latlng: this.newMarkerAdded.getLatLng() } as LeafletClickEvent, this.map)
        )
      )

      // remove from map beacuse it will be automatically added when pushed to item layers array
      this.newMarkerAdded.removeFrom(this.map)
    }
    // @ts-ignore
    this.newMarkerAdded = null
    this.isLongPressing = false
    this.map.dragging.enable()
  }

  private icon = L.icon({
    iconSize: [25, 41],
    iconAnchor: [13, 41],
    iconUrl: "assets/marker-icon.png",
    shadowUrl: "assets/marker-shadow.png",
  })

  mousePressed: boolean = false

  constructor(protected leafletService: LeafletService, leafletDirective: LeafletDirective) {
    this.leafletDirective = leafletDirective
    this.initialize()
  }

  ngOnInit() {
    if (null != this.leafletDirective.getMap()) {
      this.map = this.leafletDirective.getMap()
    }
  }

  initialize() {
    this.leafletDirective.mapReady.subscribe((map: Map) => {
      //map.addEventListener('contextmenu', (event: LeafletClickEvent) => this.handleClick(event, map));
    })
  }

  handleClick(event: LeafletClickEvent, map: Map) {
    if (this.isClickWithinBounds(event, map)) {
      this.clickInsideBounds.emit(this.normalizeClickCoords(event, map))
      this.addMarker(event, map)
    } else {
      this.clickOutsideBounds.emit(this.normalizeClickCoords(event, map))
    }
  }

  // Returns true if the click coordinates are inside the bounds of
  // the drawing/map, false otherwise
  isClickWithinBounds(event: LeafletClickEvent, map: Map): boolean {
    let bounds = map.options.maxBounds as LatLngBounds
    return bounds.contains(event.latlng)
  }

  // Returns an object with x and y attributes between 0.0 and 1.0, e.g.:
  //
  // {x: 0.0, y: 0.0} is the upper left corner of the image/map
  // {x: 1.0, y: 1.0} is the bottom right corner of the image/map
  private normalizeClickCoords(event: LeafletClickEvent, map: Map): { x: number; y: number } {
    let bounds = map.options.maxBounds as LatLngBounds
    return this.leafletService.normalizeLatLngCoordinates(event.latlng, bounds.getNorthWest(), bounds.getSouthEast())
  }

  addMarker(event: LeafletClickEvent, map: Map) {
    let marker = new L.Marker(event.latlng, { icon: this.icon, draggable: true }).addTo(map)
    this.preventMarkerToGoOutOfBounds(marker, map)

    this.newMarkerAdded = marker
    //this.addItemGesture.emit(Object.assign({ layer: marker }, this.normalizeClickCoords(event, map)));
  }

  private makeMarkerFollowFinger(event: LeafletClickEvent, marker: Marker, map: Map) {
    marker.setLatLng(event.latlng)
  }

  private convertEventCoordinatesToLeafletLatLng(event: any, map: Map): LeafletClickEvent {
    let yCorrection = 40 // to place marker above finger
    let touchCenter: { x: number | null; y: number | null } = { x: null, y: null }
    if (event.center) {
      //hammer
      touchCenter.x = event.center.x
      touchCenter.y = event.center.y
    } else {
      // dom
      touchCenter.x = event.touches[0].clientX
      touchCenter.y = event.touches[0].clientY
    }

    // @ts-ignore
    let containerPoint = L.point(touchCenter.x, touchCenter.y - yCorrection)
    let layerPoint = map.containerPointToLayerPoint(containerPoint)
    let latlng = map.layerPointToLatLng(layerPoint)

    return {
      containerPoint: containerPoint,
      layerPoint: layerPoint,
      latlng: latlng,
    } as LeafletClickEvent
  }

  private preventMarkerToGoOutOfBounds(marker: Marker, map: Map) {
    let lastValidPosition = marker.getLatLng()
    let bounds = map.options.maxBounds as LatLngBounds
    marker.addEventListener("drag", (event) => {
      let newPosition = marker.getLatLng()
      if (bounds.contains(newPosition)) lastValidPosition = newPosition
      else marker.setLatLng(lastValidPosition)
    })
  }
}
