import { Component, EventEmitter, forwardRef, Inject, Output, ViewChild } from "@angular/core"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"

import { Drawing, ItemData, Person, Project, TaskData } from "@models/common"
import { BehaviorSubject } from "rxjs"
import { take } from "rxjs/operators"
import { CheckdDrawingComponent } from "../../../checkd-ui/checkd-drawing/checkd-drawing.component"
import { NgIf } from "@angular/common"
import { ItemCreationViewComponent } from "../../../items/item-creation-view/item-creation-view.component"

@Component({
  selector: "drawing-item-creation-dialog",
  templateUrl: "./drawing-item-creation-dialog.component.html",
  styleUrls: ["./drawing-item-creation-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, forwardRef(() => ItemCreationViewComponent), NgIf, CheckdDrawingComponent],
})
export class DrawingItemCreationDialogComponent {
  @Output() onSave = new EventEmitter<{ itemData: ItemData; taskData?: TaskData }>()
  @ViewChild("itemCreationView", { read: ItemCreationViewComponent, static: true }) itemCreationView: ItemCreationViewComponent

  project: Project
  drawing: Drawing
  positionX: number
  positionY: number

  currentUser: Person
  projectMembers: Person[] = []

  // @ts-ignore
  latestChosenItemPosition = new BehaviorSubject<{ positionX: number; positionY: number }>(null)

  constructor(
    private dialogRef: MatDialogRef<DrawingItemCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { drawing: Drawing; project: Project; projectMembers: Person[]; currentUser: Person }
  ) {
    this.project = data.project
    this.drawing = data.drawing
    this.currentUser = data.currentUser
    this.projectMembers = data.projectMembers
  }

  scaleImageCoords(image: { height: number; width: number }, width: number) {
    return {
      width,
      height: image.height * (width / image.width),
    }
  }

  save(itemAndTaskData: { itemData: ItemData; taskData?: TaskData }) {
    this.latestChosenItemPosition.pipe(take(1)).subscribe((coords) => {
      if (coords) {
        itemAndTaskData.itemData.positionX = coords.positionX
        itemAndTaskData.itemData.positionY = coords.positionY
      }

      this.onSave.emit(itemAndTaskData)
      this.dialogRef.close()
    })
  }

  itemCreated(event: { positionX: number; positionY: number }) {
    this.latestChosenItemPosition.next(event)
    this.updateItemCoordinates(event.positionX, event.positionY)
  }
  itemMoved(event: { positionX: number; positionY: number }) {
    this.latestChosenItemPosition.next(event)
    this.updateItemCoordinates(event.positionX, event.positionY)
  }

  updateItemCoordinates(positionX: number, positionY: number) {
    this.itemCreationView.updateItemCoordinates(positionX, positionY)
  }
}
