<div class="content-wrapper mat-elevation-z8 flex flex-column justify-content-center" [ngClass.gt-xs]="'large'">
  <checkd-items-table-action-bar
    [tooManyItemsForBulkActions]="tooManyItemsForBulkActions"
    [maxItemsForBulkActions]="maxItemsForBulkActions"
    [selectedItems]="selectedItems"
    [searchString]="filterValues.title"
    (searchStringChange)="onAcFilterChange('title', $event)"
    (itemActionClicked)="itemActionClicked.emit($event)"
    [hideNewItemButton]="hideNewItemButton"
    [hideItemsSelectedText]="hideItemsSelectedText"
    [hideItemActions]="hideItemsActions"
    [columnsEditable]="_defaultTableConfig?.columnsEditable"
    [(editableColumns)]="editableColumns"
    [projectIsArchivedOrLocked]="isProjectLockedOrArchived"
    [canUseItemFlow]="canUseItemFlow"
    [canExportToBcf]="canExportToBcf"
  >
  </checkd-items-table-action-bar>

  <div class="table-wrapper" [ngClass.gt-xs]="'large'">
    <table mat-table matSort [dataSource]="dataSource" [trackBy]="trackByItemRowByUid" *ngIf="isItemListReady; else itemIsNotReady">
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="masterToggle($event)"
            [checked]="selection.hasValue() && isAllSelected()"
            [indeterminate]="selection.hasValue() && !isAllSelected()"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="selectRow($event, row)" [checked]="checkRowSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="selectSearch">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <!-- Number and status -->
      <ng-container matColumnDef="{{ _allItemTableColumns.numberAndStatus.columnDef }}" sticky>
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">#</span></th>
        <td mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          <checkd-status-icon [type]="item.numberAndStatus.status" [number]="item.numberAndStatus.number"></checkd-status-icon>
          <!-- {{item.number}} -->
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.numberAndStatus.columnDef }}Search" sticky>
        <th mat-header-cell *matHeaderCellDef class="number-search">
          <checkd-ac-search
            #numberFilter
            (onSelection)="onAcFilterChange('number', $event.searchValue)"
            placeholder=""
            [width]="'30px'"
          ></checkd-ac-search>
        </th>
      </ng-container>

      <!-- item number -->
      <ng-container matColumnDef="{{ _allItemTableColumns.itemNumber.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">{{ _allItemTableColumns.itemNumber.columnName }}</span>
        </th>
        <td mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.numberAndStatus.number }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.itemNumber.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef class="number-search">
          <checkd-ac-search
            #itemNumberFilter
            (onSelection)="onAcFilterChange('number', $event.searchValue)"
            placeholder=""
            [width]="'30px'"
          ></checkd-ac-search>
        </th>
      </ng-container>

      <!-- Name -->
      <ng-container matColumnDef="{{ _allItemTableColumns.name.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Title of item</span>
        </th>
        <td class="item-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.name }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.name.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-ac-search
            #nameFilter
            [currentValue]="filterValues.title"
            (onSelection)="onAcFilterChange('title', $event.searchValue)"
            placeholder="Search"
          >
          </checkd-ac-search>
        </th>
      </ng-container>

      <!-- Description -->
      <ng-container matColumnDef="{{ _allItemTableColumns.itemDescription.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Description</span>
        </th>
        <td class="item-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.description }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.itemDescription.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-ac-search
            #descriptionFilter
            [currentValue]="filterValues.description"
            (onSelection)="onAcFilterChange('description', $event.searchValue)"
            placeholder="Search"
          >
          </checkd-ac-search>
        </th>
      </ng-container>

      <!-- Latest comment -->
      <ng-container matColumnDef="{{ _allItemTableColumns.latestMessage.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">{{ _allItemTableColumns.latestMessage.columnName }}</span>
        </th>
        <td class="item-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.latestMessage }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.latestMessage.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-ac-search
            #latestMessageFilter
            [currentValue]="filterValues.latestMessage"
            (onSelection)="onAcFilterChange('latestMessage', $event.searchValue)"
            placeholder="Search"
          >
          </checkd-ac-search>
        </th>
      </ng-container>

      <!-- Tags -->
      <ng-container matColumnDef="{{ _allItemTableColumns.tags.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Tags</span>
        </th>
        <td class="item-tags-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          <checkd-tags [tags]="item.tags"></checkd-tags>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.tags.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #tagsFilter
            (onSelection)="onAcFilterChange('tags', $event)"
            [values]="itemTags"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Item Creator -->
      <ng-container matColumnDef="{{ _allItemTableColumns.itemCreatorName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Created by</span>
        </th>
        <td class="item-creator-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">{{ item.itemCreatorName }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.itemCreatorName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #itemCreatorNameFilter
            (onSelection)="onAcFilterChange('itemCreatorNames', $event)"
            [values]="itemCreatorNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Task assigner -->
      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssignerName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Assigned by</span>
        </th>
        <td class="item-task-assigner-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.taskAssignerName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssignerName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef [ngStyle]="{ 'z-index': '99 !important' }">
          <checkd-multiselect
            #taskAssignerNameFilter
            (onSelection)="onAcFilterChange('taskAssignerNames', $event)"
            [values]="taskAssignerNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Collaborators -->
      <ng-container matColumnDef="{{ _allItemTableColumns.numberOfCollaborators.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header><span class="header-text">Collaborators</span></th>
        <td mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          <div class="flex justify-content-between align-items-center">
            <mat-icon
              *ngIf="item.currentUserIsTaskCollaborator"
              matTooltip="You are a collaborator on this item"
              svgIcon="notifications:collaborator-added"
            ></mat-icon>
            <span class="inline-block ml-auto">{{ item.numberOfCollaborators }}</span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.numberOfCollaborators.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef class="number-search">
          <!--          <checkd-ac-search #numberFilter (onSelection)="onAcFilterChange('number', $event.searchValue)"-->
          <!--                            placeholder="" [width]="'30px'" ></checkd-ac-search>-->
        </th>
      </ng-container>

      <!-- Task assigner company -->
      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssignerCompanyName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Assigner company</span>
        </th>
        <td class="item-task-assigner-company-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.taskAssignerCompanyName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssignerCompanyName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #taskAssignerCompanyNameFilter
            (onSelection)="onAcFilterChange('taskAssignerCompanyNames', $event)"
            [values]="taskAssignerCompanyNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Created at -->
      <ng-container matColumnDef="{{ _allItemTableColumns.createdAt.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Created at</span>
        </th>
        <td class="item-created-at-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ formatTimestamp(item.createdAt, true) }}
        </td>
        <td mat-footer-cell *matFooterCellDef>
          <span class="header-text">Created at</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.createdAt.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-daterange #createdAtFilter (onSelection)="onDateRangeSelection('createdAt', $event)"></checkd-daterange>
        </th>
      </ng-container>

      <!-- Updated at -->
      <ng-container matColumnDef="{{ _allItemTableColumns.updatedAt.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Updated at</span>
        </th>
        <td class="item-updated-at-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ formatTimestamp(item.updatedAt, true) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.updatedAt.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-daterange #updatedAtFilter (onSelection)="onDateRangeSelection('updatedAt', $event)"></checkd-daterange>
        </th>
      </ng-container>

      <!-- Due at -->
      <ng-container matColumnDef="{{ _allItemTableColumns.dueDate.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Due at</span>
        </th>
        <td class="item-due-at-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ formatTimestamp(item.dueDate, true) }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.dueDate.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-daterange #dueDateFilter (onSelection)="onDateRangeSelection('dueDate', $event)"></checkd-daterange>
        </th>
      </ng-container>

      <!-- Task assignee -->
      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssigneeName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Assigned to</span>
        </th>
        <td class="item-task-assignee-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">
          {{ item.taskAssigneeName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.taskAssigneeName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #taskAssigneeNameFilter
            (onSelection)="onAcFilterChange('taskAssigneeNames', $event)"
            [values]="taskAssigneeNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Drawing -->
      <ng-container matColumnDef="{{ _allItemTableColumns.drawingName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Drawing</span>
        </th>
        <td class="drawing-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">{{ item.drawingName }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.drawingName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #drawingNameFilter
            (onSelection)="onAcFilterChange('drawingNames', $event)"
            [values]="drawingNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Project -->
      <ng-container matColumnDef="{{ _allItemTableColumns.projectName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Project</span>
        </th>
        <td class="project-name-cell" mat-cell *matCellDef="let item" (click)="itemClicked(item)">{{ item.projectName }}</td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.projectName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #projectNameFilter
            (onSelection)="onAcFilterChange('projectNames', $event)"
            [values]="projectNames"
            [triCheck]="true"
          ></checkd-multiselect>
        </th>
      </ng-container>

      <!-- Report Name -->
      <ng-container matColumnDef="{{ _allItemTableColumns.reportName.columnDef }}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          <span class="header-text">Report</span>
        </th>
        <td class="report-name-cell" mat-cell *matCellDef="let item">
          <span *ngIf="getLegacyReportUid(item.item) as legacyReportUid">
            <a href="legacyReports/{{ getLegacyReportUid(item.item) }}" target="_blank">
              {{ item.reportName }}
            </a>
          </span>
          <span *ngIf="!getLegacyReportUid(item.item)">{{ item.reportName }}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="{{ _allItemTableColumns.reportName.columnDef }}Search">
        <th mat-header-cell *matHeaderCellDef>
          <checkd-multiselect
            #reportNameFilter
            (onSelection)="onAcFilterChange('reportNames', $event)"
            [triCheck]="true"
            [values]="reportNames"
          >
          </checkd-multiselect>
        </th>
      </ng-container>

      <!-- Menu -->
      <ng-container matColumnDef="menu" stickyEnd>
        <th class="item-menu-cell" mat-header-cell *matHeaderCellDef></th>
        <td class="item-menu-cell" mat-cell *matCellDef="let item" (click)="onItemMenuClicked(item)">
          <item-contextmenu
            [restrictedItemMenuActions]="restrictedItemMenuActions"
            [additionalItemMenuActions]="additionalItemMenuActions"
            [item]="item.item"
            [isProjectLockedOrArchived]="isProjectLockedOrArchived"
            [userProjectRole]="userProjectRole"
            [currentUser]="currentUser"
            (menuOptionSelected)="menuOptionSelected.emit($event)"
          >
          </item-contextmenu>
        </td>
      </ng-container>

      <tr class="" *matNoDataRow>
        <td *ngIf="shouldDisplayEmptySearchResult" [colSpan]="displayedColumns.length" class="no-data-row">
          <span>No items matches your search</span>
        </td>
      </tr>

      <ng-container matColumnDef="menuSearch" stickyEnd>
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <tr class="sort-header" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

      <tr class="filter-header" mat-header-row *matHeaderRowDef="displayedSearchColumns; sticky: true"></tr>

      <ng-container *ngIf="_items != null">
        <tr mat-row *matRowDef="let item; columns: displayedColumns" class="item-row"></tr>
      </ng-container>
    </table>
    <ng-container *ngIf="dataSource.filteredData.length == 0 && isItemListReady">
      <div class="empty-state__component-container">
        <checkd-empty-state [title]="noItemsTitle" [text]="noItemsDescription" [image]="noItemsImgSrc"></checkd-empty-state>
      </div>
    </ng-container>
  </div>

  <ng-template #itemIsNotReady>
    <div class="skeleton">
      <ngx-skeleton-loader
        count="1"
        animation="progress"
        [theme]="{
          'border-radius': '5px',
          height: '50px',
          'background-color': '#e9ecef',
          border: '1px solid white',
        }"
      ></ngx-skeleton-loader>
      <ngx-skeleton-loader
        count="10"
        animation="progress"
        [theme]="{
          'border-radius': '5px',
          height: '40px',
          'background-color': '#e9ecef',
          border: '1px solid white',
        }"
      ></ngx-skeleton-loader>
    </div>
  </ng-template>

  <!-- end table wrapper -->

  <!--  <span fxFlex="1 1 auto"></span>-->

  <div class="paginator-wrapper">
    <mat-paginator
      #paginator
      [pageSizeOptions]="pageSizes"
      [pageSize]="pageSize"
      (page)="checkPageSize($event)"
      class="mat-paginator-bottom"
      showFirstLastButtons
    >
    </mat-paginator>
  </div>
</div>
