import { PersonData } from "./person.interface"
import { Person } from "./person"

type MicrosoftADRegistrationData = {
  registrationType: "microsoft-ad"
  company?: string
  personData: PersonData
}

type EmailAndPasswordRegistrationData = {
  registrationType: "email-and-password"
  company?: string
  password: string
  personData: PersonData
}

export type UserRegistrationData = MicrosoftADRegistrationData | EmailAndPasswordRegistrationData

export interface UserCompanyRemovalData {
  userUid: string
  companyUid: string
}

export const USER_PRIVATE_SUBCOLLECTION = "private"

export class User extends Person {}
