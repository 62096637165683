import { COMMA, ENTER } from "@angular/cdk/keycodes"
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from "@angular/core"
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from "@angular/forms"
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteModule,
} from "@angular/material/legacy-autocomplete"
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipsModule } from "@angular/material/legacy-chips"
import { Observable } from "rxjs"
import { map, startWith } from "rxjs/operators"
import { CheckdTagComponent } from "../checkd-tag/checkd-tag.component"
import { FlexModule } from "@angular/flex-layout/flex"
import { MatLegacyOptionModule } from "@angular/material/legacy-core"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"
import { NgIf, NgFor, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-tags",
  templateUrl: "./checkd-tags.component.html",
  styleUrls: ["./checkd-tags.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    MatLegacyFormFieldModule,
    MatLegacyChipsModule,
    NgFor,
    MatIconModule,
    FormsModule,
    MatLegacyAutocompleteModule,
    ReactiveFormsModule,
    MatLegacyOptionModule,
    FlexModule,
    CheckdTagComponent,
    AsyncPipe,
  ],
})
export class CheckdTagsComponent implements OnInit {
  // backing field for tags
  _tags: string[] = []

  // Used to detect whether any changes have been made to the tags
  // @ts-ignore
  _initialTags: string[] = null

  @Input() set tags(tagsInput: string[]) {
    this._tags = CheckdTagsComponent.cleanTags(tagsInput || [])

    if (this._initialTags === null) {
      this._initialTags = [...this.tags]
    }
  }

  get tags(): string[] {
    return this._tags || []
  }

  @Input() areTagsEditable: boolean = false
  @Input() placeholder: string = "Add tags.."
  @Input() tagSet: string[] = []

  @Output() onTagsUpdated = new EventEmitter<string[]>()
  // Check out tag-selection-dialog.component.html for an example on how to use this
  @Output() tagsChanged = new EventEmitter<boolean>(true)

  @ViewChild("tagInput") tagInput: ElementRef<HTMLInputElement>
  @ViewChild("auto") matAutocomplete: MatAutocomplete

  readonly separatorKeysCodes: number[] = [COMMA, ENTER]

  tagsControl = new UntypedFormControl()
  dropdownTagsList: Observable<string[]>

  constructor() {
    this.dropdownTagsList = this.tagsControl.valueChanges.pipe(
      startWith(null),
      map((tag: string | null) => (tag ? this._filter(tag) : this.tagSet.map((x) => x.toUpperCase().trim())))
    )
  }

  ngOnInit() {
    this.tagsChanged.emit(false)
  }

  addTagByTyping(event: MatChipInputEvent): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event (handled by selected())
    if (this.matAutocomplete && this.matAutocomplete.isOpen) {
      return
    }

    const input = event.input
    const value = event.value

    // Add our tag
    if ((value || "").trim()) {
      this._tags.push(value)
      this.tags = this._tags
      this.emitChanges()
    }

    // Reset the input value
    if (input) {
      input.value = ""
    }

    this.tagsControl.setValue(null)
  }

  // called when a tag is removed either by clicking the cancel icon, or backspace
  removeTag(tag: string): void {
    const index = this.tags.indexOf(tag)

    if (index >= 0) {
      this.tags.splice(index, 1)
      this.emitChanges()
    }
  }

  addTagByDropdownSelection(event: MatAutocompleteSelectedEvent): void {
    const tagToAdd = event.option.viewValue
    this._tags.push(tagToAdd)
    this.tags = this._tags

    this.emitChanges()

    this.tagInput.nativeElement.value = ""
    this.tagsControl.setValue(null)
  }

  private emitChanges() {
    this.onTagsUpdated.emit(this.tags)

    if (JSON.stringify(this._initialTags) === JSON.stringify(this.tags)) {
      this.tagsChanged.emit(false)
    } else {
      this.tagsChanged.emit(true)
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toUpperCase().trim()

    return this.tagSet.filter((tag) => tag.toUpperCase().indexOf(filterValue) === 0)
  }

  private static cleanTags(strings: string[]): string[] {
    if (!strings) {
      return []
    }

    return Array.from(new Set(strings))
      .map((x) => x.toUpperCase().trim())
      .sort()
  }
}
