import { Component, Inject, OnInit } from "@angular/core"
import { UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from "@angular/forms"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Item } from "@models/common"
import { NgIf } from "@angular/common"
import { CheckdButtonComponent } from "../../../checkd-ui/checkd-button/checkd-button.component"
import { MatLegacyInputModule } from "@angular/material/legacy-input"
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field"

export interface IDuplicatonSettings {
  numberOfDuplicates: number
}

@Component({
  selector: "checkd-item-duplication-dialog",
  templateUrl: "./item-duplication-dialog.component.html",
  styleUrls: ["./item-duplication-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, FormsModule, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, CheckdButtonComponent, NgIf],
})
export class ItemDuplicationDialogComponent implements OnInit {
  maxDuplicates: number = 10
  title: string = ""
  duplicationForm: UntypedFormGroup

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<ItemDuplicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { item: Item }
  ) {}

  ngOnInit() {
    this.createTitle()
    this.duplicationForm = this.formBuilder.group({
      numberOfDuplicates: 1,
    } as IDuplicatonSettings)
  }

  get maxDuplicatesExceeded() {
    return (this.duplicationForm.value.numberOfDuplicates || 0) > this.maxDuplicates
  }

  createTitle() {
    const num = this.data && this.data.item && this.data.item.number ? `${this.data.item.number}: ` : ""
    const name = this.data && this.data.item && this.data.item.name ? `${this.data.item.name}` : ""
    this.title = `Duplicate item ${num}${name}`
  }

  onSubmit() {
    this.dialogRef.close(this.duplicationForm.value as IDuplicatonSettings)
  }

  onCancel() {
    this.dialogRef.close()
  }
}
