import { Component, EventEmitter, Input, Output } from "@angular/core"
import { Item, Role, RoleType } from "@models/common"
import { MatIconModule } from "@angular/material/icon"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { NgIf } from "@angular/common"
import { MatLegacyTooltipModule } from "@angular/material/legacy-tooltip"
import { FlexModule } from "@angular/flex-layout/flex"

export const allAvailableActions = ["ASSIGN", "UPLOAD_IMAGE", "EDIT_TAGS", "SEND_COMMENT"]

@Component({
  selector: "item-actions",
  templateUrl: "./item-actions.component.html",
  styleUrls: ["./item-actions.component.scss"],
  standalone: true,
  imports: [FlexModule, MatLegacyTooltipModule, NgIf, MatLegacyButtonModule, MatIconModule],
})
export class ItemActionsComponent {
  @Input() item: Item
  @Input() availableActions: string[] = allAvailableActions
  @Input() projectIsArchived: boolean = false
  @Input() userProjectRole: Role
  @Input() userItemRole: Role
  @Output() onAssign = new EventEmitter()
  @Output() onUploadImage = new EventEmitter()
  @Output() onEditTags = new EventEmitter()
  @Output() onSendComment = new EventEmitter()

  isAvailableAction(action: string) {
    return this.availableActions.indexOf(action) > -1
  }

  // Only admins, owners, and admins should be able to assign tasks
  hasPermissionToAssignTask(): boolean {
    const rolesThatCanAssign = [
      RoleType.SYSTEM_ADMINISTRATOR,
      RoleType.COMPANY_ADMINISTRATOR,
      RoleType.PROJECT_OWNER,
      RoleType.PROJECT_ADMINISTRATOR,
      RoleType.PROJECT_ADMINISTRATOR_RESTRICTED,
      RoleType.ITEM_CREATOR,
    ]

    if (this.userProjectRole && this.userProjectRole.roleType && rolesThatCanAssign.includes(this.userProjectRole.roleType)) {
      return true
    }

    if (this.userItemRole && this.userItemRole.roleType) {
      return rolesThatCanAssign.includes(this.userItemRole.roleType)
    }

    return false
  }

  itemAssignTooltipMessage(): string {
    if (this.projectIsArchived) {
      return "This project is archived"
    }

    if (!this.hasPermissionToAssignTask()) {
      return "Only admins and item creators can assign this item"
    }

    return "Assign this item"
  }
}
