import { Component, Inject, OnInit } from "@angular/core"
import { DocumentReference } from "@angular/fire/compat/firestore"
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from "@angular/material/dialog"
import { Router } from "@angular/router"
import { Invitation, Notification } from "@models/common"
import { InvitationService, SnackbarService, UserService } from "@services"
import { firstValueFrom, startWith } from "rxjs"
import { map, shareReplay, tap } from "rxjs/operators"
import { MatLegacyButtonModule } from "@angular/material/legacy-button"
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner"
import { NgIf, AsyncPipe } from "@angular/common"

@Component({
  selector: "checkd-project-invitation-received-dialog",
  templateUrl: "./project-invitation-received-dialog.component.html",
  styleUrls: ["./project-invitation-received-dialog.component.scss"],
  standalone: true,
  imports: [MatDialogModule, NgIf, MatLegacyProgressSpinnerModule, MatLegacyButtonModule, AsyncPipe],
})
export class ProjectInvitationReceivedDialogComponent implements OnInit {
  acceptInvitationInProgress = false
  invitation: Invitation

  constructor(
    private router: Router,
    private dialogRef: MatDialogRef<ProjectInvitationReceivedDialogComponent>,
    private userService: UserService,
    private invitationService: InvitationService,
    @Inject(MAT_DIALOG_DATA) public data: { notification: Notification }
  ) {}

  readonly invitation$ = this.invitationService.listenToUid(this.data.notification.target.id).pipe(
    tap((invitation) => (this.invitation = invitation)),
    shareReplay({ bufferSize: 1, refCount: true })
  )

  readonly invitationIsLoading$ = this.invitation$.pipe(
    map(() => false),
    startWith(true)
  )

  ngOnInit() {}

  closeDialog() {
    this.dialogRef.close()
  }

  invitationText() {
    return this.data?.notification?.message ?? "You have been invited you a project?"
  }

  async acceptInvitationButtonClicked(invitation: Invitation) {
    this.acceptInvitationInProgress = true
    const currentUser = await firstValueFrom(this.userService.currentUser$)
    await this.invitationService.acceptInvitation(invitation, currentUser)

    const projectRef: DocumentReference = invitation?.data?.resources[0]

    if (projectRef) {
      await this.router.navigate([projectRef?.path])
    }

    this.dialogRef.close()
  }
}
